import React, { useState } from "react";
import Slider from "react-slick";

import { Academybanner } from "../../common/slider/Settings";
import RestaurentLoader from "../../common/loader/RestaurentLoader";
import AcademyCard from "./AcademyCard";
import { useSelector } from "react-redux";
import { STATUS } from "../../services/Status";
import EmptyComponent from "../../common/commoncomponents/EmptyComponent";

const AcademySlider = () => {
  const [loading, setloading] = useState(true);
  const { featuresports, status } = useSelector((state) => state.home);

  return (
    <>
      {status === STATUS.LOADING ? (
        <>
          {" "}
          <div className="row">
            <div className="col-sm-12 col-md-4 ">
              <RestaurentLoader />
            </div>
            <div className="col-sm-12 col-md-4 ">
              <RestaurentLoader />
            </div>
            <div className="col-sm-12 col-md-4 ">
              <RestaurentLoader />
            </div>
          </div>
        </>
      ) : (
        <>
          {featuresports && featuresports.length > 3 ? (
            <Slider {...Academybanner} className="academies_slider">
              {featuresports &&
                featuresports.map((data, i) => {
                  return (
                    <AcademyCard
                      key={i}
                      id={data.id}
                      slug={data?.academyname}
                      img={data?.displayimg}
                      link={data?.categoryname}
                      desc={data?.address}
                      title={data?.academyname}
                      city={data?.city}
                      sublocation={data?.sublocation}
                      shortdesc={data?.shortdesc}
                    />
                  );
                })}
            </Slider>
          ) : (
            <>
              <div className="row">
                {featuresports.map((data, i) => {
                  return (
                    <div className="col-sm-12 col-md-6 col-lg-4 " key={i}>
                      <AcademyCard
                        id={data.id}
                        slug={data?.academyname}
                        img={data?.displayimg}
                        link={data?.categoryname}
                        desc={data?.shortdesc}
                        title={data?.academyname}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {featuresports && featuresports.length == 0 && <EmptyComponent />}
        </>
      )}
    </>
  );
};

export default AcademySlider;
