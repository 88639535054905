export const BASE_URL = process.env.REACT_APP_API_ENDPOINT
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

export const SITE_NAME = "Sportzlete";

//local

// export const BASE_URL = `http://127.0.0.1:8000/api/v1/`;

// export const IMAGE_URL = `http://127.0.0.1:8000/`;

// export const SITE_NAME = "Sportzlete";
