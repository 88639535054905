import React from "react";
import { useDispatch } from "react-redux";
import TopBanner from "../common/banner/TopBanner";

import ContactForm from "../components/contactus/ContactForm";
import Text from "../components/contactus/Text";
import { setTotalLoader } from "../features/user/userSlice";
import useFetch from "../hooks/useFetch";
import { BASE_URL } from "../services/apiUrl";
const ContactUs = () => {
  const {
    data: { aboutuspages },
    loading,
    error,
  } = useFetch(`${BASE_URL}aboutuspage`);
  const dispatch = useDispatch();

  const loader = () => {
    dispatch(setTotalLoader(true));
  };
  return (
    <>
      <TopBanner
        // title={aboutuspages?.contactsectionheading}
        image={aboutuspages?.contactbanner}
        loading={loading}
      />

      {/* <!-- section begin --> */}
      <section>
        <div className="container">
          <div className="banner_spacer"></div>
          <div className="row">
            <Text
              text1={aboutuspages?.contactsection1}
              text2={aboutuspages?.contactsection2}
              loading={loading}
            />
            <ContactForm />
          </div>
          <div className="banner_spacer"></div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
