import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "../../components/login/Login.module.css";
import axios from "axios";

const ContactForm = () => {
  const [contactInfo, setcontactInfo] = useState({
    full_name: "",
    email: "",
    mobile_no: "",
    message: "",
  });
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState([]);
  const [successmessage, setmessage] = useState(false);
  const Handleinput = (e) => {
    e.preventDefault();
    setcontactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };

  const contactsubmit = (e) => {
    e.preventDefault();
    setloading(true);
    axios
      .post("contactus", contactInfo)
      .then((res) => {
        setloading(false);
        seterror([]);
        setcontactInfo({
          full_name: "",
          email: "",
          mobile_no: "",
          message: "",
        });
        setmessage(true);
        setTimeout(() => {
          setmessage(false);
        }, 1000);
      })
      .catch((error) => {
        setloading(false);
        if (error.response.data.errors) seterror(error.response.data.errors);
      });
  };
  return (
    <>
      <div className="col-lg-6 col-md-12 mt-5 mt-lg-0">
        <div className="form_box" style={{ height: "max-content" }}>
          {/* <!-- contents here --> */}
          <form name="contact_form" className="contact_form1">
            <input
              type="text"
              name="full_name"
              placeholder="Full Name"
              className="form-control"
              onChange={Handleinput}
              value={contactInfo?.full_name}
            />
            {error.length !== 0 && error.full_name ? (
              <p className={classes.validation}>{error.full_name[0]}</p>
            ) : null}
            <input
              type="tel"
              name="mobile_no"
              placeholder="Phone"
              className="form-control"
              onChange={Handleinput}
              value={contactInfo?.mobile_no}
            />
            {error.length !== 0 && error.mobile_no ? (
              <p className={classes.validation}>{error.mobile_no[0]}</p>
            ) : null}
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="form-control"
              onChange={Handleinput}
              value={contactInfo?.email}
            />
            {error.length !== 0 && error.email ? (
              <p className={classes.validation}>{error.email[0]}</p>
            ) : null}

            <textarea
              rows="5"
              name="message"
              className="form-control"
              placeholder="write message"
              onChange={Handleinput}
              value={contactInfo?.message}
            />

            {error.length !== 0 && error.message ? (
              <p className={classes.validation}>{error.message[0]}</p>
            ) : null}

            {loading ? (
              <CircularProgress className={classes.progressbar} />
            ) : (
              <input
                type="button"
                name="submit"
                className="btn btn-submit"
                value="Submit"
                onClick={contactsubmit}
              />
            )}
          </form>
          {successmessage && (
            <p className="text-success"> Successfully Submitted</p>
          )}

          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
