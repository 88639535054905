import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "../../components/login/Login.module.css";
import axios from "axios";

const SubmitAcademyForm = () => {
  const [error, seterror] = useState([]);
  const [loading, setloading] = useState(false);
  const [input, setinput] = useState({
    academyname: "",
    academyemail: "",
    academyphone: "",
    academyaddress: "",
  });

  const Handleinput = (e) => {
    e.preventDefault();
    setinput({ ...input, [e.target.name]: e.target.value });
  };
  const [message, setmessage] = useState(null);

  const HandleSubmit = (e) => {
    e.preventDefault();

    const data = {
      academyname: input.academyname,
      academyemail: input.academyemail,
      academyphone: input.academyphone,
      academyaddress: input.academyaddress,
    };
    console.log(data);

    setloading(true);
    axios
      .post("submitacademyform", data)
      .then((res) => {
        setloading(false);

        setmessage(res.data?.message);
        setinput({
          academyname: "",
          academyemail: "",
          academyphone: "",
          academyaddress: "",
        });
        seterror([]);
      })
      .catch((error) => {
        setloading(false);

        error.response.data.errors && seterror(error.response.data.errors);
        console.log(error);
      });
  };

  return (
    <>
      <form onSubmit={HandleSubmit}>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Academy Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Academy Name"
            name="academyname"
            onChange={Handleinput}
            value={input.academyname}
          />

          {error.length !== 0 && error.academyname ? (
            <p className="text-danger">{error.academyname[0]}</p>
          ) : null}
        </div>

        <div className="form-group mt-3">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
            name="academyemail"
            onChange={Handleinput}
            value={input.academyemail}
          />
          {error.length !== 0 && error.academyemail ? (
            <p className="text-danger">{error.academyemail[0]}</p>
          ) : null}
        </div>
        <div className="form-group mt-3">
          <label htmlFor="exampleInputEmail1">Contact Number</label>
          <input
            type="number"
            className="form-control"
            placeholder="Enter Contact Number"
            name="academyphone"
            onChange={Handleinput}
            value={input.academyphone}
          />
          {error.length !== 0 && error.academyphone ? (
            <p className="text-danger">{error.academyphone[0]}</p>
          ) : null}
        </div>

        <div className="form-group mt-3">
          <label htmlFor="exampleFormControlTextarea1">Academy Address</label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            name="academyaddress"
            onChange={Handleinput}
            value={input.academyaddress}
          ></textarea>

          {error.length !== 0 && error.academyaddress ? (
            <p className="text-danger">{error.academyaddress[0]}</p>
          ) : null}
        </div>

        <div class="form-group  text-center mt-3">
          {!loading ? (
            <button type="submit" className="commonbtn">
              Submit
            </button>
          ) : (
            <CircularProgress className={classes.progressbar} />
          )}
        </div>
        <small
          className="text-success text-center mt-3"
          style={{ display: "block" }}
        >
          {message}
        </small>
      </form>
    </>
  );
};

export default SubmitAcademyForm;
