import React from "react";
import img from "../../assets/images/cate.png";
import { IMAGE_URL } from "../../services/apiUrl";
import Loader from "../loader/Loader";

const TopBanner = ({ title, type, blogtitle, image, loading }) => {
  let imageUrl = image ? `${IMAGE_URL}${image}` : `${img}`;

  if (loading) return <Loader />;
  return (
    <>
      {/* <!--banner/breadcrumbs begin--> */}
      <section
        className="breadcrumb_sec contact_banner flex-banner"
        style={{ backgroundImage: `url(${imageUrl})`, height: "470px" }}
      >
        <div className="banner_spacer"></div>
        <h1 className="text-center text-white banner_title">
          {title ? title : ""}
        </h1>
        {type == "Blog" && (
          <div className="text-center">
            <nav aria-label="breadcrumb">
              <ul className="breadcrumb justify-content-center mb-0">
                <li className="breadcrumb-item">
                  <a href="blog.html">Blog</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {blogtitle}
                </li>
              </ul>
            </nav>
          </div>
        )}

        <div className="banner_spacer"></div>
      </section>
      {/* <!--banner/breadcrumbs begin--> */}
    </>
  );
};

export default TopBanner;
