import React, { useEffect } from "react";
import useFetch from "../hooks/useFetch";
import { BASE_URL } from "../services/apiUrl";
import { useParams } from "react-router-dom";
import Loader from "../common/loader/Loader";

const AcdemyTerms = () => {
  const params = useParams();
  const { data, loading, error, Refetch } = useFetch(
    `${BASE_URL}academyterms/${params?.id}`
  );

  useEffect(() => {
    Refetch();
  }, [params?.id]);

  function createMarkup(text) {
    return { __html: text };
  }
  if (loading) return <Loader />;
  return (
    <div className="container">
      <div className="banner_spacer"></div>
      <h1 className="text-center">{data && data?.data?.termssectionheading}</h1>

      <div className="row">
        <div className="col-md-12">
          <div
            dangerouslySetInnerHTML={createMarkup(
              data && data?.data?.termssection1
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default AcdemyTerms;
