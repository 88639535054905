import React from "react";
import { Link } from "react-router-dom";
import classes from "./LoginError.module.css";

const LoginError = ({ academy_id, slug }) => {
  return (
    <>
      <div className={classes.login__section}>
        <span>
          Login To Add Packages{" "}
          <Link
            to={{
              pathname: "/login",
              state: {
                type: "academy",
                academy_id: academy_id,
                slug: slug,
              },
            }}
            className="text-danger"
          >
            Login here
          </Link>{" "}
        </span>
      </div>
    </>
  );
};

export default LoginError;
