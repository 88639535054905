import React, { useState } from "react";
import { Vedio } from "../../common/slider/Settings";
import img1 from "../../assets/images/Sportlete_FMA_0885.jpg";
import img2 from "../../assets/images/Sportlete_FMA_0898.jpg";
import Slider from "react-slick";
import vedio1 from "../../assets/videos/FMA_Promo_v4_wz.mp4";
import vedio2 from "../../assets/videos/FMA_Promo_v4_wz.mp4";
import Model from "./Model";

import "./Category.scss";

const VedioSlider = ({ sportvedios }) => {
  const [isOpen, setOpen] = useState(false);
  const [CurrentVedio, setVedio] = useState("");

  const Data = [
    {
      vedio: vedio1,
      img: img1,
    },
    {
      vedio: vedio2,
      img: img2,
    },
  ];

  const OpenModel = (vedio) => {
    setOpen(true);
    setVedio(vedio);
  };
  return (
    <>
      <Model isOpen={isOpen} setOpen={setOpen} vedio={CurrentVedio} />
      <Slider {...Vedio} className="singlevideo_slider">
        {sportvedios.map((data, i) => (
          <div key={i}>
            <div className="">
              <div>
                <img
                  src={`https://img.youtube.com/vi/${data.attachmenturl}/hqdefault.jpg`}
                  className="vid__image"
                />
              </div>

              <button
                className="js-video-button js-video-button1"
                data-channel="custom"
                onClick={() => {
                  OpenModel(data.attachmenturl);
                }}
              >
                <div className="btn1">
                  <div className="play"></div>
                </div>
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default VedioSlider;
