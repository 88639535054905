import { ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { setSlotavailable } from "../../features/cart/cartSlice";

export default function useMultiStepForm(steps) {
  const dispatch = useDispatch();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  function next() {
    setCurrentStepIndex((i) => {
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  }

  function back() {
    dispatch(setSlotavailable(true));
    setCurrentStepIndex((i) => {
      if (i <= 0) return i;
      return i - 1;
    });
  }

  function AddtoCartFinish() {
    setCurrentStepIndex(0);
  }

  function goTo(index) {
    setCurrentStepIndex(index);
  }

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    steps,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    goTo,
    next,
    back,
    AddtoCartFinish,
    page: currentStepIndex + 1,
    setCurrentStepIndex,
  };
}
