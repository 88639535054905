import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsTermsModalVisible } from "../../features/modal/modalSlice";
import ModalBox from "../modal/ModalBox";
import classes from "./Empty.module.css";
import SubmitAcademyForm from "./SubmitAcademyForm";

const EmptyComponent = ({ city }) => {
  const dispatch = useDispatch();
  const { termsmodal } = useSelector((state) => state.modal);
  const {
    config: { custmsg },
  } = useSelector((state) => state.home);
  const modalVisible = (e) => {
    e.preventDefault();
    dispatch(setIsTermsModalVisible(true));
  };
  return (
    <>
      {termsmodal && (
        <ModalBox>
          <SubmitAcademyForm />
        </ModalBox>
      )}
      <div className={classes.empty__component}>
        <h5 className="empty-component">
          {custmsg || ""}{" "}
          <small
            className="secondary-clr"
            style={{ cursor: "pointer" }}
            onClick={modalVisible}
          >
            click here
          </small>
        </h5>
      </div>
    </>
  );
};

export default EmptyComponent;
