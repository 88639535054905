import React, { useEffect, useState, useRef, useCallback } from "react";
import img from "../../../assets/images/Sportlete_FMA_0898.jpg";
import { Link } from "react-router-dom";
import Loader from "../../../common/loader/Loader";
import axios from "axios";
import FillterCard from "./FillterCard";
import InfiniteScroll from "react-infinite-scroll-component";
import FillterCardDemo from "./FillterCardDemo";
import { setPage } from "../../../features/sports/sportSlice";
import { BASE_URL } from "../../../services/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../../../services/Status";
import Pagination from "react-js-pagination";
import EmptyComponent from "../../../common/commoncomponents/EmptyComponent";

const FillterResults = ({
  type,
  page,
  fillterData,
  hasMore,
  status,
  currentPage,
  totalcount,
  perPage,
  handlePageChange,
  paginateStatus,
}) => {
  const { city } = useSelector((state) => state.user);

  const {
    config: { listmsg },
  } = useSelector((state) => state.home);

  //const [Page, setPage] = useState(1);

  //const [Products, setProducts] = useState([]);
  //const [hasMore, setHasMore] = useState(false);

  const dispatch = useDispatch();

  const ScrollEnd = () => {
    setTimeout(() => {
      handlePageChange(page + 1);
    }, 1000);
  };

  // window.onscroll = () => {
  //   if (
  //     window.innerWidth + document.documentElement.scrollTop ===
  //     document.documentElement.offsetHeight
  //   ) {
  //     console.log("yes");
  //     ScrollEnd();
  //   }
  // };

  if (status === STATUS.LOADING) return <Loader />;

  if (fillterData.length === 0) return <EmptyComponent city={city} />;

  return (
    <>
      <>
        <InfiniteScroll
          dataLength={fillterData.length}
          next={ScrollEnd}
          hasMore={fillterData.length !== hasMore}
          loader={<Loader />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{listmsg || "That's all we have listed here for now"}</b>
            </p>
          }
        >
          <div className="container">
            <div className="row">
              {fillterData &&
                fillterData.length > 0 &&
                fillterData.map((product, i) => {
                  return (
                    <>
                      <FillterCard
                        product={product}
                        key={product.id}
                        type={type}
                      />
                    </>
                  );
                })}
            </div>
          </div>
        </InfiniteScroll>

        {/* {paginateStatus === STATUS.LOADING ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="row">
              {fillterData &&
                fillterData.length > 0 &&
                fillterData.map((product, i) => {
                  return (
                    <>
                      <FillterCard
                        product={product}
                        key={product.id}
                        type={type}
                      />
                    </>
                  );
                })}
            </div>
          </div>
        )} */}

        {/* <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <nav aria-label="Page navigation example">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={perPage}
                totalItemsCount={totalcount}
                pageRangeDisplayed={5}
                onChange={handlePageChange.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination justify-content-center cate_pagination"
                prevPageText="<"
                nextPageText=">"
              />
            </nav>
          </div>
        </div> */}
      </>
    </>
  );
};

export default FillterResults;
