import React, { useState } from "react";
import BlogCard from "./BlogCard";
import bannerimg from "../../assets/images/bannerimg1.jpg";
import BlogBanner from "./BlogBanner";
import { useDispatch, useSelector } from "react-redux";
import { setIsTermsModalVisible } from "../../features/modal/modalSlice";
import ModalBox from "../../common/modal/ModalBox";
import FillterModel from "../category/fillter/FillterModel";

const BlogList = ({
  blog,
  Category,
  setCategory,
  applyfilter,
  clearfilter,
  ClearSmFilter,
}) => {
  const dispatch = useDispatch();
  const { termsmodal } = useSelector((state) => state.modal);

  const [info, setinfo] = useState(null);
  const openAuthorinfo = (information) => {
    console.log(information);

    dispatch(setIsTermsModalVisible(true));
    setinfo(information);
  };
  function createMarkup(data) {
    return { __html: data };
  }
  const OpenOrClose = () => {
    document.getElementById("filterdiv").classList.toggle("open");
    document.getElementById("filterbtn").classList.toggle("open");
    document.body.classList.toggle("lock-scroll");
  };
  const Blogcard = () => {
    let step = 0;
    return (
      <>
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
            <div>
              {Category &&
                Category.length > 0 &&
                Category.map((cat, i) => {
                  return (
                    <span
                      className="fil_val"
                      name="category"
                      id={cat}
                      key={i}
                      onClick={(e) => ClearSmFilter(e.target.id)}
                    >
                      {cat}
                    </span>
                  );
                })}

              {Category.length > 0 ? (
                <button
                  type="reset"
                  className="reset_cls"
                  onClick={() => clearfilter(false)}
                >
                  <i className="fa fa-undo"></i>&nbsp;&nbsp;Clear
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <div
              id="filterbtn"
              className=""
              style={{ textAlign: "right", marginBottom: "10px" }}
              onClick={OpenOrClose}
            >
              <button className="sort_label">
                Filters&nbsp;&nbsp;<i className="fa fa-filter"></i>
              </button>
            </div>
          </div>
        </div>

        {termsmodal && (
          <ModalBox>
            <>
              <div dangerouslySetInnerHTML={createMarkup(info)}></div>
            </>
          </ModalBox>
        )}

        <FillterModel
          OpenOrClose={OpenOrClose}
          category={Category}
          setcategory={setCategory}
          applyfilter={applyfilter}
          agelist={[]}
          pricelist={[]}
          clearfilter={clearfilter}
          type="blog"
        />

        <div className="blog_row row">
          {/* {blog.map((blog, i) => {
            if (step == i) {
              step = step + 7;
              return (
                <BlogBanner
                  blog={blog}
                  key={i}
                  openAuthorinfo={openAuthorinfo}
                />
              );
            } else {
              return (
                <>
                  <BlogCard
                    blog={blog}
                    key={i}
                    openAuthorinfo={openAuthorinfo}
                  />
                </>
              );
            }
          })} */}

          {blog.map((blog, i) => {
            return (
              <>
                <BlogCard blog={blog} key={i} openAuthorinfo={openAuthorinfo} />
              </>
            );
          })}
        </div>
      </>
    );
  };
  return <>{Blogcard()}</>;
};

export default BlogList;
