import React from "react";
import BlogContent from "../components/blogs/BlogContent";

const Blog = () => {
  return (
    <>
      <BlogContent />
    </>
  );
};

export default Blog;
