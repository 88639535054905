import React from "react";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Rating from "@mui/material/Rating";
import RatingLoader from "../../common/loader/RatingLoader";
import { Progress } from "reactstrap";
const CustomerRating = ({ id }) => {
  const {
    data: { data },
    loading,
    error,
  } = useFetch(`GetCustomerCommentRating/${id}`);

  return (
    <>
      <div className="cus_rating px-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h2 className="siderbar_head">Customer Rating</h2>

            <h6 className="sub_txt">
              <Link
                to={`/academy/${id}/comments`}
                className="cmt_txt text-decoration-none"
              >
                Comments
              </Link>{" "}
              {/* <span>(sportzlete customers only)</span> */}
            </h6>
            {loading ? (
              <RatingLoader />
            ) : (
              <>
                <div className="text-center mt-3 mb-4">
                  <h1 className="rating_txt mb-0">
                    {data && data?.avgrating === null
                      ? 0
                      : data?.avgrating.toFixed(2)}
                    <span>/5</span>
                  </h1>
                  <Rating
                    name="simple-controlled"
                    value={data && data?.avgrating}
                    precision={0.2}
                    disabled
                  />
                  <h6>{data && data?.totrating} ratings</h6>
                  <div className="row align-items-center">
                    <div className="col-3">
                      <h6 className="mb-0 star_val">
                        5 <i className="fa fa-star"></i>
                      </h6>
                    </div>
                    <div className="col-7 p-0">
                      <Progress
                        value={data && data?.rating5}
                        max={data && data?.totrating}
                        color="warning"
                      />
                    </div>
                    <div className="col-2">
                      <p className="mb-0">{data && data?.rating5}</p>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-3">
                      <h6 className="mb-0 star_val">
                        4 <i className="fa fa-star"></i>
                      </h6>
                    </div>
                    <div className="col-7 p-0">
                      {/* <div className="progress probar">
                        <div className="progress-bar pb_bgcolor"></div>
                      </div> */}
                      <Progress
                        value={data && data?.rating4}
                        max={data && data?.totrating}
                        color="warning"
                      />
                    </div>
                    <div className="col-2">
                      <p className="mb-0">{data && data?.rating4}</p>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-3">
                      <h6 className="mb-0 star_val">
                        3 <i className="fa fa-star"></i>
                      </h6>
                    </div>
                    <div className="col-7 p-0">
                      <Progress
                        value={data && data?.rating3}
                        max={data && data?.totrating}
                        color="warning"
                      />
                    </div>
                    <div className="col-2">
                      <p className="mb-0">{data && data?.rating3}</p>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-3">
                      <h6 className="mb-0 star_val">
                        2 <i className="fa fa-star"></i>
                      </h6>
                    </div>
                    <div className="col-7 p-0">
                      <Progress
                        value={data && data?.rating2}
                        max={data && data?.totrating}
                        color="warning"
                      />
                    </div>
                    <div className="col-2">
                      <p className="mb-0">{data && data?.rating2}</p>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-3">
                      <h6 className="mb-0 star_val">
                        1 &nbsp;<i className="fa fa-star"></i>
                      </h6>
                    </div>
                    <div className="col-7 p-0">
                      <Progress
                        value={data && data?.rating1}
                        max={data && data?.totrating}
                        color="warning"
                      />
                    </div>
                    <div className="col-2">
                      <p className="mb-0">{data && data?.rating1}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerRating;
