import React, { useEffect } from "react";

import PackSlider from "../PackSlider";

const Step1 = () => {
  return (
    <>
      <fieldset>
        <div className="form-card step1">
          <PackSlider />

          {/* //pack slider */}
        </div>
      </fieldset>
    </>
  );
};

export default Step1;
