import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useMultiStepForm from "../../common/hooks/useMultiStepForm";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "../../components/login/Login.module.css";
import { DateFormat } from "../../services/Helper";
import moment from "moment";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import { toast } from "react-toastify";
import {
  addToCart,
  resetSelectedThings,
  selectedAddonsInfo,
} from "../../features/cart/cartSlice";
import axios from "axios";
import {
  setIsCartModalVisible,
  setIsModalVisible,
} from "../../features/modal/modalSlice";
import ModalBox from "../../common/modal/ModalBox";
import { Link, useHistory } from "react-router-dom";
import LoginError from "../../common/commoncomponents/LoginError";

const Slot = ({ type, id, slug }) => {
  const [stepitems, setstepitems] = useState(null);
  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    page,
    AddtoCartFinish,
    setCurrentStepIndex,
  } = useMultiStepForm(stepitems ? stepitems : []);

  const [finishloading, setfinishloading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { packages, addons, cart, slotavailable, termschecked } = useSelector(
    (state) => state.cart
  );
  const { isLoggedin } = useSelector((state) => state.user);
  const { singleItem } = useSelector((state) => state.sport);
  const { user } = useSelector((state) => state.user);
  const { cartmodal } = useSelector((state) => state.modal);
  const {
    selectedPerson,
    selectedslot,
    selecteddate,
    selectedAddons,
    selectedPackage,
    selectedPersonName,
    selectedPackageName,
    selectedAddonsName,
    selectedPackageGst,
    selectedPackageRazorpay,
    selectedPackageConveinentFee,
  } = useSelector((state) => state.cart);

  useEffect(() => {
    if (addons && addons.length > 0) {
      setstepitems([<Step1 />, <Step5 />, <Step2 />, <Step3 />, <Step4 />]);
    } else {
      setstepitems([<Step1 />, <Step2 />, <Step3 />, <Step4 />]);
    }
  }, [type, id, singleItem, addons]);

  const packageprice = (id, type) => {
    const selectedPackage_ = packages.filter((pack) => pack?.id == id);
    // console.log(
    //   selectedPackage_[0]?.offerpricenet,
    //   "from selected package slot checout"
    // );
    // return;
    // return selectedPackage_;
    let price;

    if (selectedPackage_[0]?.offerperprice) {
      //new implementation conv fee flow
      // price =
      //   selectedPackage_[0]?.offertype === "1"
      //     ? selectedPackage_[0]?.offerpricenet
      //     : selectedPackage_[0]?.offerpricenet;

      //return;

      //old implementation
      price =
        selectedPackage_[0]?.offertype === "1"
          ? Number(selectedPackage_[0]?.offerperprice)
          : Math.round(
              (selectedPackage_[0]?.price *
                selectedPackage_[0]?.offerperprice) /
                100
            );
    } else {
      price = Number(selectedPackage_[0]?.price);
    }

    if (type === "gst") {
      return (price * Number(selectedPackage_[0]?.gst)) / 100;
    } else {
      return price;
    }
  };

  const handlepagechange = (type) => {
    type === "home" ? history.push("/") : history.push("/checkout");

    dispatch(setIsCartModalVisible(false));
  };

  const addonInfo = (addonsget) => {
    const adonSelectedinfo = addons.filter((addon) =>
      addonsget.includes(addon.id)
    );

    let addoninfo = [];
    let obj;

    adonSelectedinfo.map((addon, i) => {
      if (addon?.offerprice != null) {
        if (addon?.offertype === "1") {
          obj = {
            id: addon?.id,
            name: addon?.addonname,
            price: Number(addon?.offerprice),
            gst: addon?.gst ? addon?.gst : 0,
            convfee: addon?.convfee ? addon?.convfee : 0,
          };
          addoninfo.push(obj);
        } else {
          obj = {
            id: addon?.id,
            name: addon?.addonname,
            price: Math.round(
              (Number(addon?.price) * Number(addon?.offerprice)) / 100
            ),
            gst: addon?.gst ? addon?.gst : 0,
            convfee: addon?.convfee ? addon?.convfee : 0,
          };
          addoninfo.push(obj);
        }
      } else {
        obj = {
          id: addon?.id,
          name: addon?.addonname,
          price: Number(addon?.price),
          gst: addon?.gst ? addon?.gst : 0,
          convfee: addon?.convfee ? addon?.convfee : 0,
        };
        addoninfo.push(obj);
      }
    });
    return addoninfo;
  };

  const addonprice = (addonsget, type) => {
    const adonSelected = addons.filter((addon) => addonsget.includes(addon.id));
    let price = 0;

    //return adonSelected;
    adonSelected.map((ad) => {
      if (ad?.offerprice != null) {
        if (ad?.offertype === "1") {
          price = price + Number(ad?.offerprice);

          // if (type === "gst") {
          //   price = Math.round(price + (price * Number(ad?.gst)) / 100);
          // }
          // price =
          //   type === "gst"
          //     ? price +
          //       Number(ad?.offerprice) +
          //       price +
          //       (Number(ad?.offerprice) * Number(ad?.gst)) / 100
          //     : price + Number(ad?.offerprice);
        } else {
          price =
            price +
            Math.round((Number(ad?.price) * Number(ad?.offerprice)) / 100);

          // if (type === "gst") {
          //   price = Math.round(price + (price * Number(ad?.gst)) / 100);
          // }
        }

        // ad?.offertype === 1
        //   ? return
        //   : price + Math.round((ad?.price * 10) / 100);
      } else {
        price = price + Number(ad?.price);
        // if (type == "gst") {
        //   price = Math.round(price + (price * Number(ad?.gst)) / 100);
        // }
      }
    });

    return price;
  };

  const addonpriceGst = (addoninfo) => {
    // let pricegst = 0;

    // addoninfo.map(
    //   (item, i) =>
    //     (pricegst =
    //       pricegst +
    //       Math.round(item?.price + (item?.price * Number(item?.gst)) / 100))
    // );

    // return pricegst;
    let pricegst = 0;

    addoninfo.map(
      (item) =>
        (pricegst += item?.price + (item?.price * Number(item?.gst)) / 100)
    );

    return pricegst;
  };

  const addonpriceConvefees = (addoninfo) => {
    let convfees = 0;
    addoninfo.map((item) => (convfees += item?.convfee));
    return convfees;
  };

  const AddonsGst = (information) => {
    return information.map((itemaddon, i) => itemaddon?.gst);
  };

  const addRemove = () => {
    document.querySelector(".term-success").classList.remove("remove");

    setTimeout(() => {
      document.querySelector(".term-success").classList.add("remove");
    }, 6000);
  };

  function onSubmit(e) {
    e.preventDefault();
    if (!isLastStep) return next();

    if (isLastStep && !termschecked) return addRemove();

    // addtocart here

    const addoninformation = addonInfo(selectedAddons);

    const cart_data = {
      id: "id" + Math.random().toString(16).slice(2),
      member_id: user?.id,
      academy_id: singleItem?.id,
      sport_id: singleItem?.sport_id,
      academy_name: singleItem?.academyname,
      academy_img: singleItem?.displayimg,
      selectedPerson: selectedPerson,
      selectedPersonName: selectedPersonName,
      selectedslot: selectedslot,
      selecteddate: DateFormat(moment(selecteddate).toDate()),
      selectedAddons: selectedAddons,
      selectedPackage: selectedPackage,
      selectedPackageName: selectedPackageName,
      selectedPackageGst: selectedPackageGst,
      selectedPackageRazorpay: selectedPackageRazorpay,
      selectedPackageConveinentFee: selectedPackageConveinentFee,
      selectedAddonsName: selectedAddonsName,
      selectedAddonsGst: AddonsGst(addoninformation),

      packageprice: packageprice(selectedPackage, "normal"),
      addonprice: addonprice(selectedAddons, "normal"),
      packagepriceGst: packageprice(selectedPackage, "gst"),
      addonpriceGst: addonpriceGst(addoninformation),
      addonpriceConveinentFeeTotal: addonpriceConvefees(addoninformation),
      addoninfo: addonInfo(selectedAddons),
      //totalprice: packageprice(selectedPackage) + addonprice(selectedAddons),
      totalprice:
        packageprice(selectedPackage, "normal") + addonprice(selectedAddons),
      totalpriceGst:
        packageprice(selectedPackage, "normal") +
        packageprice(selectedPackage, "gst") +
        selectedPackageConveinentFee +
        addonpriceGst(addoninformation) +
        addonpriceConvefees(addoninformation),
    };

    console.log(cart_data);
    //return;

    // checking if user already buy this same cart

    const checkdata = {
      member_id: user?.id,
      academy_id: singleItem?.id,
      sport_id: singleItem?.sport_id,
      selectedPerson: selectedPerson,
      selectedAddons: selectedAddons,
      selectedPackage: selectedPackage,
      selectedslot: selectedslot,
      start_date: DateFormat(moment(selecteddate).toDate()),
    };
    setfinishloading(true);

    axios
      .post("ifpackagebuyed", checkdata)
      .then((res) => {
        if (res.data.message) {
          setfinishloading(false);
          toast.error("You have Already bought This Package");
          return;
        } else {
          console.log("no fresh");
          //return;
          if (cart?.length > 0) {
            const alreadyexists = cart?.filter(
              (item) =>
                item?.selectedPerson === cart_data.selectedPerson &&
                item.selectedPackage === cart_data.selectedPackage &&
                item.selectedAddons.toString() ===
                  cart_data.selectedAddons.toString() &&
                item.selectedslot === cart_data.selectedslot
            );

            if (alreadyexists.length > 0) {
              setfinishloading(false);
              toast.error("This Package Already in Your Cart");
            } else {
              const checksameAcademy = cart?.filter(
                (itemcart, i) => itemcart?.academy_id === cart_data?.academy_id
              );
              if (checksameAcademy.length > 0) {
                dispatch(addToCart(cart_data));
                // toast("Added to Your Cart");
                setfinishloading(false);
                dispatch(resetSelectedThings());
                AddtoCartFinish();
                dispatch(setIsCartModalVisible(true));
              } else {
                toast.error(
                  "Whoops !! You have Allowed to add to cart Only one Academy Packages"
                );
                setfinishloading(false);

                dispatch(resetSelectedThings());
                setCurrentStepIndex(0);

                return;
              }
            }
          } else {
            dispatch(addToCart(cart_data));
            // toast("Added to Your Cart");
            setfinishloading(false);
            dispatch(resetSelectedThings());
            AddtoCartFinish();
            dispatch(setIsCartModalVisible(true));
          }
        }
      })
      .catch((error) => {
        setfinishloading(false);
      });

    //return;

    // checking cart

    //dispatch(addToCart(cart_data));
    //console.log(alreadyexists, "yes");
    //toast("Added to Your Cart");
    // dispatch(resetSelectedThings());

    // alert("Successful Account Creation");
  }
  return (
    <>
      {cartmodal && (
        <ModalBox>
          {/* <h5 className="cart-option-head">
            Successfully Added to Your Cart !!
          </h5> */}
          <div className="cart-options">
            <button
              className="btn secondary_clr"
              onClick={() => handlepagechange("checkout")}
            >
              Checkout
            </button>
            <button
              className="btn primary_clr_background"
              onClick={() => handlepagechange("home")}
            >
              Continue Shopping
            </button>
          </div>
        </ModalBox>
      )}
      <div className="choose_slot">
        <h3 className="font_call">Choose your slot</h3>

        <form
          onSubmit={onSubmit}
          name="multistepform"
          className="msfform my-lg-5 mt-4 mb-5"
        >
          <ul id="progressbar">
            {addons && addons.length > 0 ? (
              <>
                <li
                  style={{ width: "30%" }}
                  className={`${page === 1 ? "active" : ""}`}
                  id="step1"
                >
                  <span>Select Packages</span>
                </li>

                <li
                  style={{ width: "15%" }}
                  id="step5"
                  className={`${page === 2 ? "active" : ""}`}
                >
                  <span>Addons</span>
                </li>

                <li
                  style={{ width: "25%" }}
                  id="step2"
                  className={`${page === 3 ? "active" : ""}`}
                >
                  <span>Select person</span>
                </li>
                <li
                  style={{ width: "15%" }}
                  id="step3"
                  className={`${page === 4 ? "active" : ""}`}
                >
                  <span>Slot</span>
                </li>
                <li
                  style={{ width: "15%" }}
                  id="step4"
                  className={`${page === 5 ? "active" : ""}`}
                >
                  <span>Date</span>
                </li>
              </>
            ) : (
              <>
                <li
                  style={{ width: "30%" }}
                  className={`${page === 1 ? "active" : ""}`}
                  id="step1"
                >
                  <span>Select Packages</span>
                </li>

                <li
                  id="step2"
                  style={{ width: "30%" }}
                  className={`${page === 2 ? "active" : ""}`}
                >
                  <span>Select person</span>
                </li>
                <li
                  style={{ width: "20%" }}
                  id="step3"
                  className={`${page === 3 ? "active" : ""}`}
                >
                  <span>Slot</span>
                </li>
                <li
                  style={{ width: "20%" }}
                  id="step4"
                  className={`${page === 4 ? "active" : ""}`}
                >
                  <span>Date</span>
                </li>
              </>
            )}

            {/* {type && type === "Gym & Fitness" ? (
              <>
                <li className={`${page === 1 ? "active" : ""}`} id="step1">
                  <span>Select Packages</span>
                </li>

                <li id="step5" className={`${page === 2 ? "active" : ""}`}>
                  <span>Addons</span>
                </li>

                <li id="step2" className={`${page === 3 ? "active" : ""}`}>
                  <span>Select person</span>
                </li>

                <li id="step3" className={`${page === 4 ? "active" : ""}`}>
                  <span>Slot</span>
                </li>
                <li id="step4" className={`${page === 5 ? "active" : ""}`}>
                  <span>Date</span>
                </li>
              </>
            ) : (
              <>
                <li className={`${page === 1 ? "active" : ""}`} id="step1">
                  <span>Select Packages</span>
                </li>
                <li id="step5" className={`${page === 2 ? "active" : ""}`}>
                  <span>Addons</span>
                </li>

                <li id="step2" className={`${page === 2 ? "active" : ""}`}>
                  <span>Select person</span>
                </li>

                <li id="step3" className={`${page === 3 ? "active" : ""}`}>
                  <span>Slot</span>
                </li>
                <li id="step4" className={`${page === 4 ? "active" : ""}`}>
                  <span>Date</span>
                </li>
              </>
            )} */}
          </ul>
          <div className="progress" style={{ display: "none" }}>
            <div
              className="progress-bar progress-bar-striped progress-bar-animated progress_bar_msf"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <br />

          {step}
          {!isFirstStep && (
            <input
              type="button"
              name="previous"
              className="previous action-button-previous float-left"
              value="Prev"
              onClick={back}
            />
          )}

          {!isLastStep ? (
            <>
              {slotavailable &&
                (isLoggedin ? (
                  <>
                    <button
                      type="submit"
                      className="next action-button float-right"
                    >
                      {isLastStep ? "Finish" : "Next"}
                    </button>
                  </>
                ) : (
                  <LoginError academy_id={id} slug={slug} />
                ))}
            </>
          ) : (
            <>
              <button
                type="submit"
                className="next action-button float-right"
                disabled={finishloading}
              >
                {finishloading ? "Loadin..." : "Finish"}
              </button>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default Slot;
