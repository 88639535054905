import React, { useEffect } from "react";
import Slider from "react-slick";
import SportsCard from "../academics/SportsCard";
import img1 from "../../assets/images/equipments.jpg";
import img2 from "../../assets/images/food3.png";
import { Sportsbanner } from "../../common/slider/Settings";
const StoreSlider = ({ data, setCategory, Category }) => {
  return (
    <>
      {data && data.length > 5 ? (
        <>
          <Slider className=" sportz_slider " {...Sportsbanner}>
            {data.map((data, i) => {
              return (
                <SportsCard
                  key={data.id}
                  id={data.id}
                  img={data.imagebig}
                  title={data.categoryname}
                  setCategory={setCategory}
                  Category={Category}
                />
              );
            })}
          </Slider>
        </>
      ) : (
        <>
          <div className="sportz_slider justify_class">
            {data.map((data, i) => {
              return (
                <SportsCard
                  key={data.id}
                  id={data.id}
                  img={data.imagebig}
                  title={data.categoryname}
                  setCategory={setCategory}
                  Category={Category}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default StoreSlider;
