document.addEventListener("DOMContentLoaded", function () {
  window.addEventListener("scroll", function () {
    if (window.scrollY > 50) {
      document.getElementById("stickyhead").classList.add("sticky");
      document.getElementById("stickyhead").classList.remove("mymenu");
      // add padding top to show content behind navbar
      var navbar_height = document.querySelector(".navbar").offsetHeight;
      document.body.style.paddingTop = navbar_height + "px";
    } else {
      document.getElementById("stickyhead").classList.remove("sticky");
      document.getElementById("stickyhead").classList.add("mymenu");
      // remove padding top from body
      document.body.style.paddingTop = "0";
    }
  });
});
