import React from "react";
import { Link } from "react-router-dom";
import GymSlider from "./GymSlider";

const Gymsection = ({ gymString }) => {
  return (
    <>
      <div className="container py-5">
        <div className="row pt-3 align-items-center	">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <h1 className="gym_head">
              {gymString ? gymString : "Gym & Fitness"}
            </h1>
            <Link to="/gym-fitness" className="btn gym_btn mt-3">
              View all Gyms
            </Link>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 pt-5 pt-md-0">
            <GymSlider />
            {/* slider begin */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gymsection;
