import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../services/apiUrl";
import { STATUS } from "../../services/Status";

const initialState = {
  academies: [],
  featuresports: [],
  featurecoach: [],
  featuregym: [],
  alllocations: [],
  academyCount: 0,
  status: STATUS.LOADING,
  errormessage: "",
  searchItems: [],
  searchItemsbanner: [],
  searchstatus: STATUS.LOADING,
  academyString: "",
  gymString: "",
  config: [],
  snackbar: false,
  policies: {},
  blogs: [],
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setAcademies(state, action) {
      state.academies = action.payload;
      state.academyCount = action.payload.length;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setsearchstatus(state, action) {
      state.searchstatus = action.payload;
    },

    setfeaturesports(state, action) {
      state.featuresports = action.payload;
    },
    setfeaturecoach(state, action) {
      state.featurecoach = action.payload;
    },

    setfeaturegym(state, action) {
      state.featuregym = action.payload;
    },

    setLocations(state, action) {
      let unique = [...new Set(action.payload)];

      state.alllocations = unique;
    },

    setSearchItems(state, action) {
      state.searchItems = action.payload;
    },
    setSearchItemsBanner(state, action) {
      state.searchItemsbanner = action.payload;
    },

    setacademyString(state, action) {
      state.academyString = action.payload;
    },
    setgymString(state, action) {
      state.gymString = action.payload;
    },

    setConfig(state, action) {
      state.config = action.payload;
    },
    setSnackabar(state, action) {
      state.snackbar = action.payload;
    },
    resetsearchItems(state) {
      state.searchItems = [];
    },
    resetsearchItemsbanner(state) {
      state.searchItemsbanner = [];
    },
    setPolicydata(state, action) {
      state.policies = action.payload;
    },

    setBlogs(state, action) {
      state.blogs = action.payload;
    },
  },
});
export const {
  setAcademies,
  setStatus,
  setfeaturesports,
  setfeaturegym,
  setLocations,
  setSearchItems,
  setsearchstatus,
  setacademyString,
  setgymString,
  setConfig,
  setSnackabar,
  resetsearchItems,
  resetsearchItemsbanner,
  setSearchItemsBanner,
  setfeaturecoach,
  setPolicydata,
  setBlogs,
} = homeSlice.actions;
export default homeSlice.reducer;

export const fetchAcademies = (location, option) => {
  //console.log(location, option);
  return async function fetchAcademiesThunk(dispatch) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const response = await fetch(
        `${BASE_URL}GetAcademyList/${option}/${location}`
      );
      const data = await response.json();
      dispatch(setAcademies(data));
      dispatch(setStatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};

export const fetchBlogs = (url) => {
  //console.log(location, option);
  return async function fetchBlogsThunk(dispatch) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const response = await fetch(`${BASE_URL}${url}`);
      const data = await response.json();
      dispatch(setBlogs(data.blog));
      dispatch(setStatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};

export const fetchFeaturehome = (location, lat, lng) => {
  return async function fetchFeatureThunk(dispatch) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const response = await fetch(
        `${BASE_URL}GetFeaturedAcademy/${location}?lat=${lat}&lng=${lng}`
      );
      const data = await response.json();
      dispatch(setfeaturesports(data.sports));
      dispatch(setfeaturegym(data.gym));
      dispatch(setfeaturecoach(data.coach));
      dispatch(setStatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};
export const fetchLocations = () => {
  return async function fetchLocationThunk(dispatch) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const response = await fetch(`${BASE_URL}GetAllLocations`);
      const data = await response.json();
      dispatch(setLocations(data.data));

      dispatch(setStatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};

export const fetchSearchitems = (search, location, type) => {
  return async function fetchSearchitemThunk(dispatch) {
    dispatch(setsearchstatus(STATUS.LOADING));
    try {
      const response = await fetch(
        `${BASE_URL}GetSearchAcademy/${search}/${location}`
      );
      const data = await response.json();
      type === "banner"
        ? dispatch(setSearchItemsBanner(data.data))
        : dispatch(setSearchItems(data.data));

      dispatch(setsearchstatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setsearchstatus(STATUS.ERROR));
    }
  };
};

export const fetchSiteconfig = () => {
  return async function fetchSiteconfig(dispatch) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const response = await fetch(`${BASE_URL}config`);
      const data = await response.json();
      dispatch(setConfig(data.data));

      dispatch(setStatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};
export const policydata = () => {
  return async function policydata(dispatch) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const response = await fetch(`${BASE_URL}policypage`);
      const data = await response.json();
      //console.log(data?.policypages);
      dispatch(setPolicydata(data?.policypages));

      dispatch(setStatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};
