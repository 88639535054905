import React from "react";
import Slider from "react-slick";

import BannerContent from "./BannerContent";
import { Mainbanner } from "../../common/slider/Settings";
import useFetch from "../../hooks/useFetch";
import { BASE_URL, IMAGE_URL } from "../../services/apiUrl";
import noimage from "../../../src/assets/images/noimage.jpg";
import { useDispatch } from "react-redux";
import { setacademyString, setgymString } from "../../features/home/homeSlice";
export const Banner = () => {
  const { data, loading, error } = useFetch(`${BASE_URL}homepage`);
  const dispatch = useDispatch();

  if (data) {
    dispatch(setacademyString(data?.homepages?.section1));
    dispatch(setgymString(data?.homepages?.section2));
  }

  const image1 =
    data.homepages && data.homepages.himage1
      ? `${IMAGE_URL}${data.homepages.himage1}`
      : noimage;
  const image2 =
    data.homepages && data.homepages.himage2
      ? `${IMAGE_URL}${data.homepages.himage2}`
      : noimage;
  const image3 =
    data.homepages && data.homepages.himage3
      ? `${IMAGE_URL}${data.homepages.himage3}`
      : noimage;

  return (
    <>
      <section className="homebanner_sec">
        <Slider {...Mainbanner} className="banner_slider">
          <div className="imagehome1">
            <div
              className="home_banner"
              style={{ backgroundImage: `url(${image1})` }}
            ></div>
          </div>

          <div className="imagehome2">
            <div
              className="home_banner"
              style={{ backgroundImage: `url(${image2})` }}
            ></div>
          </div>

          <div className="imagehome3">
            <div
              className="home_banner"
              style={{ backgroundImage: `url(${image3})` }}
            ></div>
          </div>
        </Slider>

        <BannerContent
          heading={data?.homepages?.hheading}
          subheading={data?.homepages?.hsubheading}
        />
      </section>
    </>
  );
};
