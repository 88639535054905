import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import coder from "../../assets/images/Vector.png";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { CurrentDateFormat, Removspace } from "../../services/Helper";
import { BASE_URL, IMAGE_URL } from "../../services/apiUrl";
const Popup = (props) => {
  const { user, familymembers, demo_user } = useSelector((state) => state.user);

  const [status, setstatus] = useState(null);

  useEffect(() => {
    setstatus(Removspace(props?.userinfo?.status));
  }, [props?.userinfo]);

  const Generateurl = (user) => {
    let url = `${IMAGE_URL}administrator/putAttendance/${user?.order_item_id}/${user?.date}`;

    return url;
  };

  const RenderQrcode = () => {
    if (!status) {
      return <p className="text-success">Classes Not Added</p>;
    }
    if (status == "present") {
      return <p className="text-success">Already Attendance Added</p>;
    }
    if (status == "noclass") {
      return <p className="text-success">No Class Today</p>;
    }

    if (status == "holiday") {
      return <p className="text-success">Today Holiday</p>;
    }
    return (
      <QRCode
        size={256}
        style={{
          height: "auto",
          maxWidth: "100%",
          width: "100%",
        }}
        value={Generateurl(props?.userinfo)}
        viewBox={`0 0 256 256`}
      />
    );
  };

  return (
    <>
      <div className="attendance">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="attendancemodal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {/* <button type="button" aria-label="Close" data-mdb-dismiss="modal">
                close
              </button> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="model_popup">
              <div className="attendance_view">
                <div className="tag_content">
                  <p className="tag_data">Attendance :{CurrentDateFormat()}</p>
                </div>
                <p className="highlight_head">👋 Hello, {user?.membername}</p>
                <p className="sub_text">
                  Scan QR code from your phone to mark attendance.
                </p>
                <div className="step_text">
                  <p className="list_head">Steps to mark attendance</p>
                  <ul>
                    <li className="list_text">Open sportslete application</li>
                    <li className="list_text">Tap on attendance tab</li>
                    <li className="list_text">
                      Scan QR code to mark attendance
                    </li>
                  </ul>
                </div>
              </div>

              <div className="qr_container">
                <div className="attendance_view">
                  {/* <img src={coder} width="180px" /> */}

                  {RenderQrcode()}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Popup;
