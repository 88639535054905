import React from "react";
import { useDispatch } from "react-redux";
import { removeCart } from "../../features/cart/cartSlice";
import { IMAGE_URL } from "../../services/apiUrl";
import { toIndianCurrency } from "../../services/Helper";

const CartCard = ({ cart, type }) => {
  const dispatch = useDispatch((state) => state.cart);
  return (
    <>
      <div className="cart_item" key={cart?.id}>
        <div
          className={`${
            type === "checkout" ? "row" : "sportcart"
          } align-items-center py-2`}
        >
          {/* <div className="col-3 pr-1">
            <img
              src={`${IMAGE_URL}${cart?.academy_img}`}
              className="img-fluid cart_img"
            />
          </div> */}
          <div className="col-sm-7 col-7">
            <h6 className="mb-0">{cart?.academy_name}</h6>
            <div className="pack__info">
              <small>
                Member Name :{" "}
                <span className="strong_cls">{cart?.selectedPersonName}</span>
              </small>
              {type === "checkout" && (
                <>
                  <small>
                    Package Name :{" "}
                    <span className="strong_cls">
                      {cart?.selectedPackageName}
                      <span className="text-danger float_right">
                        {/* <s className="text-danger" style={{ fontSize: "11px" }}>
                          {toIndianCurrency(cart?.packageprice)}
                        </s> */}
                        {toIndianCurrency(
                          Math.round(
                            cart?.packageprice +
                              (cart?.packageprice *
                                Number(cart?.selectedPackageGst)) /
                                100
                          )
                        )}{" "}
                        ( {cart?.selectedPackageGst}%)
                      </span>
                    </span>
                  </small>
                  <small>Addons Selected : </small>

                  {cart?.addoninfo.map((addon, i) => (
                    <small key={i} className="strong_cls">
                      {addon?.name}{" "}
                      <span className="text-danger float_right">
                        {/* <s className="text-danger" style={{ fontSize: "11px" }}>
                          -{toIndianCurrency(addon?.price)}
                        </s> */}
                        {toIndianCurrency(
                          Math.round(
                            addon?.price +
                              (addon?.price * Number(addon?.gst)) / 100
                          )
                        )}{" "}
                        ({addon?.gst}%)
                      </span>
                    </small>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="col-sm-4 col-4 p-0">
            <div className="price_details">
              <p className="mb-0 cate_price strong_cls">
                {toIndianCurrency(cart?.totalpriceGst)}
              </p>
            </div>
          </div>
          <div className="col-1 px-0">
            <p
              className="mb-0 text-dark"
              onClick={() => dispatch(removeCart(cart?.id))}
            >
              <i className="fa fa-trash"></i>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartCard;
