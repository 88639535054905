import React, { useState } from "react";
import classes from "./Login.module.css";
import SocialMedialogin from "./SocialMedialogin";
import CircularProgress from "@mui/material/CircularProgress";
import OtpForm from "./OtpForm";
import ProfileCimplete from "./ProfileCimplete";
import { useSelector } from "react-redux";

const Signin = ({
  SocialLogin,
  setLoginpage,
  Loginpage,
  onSubmitHandler,
  errors,
  loading,
  getOtp,
  SocialLoginprofile,
}) => {
  const { Otpverified } = useSelector((state) => state.user);
  const [Logininput, setLogininput] = useState(null);

  const [formValid, setformValid] = useState(false);

  const Handleinput = (e) => {
    e.preventDefault();

    e.target.value.length === 0 && setformValid(false);
    e.target.value.length > 1 && setformValid(true);

    setLogininput(e.target.value);
  };

  const GetotpHandler = (e) => {
    e.preventDefault();

    const data = {
      email: Logininput,
    };
    onSubmitHandler(data);
  };
  return (
    <>
      {!getOtp && (
        <>
          <form onSubmit={GetotpHandler} className="lform">
            <div className="form_input">
              <input
                type="text"
                placeholder="Phone number/Email"
                name=""
                required
                className="form-control"
                onChange={Handleinput}
              />
              {errors.length !== 0 && errors.email ? (
                <p className={classes.validation}>{errors.email[0]}</p>
              ) : null}
            </div>

            <button
              type="submit"
              className="sportsbutton"
              disabled={!formValid}
              style={{ backgroundColor: !formValid ? "grey" : "" }}
            >
              {!loading ? (
                "Get OTP"
              ) : (
                <CircularProgress className={classes.progressbar_login} />
              )}
            </button>
          </form>
        </>
      )}

      {getOtp && !Otpverified ? (
        <OtpForm type="login" />
      ) : (
        getOtp && <ProfileCimplete />
      )}

      {/* <SocialMedialogin type="login" /> */}
    </>
  );
};

export default Signin;
