import React, { useState } from "react";
import Chekbox from "./Chekbox";
import img1 from "../../../assets/images/person.png";
import { IMAGE_URL } from "../../../services/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAddons } from "../../../features/cart/cartSlice";
import Slider from "react-slick";
import { PackSlider_, Person } from "../../../common/slider/Settings";
import { toIndianCurrency } from "../../../services/Helper";

const Step5 = () => {
  const [person, setperson] = useState(false);
  const dispatch = useDispatch();
  const { addons, selectedAddons } = useSelector((state) => state.cart);

  const [selectAddons, setselectAddons] = useState([]);

  const handleSelect = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;

    setselectAddons(
      checked
        ? [...selectAddons, value]
        : selectAddons.filter((item) => item !== value)
    );

    console.log(selectAddons);

    // dispatch(setSelectedAddons(selectAddons));
  };

  const clickevent = (tag) => {
    const element = document.querySelector(`#${tag}`);
    const confee = element.dataset.confee;
    if (element.checked) {
      dispatch(setSelectedAddons([true, element.value, element.name, confee]));
    } else {
      dispatch(setSelectedAddons([false, element.value, element.name, confee]));
    }
  };

  return (
    <>
      <fieldset>
        <div className="step1">
          <>
            <Slider {...PackSlider_} className="row pack_slider ">
              {addons &&
                addons.length > 0 &&
                addons.map((addon, i) => (
                  <>
                    <>
                      <div
                        className="pack_content toolpack"
                        key={addon.id}
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title={addon?.description}
                      >
                        <label htmlFor={`person${addon.id}`}>
                          <input
                            type="checkbox"
                            name={addon?.addonname}
                            id={`person${addon.id}`}
                            value={addon.id}
                            data-confee={addon?.convfee}
                            defaultChecked={selectedAddons.includes(addon.id)}
                            style={{
                              backgroundColor: selectedAddons.includes(addon.id)
                                ? "red"
                                : "",
                            }}
                            onChange={() => clickevent(`person${addon.id}`)}
                          />

                          <div className="pack_box">
                            <div className="pack_box_top text-white text-center">
                              <h5>{addon?.addonname}</h5>

                              {addon?.offerprice ? (
                                <>
                                  {addon?.offertype === "1" ? (
                                    <>
                                      <h4>
                                        {toIndianCurrency(
                                          +addon?.offerpricenet
                                        )}
                                      </h4>
                                      <h6>
                                        {toIndianCurrency(
                                          +addon?.offernettotal
                                        )}
                                      </h6>
                                    </>
                                  ) : (
                                    <>
                                      {/* <h4>
                                        {toIndianCurrency(
                                          Math.round(
                                            (addon?.price * addon?.offerprice) /
                                              100
                                          )
                                        )}
                                      </h4>
                                      <h6>{toIndianCurrency(+addon?.price)}</h6> */}
                                      <h4>
                                        {toIndianCurrency(addon?.offerpricenet)}
                                      </h4>
                                      <h6>
                                        {toIndianCurrency(addon?.offernettotal)}
                                      </h6>
                                    </>
                                  )}
                                </>
                              ) : (
                                <h4>{toIndianCurrency(+addon?.nettotal)}</h4>
                              )}
                            </div>
                            <div className="pack_box_bottom p-3 text-left">
                              <div className="pack_des">
                                <h5>
                                  <i className="fa fa-check"></i>
                                  {addon?.comment1}
                                </h5>
                                <h5>
                                  <i className="fa fa-check"></i>
                                  {addon?.comment2}
                                </h5>
                                <h5>
                                  <i className="fa fa-check"></i>
                                  {addon?.comment3}
                                </h5>
                                <h5>
                                  <i className="fa fa-check"></i>
                                  {addon?.comment4}
                                </h5>
                                <h5></h5>
                              </div>

                              <br />
                              <div
                                className="select_span text-center"
                                style={{
                                  backgroundColor: selectedAddons.includes(
                                    addon.id
                                  )
                                    ? "#de4b31"
                                    : "",
                                }}
                              >
                                <i className="fa fa-check"></i>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </>
                  </>
                ))}
            </Slider>
          </>
          {/* <Slider {...Person} className="row person_slider">
            {addons && addons.length > 0
              ? addons.map((addon, i) => (
                  <>
                    <div key={i}>
                      <label htmlFor={`person${addon.id}`}>
                        <input
                          type="checkbox"
                          name="person_select"
                          id={`person${addon.id}`}
                          value={addon.id}
                          defaultChecked={selectedAddons.includes(addon.id)}
                          style={{
                            backgroundColor: selectedAddons.includes(addon.id)
                              ? "red"
                              : "",
                          }}
                          onChange={() => clickevent(`person${addon.id}`)}
                        />
                        <div className="person_box text-center">
                          <img
                            src={`${IMAGE_URL}${addon?.displayimg}`}
                            className="img-fluid"
                          />
                          <h6 className="py-2">{addon?.addonname}</h6>

                          {addon?.offerprice ? (
                            <>
                              {addon?.offertype === "1" ? (
                                <>
                                  <div
                                    className="d-flex aligncenter"
                                    style={{ gap: "10px" }}
                                  >
                                    <b>₹ {addon?.offerprice}</b>
                                    <s>₹ {addon?.price}</s>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="d-flex"
                                    style={{ gap: "10px" }}
                                  >
                                    <b>
                                      ₹{" "}
                                      {Math.round(
                                        (addon?.price * addon?.offerprice) / 100
                                      )}
                                    </b>
                                    <s>₹ {addon?.price}</s>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <b>₹ {addon?.price}</b>
                          )}
                          <div className="select_span text-center">
                            <i className="fa fa-check"></i>
                          </div>
                        </div>
                      </label>
                    </div>
                  </>
                ))
              : "No Addons Available"}
          </Slider> */}
        </div>
      </fieldset>
    </>
  );
};

export default Step5;
