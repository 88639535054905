import React from "react";
import ContentLoader from "react-content-loader";

const SearchLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={60}
      viewBox="0 0 400 60"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="58" y="9" rx="9" ry="9" width="400" height="6" />
      <rect x="58" y="26" rx="3" ry="3" width="52" height="6" />

      <circle cx="25" cy="30" r="25" />
    </ContentLoader>
  );
};

export default SearchLoader;
