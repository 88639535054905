import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import Category from "../../../pages/Category";
import { BASE_URL } from "../../../services/apiUrl";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import MultiRangeSlider from "../../../common/commoncomponents/MultirangeSlider.js/MultiRangeSlider";
import {
  setselectedUrlage,
  setselectedUrlprice,
} from "../../../features/sports/sportSlice";

const FillterModel = ({
  OpenOrClose,
  type,
  applyfilter,
  category,
  agelist,
  pricelist,
  setcategory,
  clearfilter,
}) => {
  const { city } = useSelector((state) => state.user);

  const [selectedcategory, setselectedcategory] = useState(category);
  const [selectedage, setselectedage] = useState(agelist);
  const [clearState, setclearState] = useState(false);

  const [agelimit, setagelimit] = useState({
    min: null,
    max: null,
  });
  const [pricelimit, setpricelimit] = useState({
    min: null,
    max: null,
  });
  const [selectedprice, setselectedprice] = useState(pricelist);

  const { selectedurlage, selectedurlprice } = useSelector(
    (state) => state.sport
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setselectedcategory(category);
    setselectedprice(pricelist);
    setselectedage(agelist);
    dispatch(setselectedUrlage(agelist));
    dispatch(setselectedUrlprice(pricelist));
  }, [category, agelist, pricelist]);

  let url;

  if (type === "sports" && city) {
    url = `GetAllFilterOptions/1/${city}/1`;
  } else if (type === "coach") {
    url = `GetAllFilterOptions/1/${city}/0`;
  } else if (type === "blog") {
    url = "GetBlogCategoryList";
  } else {
    url = `GetAllFilterOptions/0/${city}/1
    `;
  }

  const { data, error, loading } = useFetch(url);
  const [checked, setchecked] = useState(false);

  useEffect(() => {
    if (data?.agelist) {
      setagelimit({
        min: data?.agelist[1],
        max: data?.agelist[0],
      });
    }

    if (data?.pricelist) {
      setpricelimit({
        min: data?.pricelist[1],
        max: data?.pricelist[0],
      });
    }
  }, [data, clearState]);

  const removefil = () => {
    document.getElementById("filterdiv").classList.remove("open");
    document.getElementById("filterbtn").classList.remove("open");
    document.body.classList.remove("lock-scroll");
  };

  const onSubmitapplyfilter = (e) => {
    e.preventDefault();

    const category = getCheckedvalues("categorycheckbox");
    //const pricelist = getCheckedvalues("pricecheckbox");
    //const agelist = getCheckedvalues("agecheckbox");
    const agelist = selectedage;
    const pricelist = selectedprice;

    let data = {
      category,
      pricelist,
      agelist,
    };
    applyfilter(data);
  };

  const getCheckedvalues = (name) => {
    const checked = Array.from(
      document.querySelectorAll(`input[name="${name}"]:checked`)
    ).map((data) => {
      return data.value;
    });

    return checked;
  };

  const handlechangeCategory = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    setselectedcategory(
      checked
        ? [...selectedcategory, value]
        : selectedcategory.filter((item) => item !== value)
    );
  };
  const handlechangeage = (min, max) => {
    // const checked = e.target.checked;
    // const value = e.target.value;
    setselectedage([Number(min), Number(max)]);
  };

  const handlechangeprice = (min, max) => {
    // const checked = e.target.checked;
    // const value = e.target.value;
    // setselectedprice(
    //   checked
    //     ? [...selectedprice, value]
    //     : selectedprice.filter((item) => item !== value)
    // );

    setselectedprice([Number(min), Number(max)]);
  };

  const Sliderchange = (value) => {
    console.log("value", value);
  };

  const FilterButton = () => {
    // (data && (data?.pricelist || []).length != 0) ||
    //           (data?.agelist || []).length != 0 ||
    //           ((data?.category || []).length != 0

    if (
      (data?.pricelist || []).length != 0 ||
      (data?.agelist || []).length != 0 ||
      (data?.category || []).length != 0
    ) {
      return (
        <>
          <div className="filter_footer">
            <label htmlFor="fil_reset" className="reset_cls mr-3">
              <i className="fa fa-undo"></i>

              <input
                type="button"
                name="fil_reset"
                id="fil_reset"
                value="Clear"
                onClick={() => {
                  setclearState(!clearState);
                  clearfilter();
                  setselectedage([agelimit?.min, agelimit?.max]);
                }}
              />
            </label>

            <input
              type="submit"
              name="fil_sub"
              id="fil_sub"
              value={type == "blog" ? "Search Blog" : "Search Academy"}
              className="fil_btn"
              onClick={onSubmitapplyfilter}
            />
          </div>
        </>
      );
    }
  };

  return (
    <>
      {" "}
      <div id="filterdiv">
        <div className="filtersidebar" onClick={removefil}></div>
        <div className="filterdiv_inner">
          <form className="filterform">
            <h4 className="mb-5">
              <a
                href="#"
                id="filterclose"
                className="closebtn text-dark"
                onClick={OpenOrClose}
              >
                ×
              </a>
              Filter By
            </h4>
            {((type && type == "sports") ||
              type == "coach" ||
              type == "blog") && (
              <>
                <div className="mb-4 __extra">
                  {data && (data.category || []).length != 0 && (
                    <h6>{type == "blog" ? "Blog" : "Sports"} Category</h6>
                  )}

                  <div className="row">
                    {data && data.category ? (
                      <>
                        {type == "blog" ? (
                          <>
                            {data.category.map((cat, i) => (
                              <div className="col-sm-6 col-12" key={i}>
                                <input
                                  type="checkbox"
                                  name="categorycheckbox"
                                  id={`cate_filter${i}`}
                                  checked={selectedcategory.includes(cat)}
                                  onChange={handlechangeCategory}
                                  value={cat || ""}
                                />
                                <label htmlFor={`cate_filter${i}`}>{cat}</label>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            {data.category.map((cat, i) => (
                              <div className="col-sm-6 col-12" key={i}>
                                <input
                                  type="checkbox"
                                  name="categorycheckbox"
                                  id={`cate_filter${i}`}
                                  checked={selectedcategory.includes(
                                    cat?.categoryname
                                  )}
                                  onChange={handlechangeCategory}
                                  value={cat?.categoryname || ""}
                                />
                                <label htmlFor={`cate_filter${i}`}>
                                  {cat?.categoryname}
                                </label>
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      "..."
                    )}
                  </div>
                </div>
              </>
            )}

            {data?.agelist && data?.agelist.length > 0 && (
              <div className="mb-4 __extra">
                <div className="row">
                  {data && (data.agelist || []).length != 0 && <h6>Agelist</h6>}
                  {data && data.agelist && data?.agelist.length > 0 ? (
                    <>
                      <MultiRangeSlider
                        min={agelimit?.min}
                        max={agelimit?.max}
                        selectedmin={selectedurlage[0] || agelimit?.min}
                        selectedmax={selectedurlage[1] || agelimit?.max}
                        onChange={({ min, max }) => handlechangeage(min, max)}
                        setclearState={clearState}
                      />
                    </>
                  ) : (
                    ".."
                  )}

                  {/* {data && data.agelist ? (
                  <>
                    {data.agelist.map((age, i) => (
                      <div className="col-6" key={i}>
                        <input
                          type="checkbox"
                          name="agecheckbox"
                          id={`age_filter${i}`}
                          checked={selectedage.includes(age.agelimit)}
                          onChange={handlechangeage}
                          value={age}
                        />
                        <label htmlFor={`age_filter${i}`}>{age}</label>
                      </div>
                    ))}
                  </>
                ) : (
                  "..."
                )} */}
                </div>
              </div>
            )}

            {/* rating */}
            {/* <div className="mb-4 __extra">
              <h6>Rating</h6>

              <div className="row">
                <div className="col-6">
                  <input
                    type="checkbox"
                    name="rating_filter1"
                    id="rating_filter1"
                  />
                  <label htmlFor="rating_filter1">1 Star</label>
                </div>
                <div className="col-6">
                  <input
                    type="checkbox"
                    name="rating_filter2"
                    id="rating_filter2"
                  />
                  <label htmlFor="rating_filter2">2 Star</label>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <input
                    type="checkbox"
                    name="rating_filter3"
                    id="rating_filter3"
                  />
                  <label htmlFor="rating_filter3">3 Star</label>
                </div>
                <div className="col-6">
                  <input
                    type="checkbox"
                    name="rating_filter4"
                    id="rating_filter4"
                  />
                  <label htmlFor="rating_filter4">4 Star</label>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <input
                    type="checkbox"
                    name="rating_filter5"
                    id="rating_filter5"
                  />
                  <label htmlFor="rating_filter5">5 Star</label>
                </div>
              </div>
            </div> */}
            {/* <div className="mb-4 __extra">
              <h6>Location</h6>

              <div className="row">
                <div className="col-6">
                  <input
                    type="checkbox"
                    name="location_filter1"
                    id="location_filter1"
                  />
                  <label htmlFor="location_filter1">Bangalore</label>
                </div>
                <div className="col-6">
                  <input
                    type="checkbox"
                    name="location_filter2"
                    id="location_filter2"
                  />
                  <label htmlFor="location_filter2">Chennai</label>
                </div>
              </div>
            </div> */}
            {/* <div className="mb-4 __extra">
              <div className="row">
                {data && (data.pricelist || []).length != 0 && (
                  <h6>Pricelist</h6>
                )}
                {data && data.pricelist ? (
                  <>
                    {data.pricelist.map((price, i) => (
                      <div className="col-6" key={i}>
                        <input
                          type="checkbox"
                          name="pricecheckbox"
                          id={`price_filter${i}`}
                          value={price}
                          checked={selectedprice.includes(price)}
                          onChange={handlechangeprice}
                        />
                        <label htmlFor={`price_filter${i}`}>{price}</label>
                      </div>
                    ))}
                  </>
                ) : (
                  "..."
                )}
              </div>
            </div> */}

            {data?.pricelist && data?.pricelist.length > 0 && (
              <div className="mb-4 __extra">
                <div className="row">
                  {data && (data.pricelist || []).length != 0 && (
                    <h6>Pricelist</h6>
                  )}
                  {data && data.pricelist && data?.pricelist.length > 0 ? (
                    <>
                      <MultiRangeSlider
                        min={pricelimit?.min}
                        max={pricelimit?.max}
                        selectedmin={selectedurlprice[0] || pricelimit?.min}
                        selectedmax={selectedurlprice[1] || pricelimit?.max}
                        onChange={({ min, max }) => handlechangeprice(min, max)}
                      />
                    </>
                  ) : (
                    ".."
                  )}

                  {/* {data && data.agelist ? (
                  <>
                    {data.agelist.map((age, i) => (
                      <div className="col-6" key={i}>
                        <input
                          type="checkbox"
                          name="agecheckbox"
                          id={`age_filter${i}`}
                          checked={selectedage.includes(age.agelimit)}
                          onChange={handlechangeage}
                          value={age}
                        />
                        <label htmlFor={`age_filter${i}`}>{age}</label>
                      </div>
                    ))}
                  </>
                ) : (
                  "..."
                )} */}
                </div>
              </div>
            )}
            {FilterButton()}
          </form>
        </div>
      </div>
    </>
  );
};

export default FillterModel;
