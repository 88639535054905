import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../services/apiUrl";
import noimage from "../../../src/assets/images/noimage.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { shortString, slugify, createMarkup } from "../../services/Helper";

const CoachCard = ({
  link,
  img,
  desc,
  title,
  key,
  id,
  slug,
  cat,
  city,
  sublocation,
  shortdesc,
}) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const srcfile = img ? `${IMAGE_URL}${img}` : noimage;
  return (
    <>
      <div className="slide_box coach_slide" key={key} data-aos="zoom-in">
        <Link to={`/academy/${id}/${slugify(slug)}`}>
          <div className="coach_top">
            <div
              className="cate_img"
              style={{ backgroundImage: `url(${srcfile})` }}
            >
              {/* <img src={srcfile} alt={title} /> */}
            </div>
          </div>
          <div className="coach_bottom p-4">
            <span className="coach_badge">{cat || ""}</span>
            <h4 className="font-weight-bold mt-5">{title}</h4>
            <hr className="coach_hr"></hr>
            {sublocation != null ? (
              <span>
                {city} - {sublocation}
              </span>
            ) : (
              <span>{city}</span>
            )}
            {shortdesc && (
              <div
                className="custom_style"
                dangerouslySetInnerHTML={createMarkup(
                  shortString(shortdesc, 30)
                )}
              />
            )}
          </div>
        </Link>
      </div>
    </>
  );
};

export default CoachCard;
