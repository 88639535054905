import React, { useState, useEffect } from "react";
import TopBanner from "../common/banner/TopBanner";
import profileimage from "../assets/images/person.png";
import other from "../assets/images/other.png";
import girl from "../assets/images/girl.png";
import kid from "../assets/images/kid.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFamilyMembers,
  fetchGetuser,
  fetchLogout,
  setLoggedin,
  setTotalLoader,
} from "../features/user/userSlice";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "../components/login/Login.module.css";
import FamilyMember from "../components/profile/FamilyMember";
import SingleFamilyMember from "../components/profile/SingleFamilyMember";
import { STATUS } from "../services/Status";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL, IMAGE_URL } from "../services/apiUrl";
import FamilMemberLoader from "../common/loader/FamilMemberLoader";
import Loader from "../common/loader/Loader";
import useFetch from "../hooks/useFetch";
import NotificationTab from "../components/profile/NotificationTab";
import ModelBox from "../common/modal/ModalBox";
import { setIsModalVisible } from "../features/modal/modalSlice";
import { useHistory } from "react-router-dom";
const Profile = () => {
  const {
    data: { aboutuspages },
    loading: abload,
    error: aberror,
  } = useFetch(`${BASE_URL}aboutuspage`);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setTotalLoader(false));
  }, []);
  const history = useHistory();
  const { modalvisible } = useSelector((state) => state.modal);
  const { user, familymembers, status, demo_user } = useSelector(
    (state) => state.user
  );
  const [pincodeerror, setpincodeerror] = useState(false);
  const [loading, setloading] = useState(false);
  const [deleteloading, setdeleteloading] = useState(false);
  const [error, seterror] = useState([]);
  const [getuser, setgetuser] = useState(false);

  const [profileInfo, setprofileInfo] = useState({});

  const [gender, setgender] = useState("male");

  const [profileImage, setprofileImage] = useState({
    image: "",
    imagefile: "",
  });
  const setMaxDateToday = () => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  const deleteaccount = (e) => {
    e.preventDefault();
    setdeleteloading(true);

    axios
      .post("deleteaccount")
      .then((res) => {
        setdeleteloading(false);
        dispatch(fetchLogout());
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
        setdeleteloading(false);
      });
  };

  useEffect(() => {
    setprofileInfo({
      ...profileInfo,
      id: user.id,
      membername: user.membername,
      emailid: user.emailid,
      phoneno: user.phoneno,
      authtype: user.authtype,
      gender: user?.gender || gender,
      dob: user.dob,
      profession: user.profession === "null" ? "" : user.profession,
      address: user.address === "null" ? "" : user.address,
      city: user.city === "null" ? "" : user.city,
      district: user.district ? user.district : "",
      pincode: user.pincode === "null" ? "" : user.pincode,
      displayimg: user?.displayimg,
      comments: user.comments === "null" ? "" : user.comments,
      isnew: user.isnew,
      status: user.status,
    });

    setprofileImage({
      ...profileImage,
      image: user.displayimg,
    });

    setgender(user?.gender);
    setgetuser(true);
  }, [user]);

  const Handleinput = (e) => {
    e.preventDefault();
    setprofileInfo({ ...profileInfo, [e.target.name]: e.target.value });

    if (e.target.name === "pincode" && e.target.value.length > 3)
      getStatewithpincode(e.target.value);
  };

  const clickevent = (e) => {
    e.preventDefault();
    document.querySelector(".profileprev").click();
  };

  const onImageChange = (event) => {
    event.preventDefault();
    console.log(event.target.files);

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setprofileImage({
          image: e.target.result,
          imagefile: event.target.files[0],
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const addRemove = () => {
    document.querySelector(".profile-success").classList.remove("remove");

    setTimeout(() => {
      document.querySelector(".profile-success").classList.add("remove");
    }, 2000);
  };

  const getStatewithpincode = async (value) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${value}`
      );
      const jsonData = await response.json();
      //console.log(jsonData[0]?.PostOffice[0]);

      if (jsonData[0]) {
        if (jsonData[0]?.Status === "404" || jsonData[0]?.Status === "Error") {
          setpincodeerror(true);
          setprofileInfo({
            ...profileInfo,
            ["district"]: "",
            ["pincode"]: value,
          });
          console.log("error recordes");
        } else {
          if (jsonData[0]?.PostOffice[0]?.State)
            setprofileInfo({
              ...profileInfo,
              ["district"]: jsonData[0]?.PostOffice[0]?.State,
              ["pincode"]: value,
            });
          setpincodeerror(false);

          console.log(jsonData[0]?.PostOffice[0]?.State);
        }
      } else {
        setprofileInfo({
          ...profileInfo,
          ["district"]: "",
          ["pincode"]: value,
        });
        setpincodeerror(true);
        console.log("error");
      }
    } catch (error) {
      console.log(error);
      setprofileInfo({
        ...profileInfo,
        ["district"]: "",
      });
      setpincodeerror(true);
    }
  };

  const ProfileSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const formData = new FormData();
    for (var key in profileInfo) {
      formData.append(key, profileInfo[key]);
    }

    formData.append("newimage", profileImage.imagefile);
    try {
      const response = await axios({
        method: "post",
        url: "memberprofileupdate",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      addRemove();
      setloading(false);
      dispatch(fetchFamilyMembers());
      dispatch(fetchGetuser());
      seterror([]);
      setprofileImage({
        image: "",
        imagefile: "",
      });
    } catch (error) {
      setloading(false);
      console.log(error);
      if (error.response.data.errors) seterror(error.response.data.errors);
    }
  };

  const Findimage = (string) => {
    let image;
    if (string === null) {
      if (profileInfo?.gender === "male") {
        image = profileimage;
      } else if (profileInfo?.gender === "female") {
        image = girl;
      } else {
        image = other;
      }
    } else if (string.includes("base64")) {
      image = string;
    } else if (string.includes("static")) {
      image = string;
    } else if (string.includes("https")) {
      image = string;
    } else {
      image = `${IMAGE_URL}${string}`;
    }

    return image;
  };
  const genderChange = (e) => {
    setgender(e.target.value);
    setprofileInfo({ ...profileInfo, gender: e.target.value });
  };
  return (
    <>
      {modalvisible && (
        <ModelBox>
          <div className="delete__confirmation text-center">
            <i
              class="fa fa-warning"
              style={{ fontSize: "48px", color: "red" }}
            ></i>
            <h4 className="text-danger">Are You Sure</h4>
            <p>Do you really want to Delete This Account ?</p>
          </div>
          <div className="cart-options">
            {!deleteloading && (
              <button
                className="btn btn-primary"
                onClick={() => dispatch(setIsModalVisible(false))}
              >
                Cancel
              </button>
            )}

            {deleteloading ? (
              <CircularProgress className={classes.progressbar} />
            ) : (
              <button className="btn btn-danger" onClick={deleteaccount}>
                Delete Account
              </button>
            )}
          </div>
        </ModelBox>
      )}
      {abload ? (
        <>
          {" "}
          <Loader />
        </>
      ) : (
        <TopBanner title="Profile" image={aboutuspages?.profilebanner} />
      )}

      <div className="container">
        <div className="banner_spacer"></div>
        <div className="row login_form">
          <div className="col-lg-12 col-md-12">
            <h3 className="font_call font-weight-light text-center">
              Member Information
            </h3>

            <form className="registration_form">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_name" className="mt-0 log_label">
                        Member Name
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="membername"
                        id="mem_name"
                        onChange={Handleinput}
                        value={profileInfo.membername}
                        placeholder="Name"
                        className="form-control profile_ip"
                      />
                      {error.length !== 0 && error.membername ? (
                        <p className={classes.validation}>
                          {error.membername[0]}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_dob" className="mt-0 log_label">
                        DOB
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="date"
                        name="dob"
                        id="mem_dob"
                        onChange={Handleinput}
                        value={profileInfo.dob}
                        max={setMaxDateToday()}
                        placeholder="01-01-2000"
                        className="form-control profile_ip"
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_gender" className="mt-0 log_label">
                        Gender
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <select
                        className="form-control"
                        value={gender || "male"}
                        onChange={genderChange}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                      </select>
                      {/* <input
                        type="text"
                        name="gender"
                        id="mem_gender"
                        onChange={Handleinput}
                        value={profileInfo.gender}
                        placeholder="Male"
                        className="form-control profile_ip"
                      /> */}
                      {error.length !== 0 && error.gender ? (
                        <p className={classes.validation}>{error.gender[0]}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_email" className="mt-0 log_label">
                        Email
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <p>{profileInfo.emailid}</p>
                      {/* <input
                        type="text"
                        name="emailid"
                        id="mem_email"
                        value={profileInfo.emailid}
                        placeholder="example@example.com"
                        className="form-control profile_ip"
                        readOnly
                      /> */}
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_phnum" className="mt-0 log_label">
                        Phone Number
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <p>{profileInfo.phoneno}</p>
                      {/* <input
                        type="text"
                        name="phoneno"
                        value={profileInfo.phoneno}
                        id="mem_phnum"
                        placeholder="0123456789"
                        className="form-control profile_ip"
                        readOnly
                      /> */}
                    </div>
                  </div>

                  {/* <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_gaur" className="mt-0 log_label">
                        Father / Gaurdian
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="mem_gaur"
                        id="mem_gaur"
                        placeholder="Father"
                        className="form-control profile_ip"
                      />
                    </div>
                  </div> */}

                  {/* <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_gaurjob" className="mt-0 log_label">
                        Job
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="mem_gaurjob"
                        id="mem_gaurjob"
                        placeholder="Business Man"
                        className="form-control profile_ip"
                      />
                    </div>
                  </div> */}

                  <div className="row align-items-center mt-3 row_flex">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <p className="log_label mt-0">Display Image</p>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="file"
                        className="previewlabel profileprev input-img"
                        name="mem_img"
                        id="mem_img"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={onImageChange}
                      />

                      <img
                        src={Findimage(profileImage?.image || null)}
                        alt="Profile"
                        className="profilepreview img-fluid"
                      />

                      {/* <img
                        alt="Profile-image"
                        className="img-fluid"
                        src={profileImage?.image}
                        style={{ width: "50px" }}
                      /> */}

                      <label
                        onClick={clickevent}
                        className="mt-0 mx-1 btn rounded-0 previewlabel"
                      >
                        Change Image
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_address" className="mt-0 log_label">
                        Address
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="address"
                        id="mem_address"
                        onChange={Handleinput}
                        value={profileInfo.address}
                        placeholder="Enter Address"
                        className="form-control profile_ip"
                      />
                      {error.length !== 0 && error.address ? (
                        <p className={classes.validation}>{error.address[0]}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_city" className="mt-0 log_label">
                        City
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="city"
                        onChange={Handleinput}
                        value={profileInfo.city}
                        id="mem_city"
                        placeholder="Enter City"
                        className="form-control profile_ip"
                      />
                      {error.length !== 0 && error.city ? (
                        <p className={classes.validation}>{error.city[0]}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_city" className="mt-0 log_label">
                        State
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="district"
                        onChange={Handleinput}
                        value={profileInfo.district}
                        id="mem_district"
                        placeholder=""
                        readOnly
                        className="form-control profile_ip"
                      />
                      {error.length !== 0 && error.district ? (
                        <p className={classes.validation}>
                          {error.district[0]}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_pincode" className="mt-0 log_label">
                        Pincode
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="pincode"
                        value={profileInfo.pincode}
                        id="mem_pincode"
                        onChange={Handleinput}
                        placeholder="Enter Pincode"
                        className="form-control profile_ip"
                      />
                      {error.length !== 0 && error.pincode ? (
                        <p className={classes.validation}>{error.pincode[0]}</p>
                      ) : null}

                      {pincodeerror && (
                        <small className="text-danger">Invalid Pin</small>
                      )}
                    </div>
                  </div>

                  {/* <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_whnum" className="mt-0 log_label">
                        Whatsapp Number
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="mem_whnum"
                        id="mem_whnum"
                        placeholder="0123456789"
                        className="form-control profile_ip"
                      />
                    </div>
                  </div> */}

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_prof" className="mt-0 log_label">
                        Profession
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="profession"
                        id="mem_prof"
                        value={profileInfo.profession}
                        onChange={Handleinput}
                        placeholder="Enter Profession"
                        className="form-control profile_ip"
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_cmts" className="mt-0 log_label">
                        Comments
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="comments"
                        onChange={Handleinput}
                        value={profileInfo.comments}
                        id="mem_cmts"
                        placeholder="Content"
                        className="form-control profile_ip"
                      />
                    </div>
                  </div>

                  {/* <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_time" className="mt-0 log_label">
                        Time Stamp
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <input
                        type="text"
                        name="mem_time"
                        id="mem_time"
                        placeholder="Nil"
                        className="form-control profile_ip"
                      />
                    </div>
                  </div> */}

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_status" className="mt-0 log_label">
                        Status
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      {profileInfo?.status || user.status === 1 ? (
                        <>
                          <span className="text-success">Active</span>
                        </>
                      ) : (
                        <>
                          <span className="text-danger">In Active</span>
                        </>
                      )}
                    </div>
                  </div>
                  {!demo_user && (
                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="mem_status" className="mt-0 log_label">
                          Notifications
                        </label>
                      </div>
                      {getuser ? (
                        <div className="col-lg-8 col-md-8 col-sm-8">
                          <NotificationTab />
                        </div>
                      ) : (
                        "...."
                      )}
                    </div>
                  )}

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label
                        htmlFor="mem_status"
                        className="mt-0 log_label text-danger"
                      >
                        Account Deletion
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <button
                        type="button"
                        className="btn btn-danger text-white  py-2  mt-2"
                        onClick={() => dispatch(setIsModalVisible(true))}
                      >
                        Delete Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row login_form">
          <div className="col-lg-12 col-md-12 text-center mt-4">
            {!loading ? (
              <>
                <button
                  type="button"
                  name="otp"
                  className=" otp_btn commonbtn"
                  onClick={ProfileSubmit}
                >
                  Submit
                </button>
              </>
            ) : (
              <CircularProgress className={classes.progressbar} />
            )}
          </div>
        </div>
        <p className="profile-success text-center text-success mt-2 remove">
          Profile SuccessFully Updated.
        </p>
        <div className="banner_spacer"></div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <h3 className="font_call font-weight-light text-center">
              Family Members
            </h3>
            <div className="banner_spacer"></div>

            {status === STATUS.LOADING ? (
              <FamilMemberLoader />
            ) : (
              <>
                <div className="row">
                  {/* family members */}
                  {familymembers &&
                    familymembers.length &&
                    familymembers.map((member, i) => (
                      <>
                        <FamilyMember member={member} key={i} />
                        <SingleFamilyMember
                          id={`crudfamilymembers${member.id}`}
                          member={member}
                        />
                      </>
                    ))}
                  {!demo_user && (
                    <>
                      {familymembers && familymembers.length != 4 && (
                        <div className="col-lg-3 col-md-6 col-sm-6 fmem_box mb-lg-0 mb-5">
                          <div className="addmem_box text-center">
                            <button
                              type="button"
                              className="btn"
                              data-bs-toggle="modal"
                              data-bs-target="#addfamilymembers"
                            >
                              <div
                                type="button"
                                className="rounded-circle p-0 add_profile mb-3"
                              >
                                <i className="pluscss"></i>
                              </div>
                              <h5 className="font_call">ADD</h5>
                              <h6>New Member</h6>
                            </button>
                          </div>

                          <SingleFamilyMember
                            id="addfamilymembers"
                            member_id={user?.id}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="banner_spacer"></div>
      </div>
    </>
  );
};

export default Profile;
