import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "@popperjs/core";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";

import "../src/common/js/sticky.js";

import "../src/assets/css/mystyle.css";
import "../src/assets/css/extracss.css";
import "../src/assets/slick/slick.css";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import store from "../src/app/store";
import axios from "axios";
import { BASE_URL } from "./services/apiUrl";
import ScrollToTop from "./services/ScrollToTop";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

//axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1/";
axios.defaults.baseURL = BASE_URL;
console.log(process.env.REACT_APP_BASE_URL);
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth-token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <Provider store={store}>
      <App />
    </Provider>

    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  </BrowserRouter>,

  document.getElementById("root")
);
serviceWorkerRegistration.register();
