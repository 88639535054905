import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "../login/Login.module.css";
import img from "../../assets/images/person.png";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogout } from "../../features/user/userSlice";
import profileimage from "../../assets/images/person.png";
import girl from "../../assets/images/girl.png";
import kid from "../../assets/images/kid.png";
import other from "../../assets/images/other.png";
import { IMAGE_URL } from "../../services/apiUrl";
import { shortString } from "../../services/Helper";

const AfterLogin = ({ type }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setloading] = useState(false);

  const { user, isLoggedin } = useSelector((state) => state.user);

  const Findimage = (string = img) => {
    let image;
    if (string === null) {
      if (user?.gender === "male") {
        image = profileimage;
      } else if (user?.gender === "female") {
        image = girl;
      } else {
        image = other;
      }
    } else if (string.includes("base64")) {
      image = string;
    } else if (string.includes("static")) {
      image = string;
    } else if (string.includes("https")) {
      image = string;
    } else {
      image = `${IMAGE_URL}${string}`;
    }

    return image;
  };

  const LogoutHandler = () => {
    dispatch(fetchLogout());
    history.push("/");
  };
  return (
    <>
      <div className="dropdown profile_dropdown">
        <button
          className="btn dropdown-toggle afterlogin"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            src={Findimage(user?.displayimg)}
            width="40"
            height="50"
            className="img-fluid rounded-circle mr-2 head_profile"
            alt="Profile Image"
          />
          {/* {type && type != "footer" && (
            <span>{shortString(user?.membername || "", 5)}</span>
          )} */}
        </button>
        <div
          className="dropdown-menu px-2"
          aria-labelledby="dropdownMenuButton"
        >
          <Link className="dropdown-item py-2" to="/profile">
            Account Settings
          </Link>
          {/* <Link className="dropdown-item py-2" to="/attendance">
            Attendance
          </Link> */}
          <Link className="dropdown-item py-2" to="/checkout">
            Cart
          </Link>
          <Link className="dropdown-item py-2" to="/packages">
            Order History
          </Link>
          {/* <Link className="dropdown-item py-2" to="/checkout">
            Checkout
          </Link> */}
          <a
            className="dropdown-item py-2"
            href="#"
            onClick={() => LogoutHandler()}
          >
            Logout
          </a>
        </div>
      </div>
    </>
  );
};

export default AfterLogin;
