import React from "react";
import twitter from "../../assets/images/twitter.png";
import insta from "../../assets/images/insta.png";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  InstapaperShareButton,
  InstagramShareButton,
  InstapaperIcon,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  ShareButtonInstagram,
} from "react-share";

const Share = ({ fb, twiter, linkin, web }) => {
  const shareUrl = window?.location.href ?? "";

  const shareOnInstagram = () => {
    const url = shareUrl; // Replace with the URL you want to share

    // Construct the Instagram share URL
    const instagramURL = `https://www.instagram.com/?url=${encodeURIComponent(
      url
    )}`;

    // Open a new window with the Instagram share URL
    window.open(instagramURL, "_blank");
  };

  return (
    <>
      <div className="share_div">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h2 className="siderbar_head mt-5">Share</h2>

            <div className="share-icons">
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} square="true" />
              </FacebookShareButton>

              {/* <button onClick={shareOnInstagram}>i</button> */}

              {/* <InstapaperShareButton url={shareUrl}>
                <InstapaperIcon size={32} square="true" />
              </InstapaperShareButton> */}

              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={32} square="true" />
              </WhatsappShareButton>

              <TwitterShareButton url={shareUrl} hashtag="#sportzlete">
                <img src={twitter} width={30} />
                {/* <TwitterIcon size={32} square="true" /> */}
              </TwitterShareButton>
              <a href={linkin} className="insta_share" target="_blank">
                <i className="fa fa-instagram"></i>
              </a>
              {/* <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={32} square="true" />
              </LinkedinShareButton> */}
            </div>

            {/* <a
              href={`https://www.facebook.com/sharer/sharer.php?u=text`}
              className="social_share"
              target="_blank"
            >
              <i className="fa fa-facebook"></i>
            </a> */}
            {/* <a href={fb} className="social_share" target="_blank">
              <i className="fa fa-twitter"></i>
            </a> */}
            {/* <a href={twiter} className="social_share" target="_blank">
              <i className="fa fa-linkedin"></i>
            </a> */}
            {/* <a href={linkin} className="social_share" target="_blank">
              <i className="fa fa-whatsapp"></i>
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Share;
