import React from "react";
import ModalVideo from "react-modal-video";
import "./Category.scss";
import vedio2 from "../../assets/videos/FMA_Promo_v4_wz.mp4";
const Model = (props) => {
  return (
    <ModalVideo
      channel="youtube"
      autoplay
      isOpen={props.isOpen}
      videoId={props.vedio}
      onClose={() => props.setOpen(false)}
    />
  );
};

export default Model;
