import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../services/Status";
import { BASE_URL, IMAGE_URL } from "../../services/apiUrl";
import axios from "axios";

const initialState = {
  sportList: [],
  sportCount: 0,
  status: STATUS.LOADING,
  errormessage: "",
  pagination: {
    currentPage: 1,
    totalcount: 0,
    perPage: 0,
    paginateStatus: null,
  },
  page: 1,
  hasMore: 0,
  sportCategory: [],
  sportImages: [],
  sportvedios: [],
  singleItem: [],
  Lightboximages: [],
  Lightboxview: false,
  photoIndex: 0,
  selectedurlage: [],
  selectedurlprice: [],
  cancelreason: [],
};

const sportSlice = createSlice({
  name: "sport",
  initialState,
  reducers: {
    setselectedUrlage(state, action) {
      state.selectedurlage = action.payload;
    },
    resetSelectedurlage(state, action) {
      state.selectedurlage = [];
    },
    setselectedUrlprice(state, action) {
      state.selectedurlprice = action.payload;
    },
    resetSelectedurlprice(state, action) {
      state.selectedurlprice = [];
    },
    setSports(state, action) {
      state.sportList = action.payload;
      // state.sportList = [...new Set(data.map((item) => item))];
      // state.sportList = action.payload;
    },
    setSportsInfinite(state, action) {
      state.sportList = [...new Set([...state.sportList, ...action.payload])];
      // state.sportList = [...new Set(data.map((item) => item))];
      // state.sportList = action.payload;
    },

    emptySportList(state) {
      state.sportList = [];
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setPaginateStatus(state, action) {
      state.pagination.paginateStatus = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
    setCategory(state, action) {
      state.sportCategory = action.payload;
    },
    setPagination(state, action) {
      state.pagination.currentPage = action.payload.current_page;
      state.pagination.perPage = action.payload.per_page;
      state.pagination.totalcount = action.payload.total;
    },
    updatePagination(state, action) {
      //state.pagination.currentPage = action.payload;
      state.page = action.payload;
    },
    setcancelreason(state, action) {
      const checked = action.payload[0];

      const value = action.payload[1];

      checked
        ? (state.cancelreason = [...state.cancelreason, +value])
        : (state.cancelreason = state.cancelreason.filter(
            (item) => item != value
          ));
    },

    resetCancelReason(state, action) {
      state.cancelreason = [];
    },

    ResetState(state, action) {
      state.sportList = [];
      state.page = 1;
      state.hasMore = 0;
      state.pagination.currentPage = 1;
      state.pagination.perPage = 0;
      state.pagination.totalcount = 0;
    },
    setSingleitem(state, action) {
      state.singleItem = action.payload;

      state.sportImages = action.payload.attachments.filter(
        (item) => item.attachtype === 1
      );

      state.sportvedios = action.payload.attachments.filter(
        (item) => item.attachtype === 0
      );
      state.Lightboximages = action.payload.attachments
        .filter((item) => item.attachtype === 1)
        .map((attach) => `${IMAGE_URL}${attach.attachmenturl}`);
    },

    setlightboximage(state, action) {
      state.photoIndex = action.payload;
    },
    setLightboxview(state, action) {
      state.Lightboxview = action.payload;
    },
  },
});

export const {
  setStatus,
  setSports,
  setPage,
  setHasMore,
  emptySportList,
  setCategory,
  ResetState,
  setPagination,
  updatePagination,
  setPaginateStatus,
  setSingleitem,
  setSportsInfinite,
  setlightboximage,
  setLightboxview,
  setselectedUrlage,
  resetSelectedurlage,
  setselectedUrlprice,
  resetSelectedurlprice,
  setcancelreason,
  resetCancelReason,
} = sportSlice.actions;

export default sportSlice.reducer;

export const fetchSports = (
  option,
  location,
  lat,
  lng,
  page,
  type,
  url,
  method,
  propstype
) => {
  return async function fetchSportsThunk(dispatch) {
    if (location) {
      type === "custom" && dispatch(setStatus(STATUS.LOADING));
      type === "pagination" && dispatch(setPaginateStatus(STATUS.LOADING));

      let isacademy;
      if (propstype === "sports") {
        isacademy = 1;
      } else if (propstype === "coach") {
        isacademy = 0;
      } else {
        isacademy = 1;
      }
      try {
        await axios
          .get(
            `${BASE_URL}filter/${option}/${location}/${isacademy}?page=${page}${url}&lat=${lat}&lng=${lng}`
          )
          .then((res) => {
            // console.log(res.data.academy.total);
            //dispatch(ResetState());
            if (method === "custom") {
              dispatch(setSports(res.data.academy.data));
            } else if (method === "infinite") {
              dispatch(setSportsInfinite(res.data.academy.data));
            }

            // dispatch(setPagination(res.data.academy));
            dispatch(setHasMore(res.data.academy.total));
            //console.log(res, "from");
          })
          .catch((e) => console.log(e));
        type === "custom" && dispatch(setStatus(STATUS.IDLE));
        type == "pagination" && dispatch(setPaginateStatus(STATUS.IDLE));
      } catch (error) {
        type === "custom" && dispatch(setStatus(STATUS.ERROR));
        type === "pagination" && dispatch(setPaginateStatus(STATUS.ERROR));
      }
    }
  };
};
export const fetchSportsCategory = (location, type) => {
  return async function fetchSportsCategoryThunk(dispatch) {
    dispatch(setStatus(STATUS.LOADING));

    let isacademy = type === "sports" ? 1 : 0;

    try {
      await axios
        .get(`${BASE_URL}GetCategoryList/${location}/${isacademy}`)
        .then((res) => {
          // console.log(res.data.academy.total);
          dispatch(setCategory(res.data.data));
        })
        .catch((e) => console.log(e));

      dispatch(setStatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};

export const fetchSportsSingle = (id) => {
  return async function fetchSportsSingleThunk(dispatch) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      await axios
        .get(`${BASE_URL}GetSingleAcademy/${id}/academy`)
        .then((res) => {
          dispatch(setSingleitem(res.data.data));
        })
        .catch((e) => console.log(e));

      dispatch(setStatus(STATUS.IDLE));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};
