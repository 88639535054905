import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL, IMAGE_URL } from "../../services/apiUrl";

const SportsCard = ({ img, title, id, setCategory }) => {
  const { city } = useSelector((state) => state.user);
  const link = `/sports?location=${city}&category=${title}`;

  return (
    <>
      <div className="sportz_slibox" key={id}>
        <div onClick={() => setCategory([title])} style={{ cursor: "pointer" }}>
          <div
            className="cate_img"
            style={{ backgroundImage: `url(${IMAGE_URL}${img})` }}
          ></div>
          <div className="text-center p-4">
            <h5
              className="font-weight-bold"
              onClick={() => setCategory([title])}
            >
              {title}
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default SportsCard;
