import React from "react";
import { useSelector } from "react-redux";
import AcademySlider from "./AcademySlider";
import CoachSlider from "./CoachSlider";
import Gymsection from "./gym/Gymsection";
import { Link } from "react-router-dom";

const Academics = () => {
  const { academyString, gymString } = useSelector((state) => state.home);
  return (
    <>
      {/* <!-- section begin --> */}
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div>
                <h1 className="sec_head font-weight-bold">
                  {academyString ? academyString : "Academies"}
                </h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="text-end">
                <Link to="/sports" className="btn view_all_button">
                  View all
                </Link>
              </div>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {/* //slider here */}
              <AcademySlider />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- section end --> */}

      {/* section start for gym */}
      <section className="gym_full">
        <Gymsection gymString={gymString} />
      </section>
      {/* sectio end for gym*/}

      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div>
                <h1 className="sec_head font-weight-bold">Experts</h1>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="text-end">
                <Link to="/coach" className="btn view_all_button">
                  View all
                </Link>
              </div>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {/* //slider here */}
              <CoachSlider />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Academics;
