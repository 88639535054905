import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SwitchTab from "./switch/SwitchTab";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import { setSnackabar } from "../../features/home/homeSlice";
import { setUserOffers } from "../../features/user/userSlice";
import axios from "axios";
import SnackbarNotification from "../../common/loader/SnackbarNotification";
const NotificationTab = () => {
  const [value, setValue] = useState("1");

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const SwitchChange = (event) => {
    let offer = event.target.name;

    let type = event.target.id;
    let action = event.target.checked ? 1 : 0;

    axios
      .post(`offernotification/${type}/${offer}/${action}`)
      .then((res) => {
        dispatch(setSnackabar(true));
        dispatch(setUserOffers([offer, type, action]));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setSnackabar(false));
      });
  };

  const { user } = useSelector((state) => state.user);
  return (
    <>
      <SnackbarNotification />

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              indicatorColor="red"
            >
              <Tab label="Promotional" value="1" />
              <Tab label="Offer" value="2" />
              <Tab label="Order" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="switch-items">
              <div className="switch-item">
                <label>Email</label>

                <Switch
                  checked={user?.proemail == "1" ? true : false}
                  name="email"
                  id="promotional"
                  onChange={SwitchChange}
                />
              </div>
              <div className="switch-item">
                <label>Whatsapp</label>
                <Switch
                  checked={user?.prowhatsapp == "1" ? true : false}
                  name="whatsapp"
                  id="promotional"
                  onChange={SwitchChange}
                />
              </div>
            </div>
            {/* <SwitchTab
            checkedinfoEmail={user?.proemail == 1 ? true : false}
            checkedinfoWhatsapp={user?.prowhatsapp == 1 ? true : false}
            type="promotional"
          /> */}
          </TabPanel>
          <TabPanel value="2">
            <SwitchTab
              checkedinfoEmail={user && user?.offeremail == "1" ? true : false}
              checkedinfoWhatsapp={
                user && user?.offerwhatsapp == "1" ? true : false
              }
              type="offer"
            />
          </TabPanel>
          <TabPanel value="3">
            <SwitchTab
              checkedinfoEmail={user && user?.orderemail == "1" ? true : false}
              checkedinfoWhatsapp={
                user && user?.orderwhatsapp == "1" ? true : false
              }
              type="order"
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default NotificationTab;
