import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import classes from "./Login.module.css";
import {
  fetchGetuser,
  setClearRegisterUser,
  setEmailPhoneActivate,
  setLoggedin,
  setRegisterUser,
  setTotalLoader,
} from "../../features/user/userSlice";
import { useHistory } from "react-router-dom";
import { setIsTermsModalVisible } from "../../features/modal/modalSlice";
import ModalBox from "../../common/modal/ModalBox";
import OtpForm from "./OtpForm";

const ProfileCimplete = () => {
  const { registeruser, SocialLoginprofile, emailActivated, phoneActivated } =
    useSelector((state) => state.user);
  const { termsmodal } = useSelector((state) => state.modal);
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [activateload, setactivateload] = useState(false);
  const [activateerror, setactivateerror] = useState(null);
  const [error, seterror] = useState([]);
  const dispatch = useDispatch();
  const [profile, setprofile] = useState({
    dob: "",
    phoneno:
      registeruser.opt === "phone" || registeruser.opt === "social"
        ? registeruser.phonenumber
        : "",
    emailid:
      registeruser.opt === "email" || registeruser.opt === "social"
        ? registeruser.email
        : "",
    membername: registeruser.membername || "",
    displayimg: registeruser.displayimg || "",
    authtype: registeruser.authtype || "",
    apple_uid: registeruser.apple_uid || "",
    gender: registeruser?.exist_info?.gender || "male",
    dob: registeruser?.exist_info?.dob || "",
  });

  const genderChange = (e) => {
    setprofile({ ...profile, gender: e.target.value });
  };
  const Handleinput = (e) => {
    e.preventDefault();
    setprofile({ ...profile, [e.target.name]: e.target.value });
  };
  const setMaxDateToday = () => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  const OpenActivateModel = () => {
    setactivateload(true);
    setactivateerror("");
    let opt;

    let data = {
      email: emailActivated ? profile.phoneno : profile.emailid,
      opt: emailActivated ? "phone" : "email",
      auth_apple: profile?.authtype == "apple" ? true : false,
      auth_type: profile?.authtype,
    };
    axios
      .post("resendotp/verify", data)
      .then((res) => {
        dispatch(setIsTermsModalVisible(true));
        dispatch(setRegisterUser(res.data));
        setprofile({
          ...profile,
          dob: res.data?.exist_info?.dob || "",
          gender: res.data?.exist_info?.gender || "male",
        });
        seterror([]);
        setactivateload(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
          if (error.response.data.errors) seterror(error.response.data.errors);
          setactivateload(false);
        }
      });
  };

  const Completeprofile = async (e) => {
    e.preventDefault();
    setloading(true);
    let url = SocialLoginprofile ? "socialloginRegister" : "registerComplete";

    if (!emailActivated || !phoneActivated) {
      if (!emailActivated) setactivateerror("Please verify Your Email Address");
      if (!phoneActivated) setactivateerror("Please verify Your Phone Number");
      setloading(false);
      return;
    }
    setactivateerror("");

    await axios
      .post(url, profile)
      .then((res) => {
        setloading(false);
        localStorage.setItem("auth-token", res.data.token);
        dispatch(setTotalLoader(true));
        dispatch(setClearRegisterUser());
        dispatch(setLoggedin(true));
        dispatch(fetchGetuser());

        dispatch(setEmailPhoneActivate({ type: "email", value: false }));
        dispatch(setEmailPhoneActivate({ type: "phone", value: false }));
        // console.log(res);
        history.push("/profile");
      })
      .catch((errors) => {
        setloading(false);
        if (errors.response.data.errors) seterror(errors.response.data.errors);
        else console.log(errors.response.data);
      });
  };

  return (
    <>
      {termsmodal && (
        <ModalBox>
          <>
            <OtpForm
              type="activate"
              setotpVerified={null}
              setmailerror={null}
              activate={emailActivated ? profile.phoneno : profile.emailid}
            />
          </>
        </ModalBox>
      )}
      <div className="profile__complete mt-4">
        <h5 className="text-success">OTP Successfully Verified</h5>
        {/* <h5 className="text-success">
          Hi !{" "}
          <span className="text-dark">
            {registeruser.email || registeruser.phonenumber}
          </span>{" "}
          {SocialLoginprofile ? "" : "Your OTP Successfully Verified"}
        </h5> */}
        <br />
        <p className="text-danger">
          To Complete Your Registration , Kindly Fill out All Required
          Information
        </p>
      </div>

      <div className="container mt-4">
        <div className="row login_form">
          <div className="col-lg-12 col-md-12">
            <div className="registration_form">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_name" className="mt-0 log_label">
                        Member Name <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 form_input_profile">
                      <input
                        type="text"
                        name="membername"
                        id="mem_name"
                        placeholder="Name"
                        value={profile.membername}
                        className="form-control profile_ip"
                        onChange={Handleinput}
                      />
                      {error.length !== 0 && error.membername ? (
                        <p className={classes.validation}>
                          {error.membername[0]}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_name" className="mt-0 log_label">
                        Member Email <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 form_input_profile">
                      <input
                        type="text"
                        name="emailid"
                        id="mem_name"
                        placeholder="Email"
                        value={profile.emailid}
                        disabled={emailActivated ? true : false}
                        className="form-control profile_ip"
                        onChange={Handleinput}
                      />
                      {error.length !== 0 && error.emailid ? (
                        <p className={classes.validation}>{error.emailid[0]}</p>
                      ) : null}
                      {error.length !== 0 && error.email ? (
                        <p className={classes.validation}>{error.email[0]}</p>
                      ) : null}
                      {profile.emailid.includes("@") &&
                        !emailActivated &&
                        (activateload ? (
                          <div className="mt-2">
                            <CircularProgress
                              className={classes.progressactivate}
                            />
                          </div>
                        ) : (
                          <span
                            className="mt-2 d-block text-danger back-btn"
                            onClick={OpenActivateModel}
                          >
                            Verify Email
                          </span>
                        ))}
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_name" className="mt-0 log_label">
                        Member Phone <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 form_input_profile">
                      <input
                        type="text"
                        name="phoneno"
                        id="mem_name"
                        placeholder="Phone"
                        value={profile.phoneno}
                        className="form-control profile_ip"
                        disabled={phoneActivated ? true : false}
                        onChange={Handleinput}
                      />
                      {error.length !== 0 && error.phoneno ? (
                        <p className={classes.validation}>{error.phoneno[0]}</p>
                      ) : null}

                      {profile.phoneno &&
                        profile.phoneno.length >= 10 &&
                        !phoneActivated &&
                        (activateload ? (
                          <div className="mt-2">
                            <CircularProgress
                              className={classes.progressactivate}
                            />
                          </div>
                        ) : (
                          <span
                            className="mt-2 d-block text-danger back-btn"
                            onClick={OpenActivateModel}
                          >
                            Verify Phone
                          </span>
                        ))}
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_name" className="mt-0 log_label">
                        Member DOB <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 form_input_profile">
                      <input
                        type="date"
                        name="dob"
                        id="mem_name"
                        placeholder="Name"
                        value={profile.dob || ""}
                        className="form-control profile_ip"
                        onChange={Handleinput}
                        max={setMaxDateToday()}
                      />
                      {error.length !== 0 && error.dob ? (
                        <p className={classes.validation}>{error.dob[0]}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row align-items-center mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="mem_name" className="mt-0 log_label">
                        Member Gender <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 form_input_profile">
                      <select
                        className="form-control"
                        value={profile.gender || "male"}
                        onChange={genderChange}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                      </select>
                      {error.length !== 0 && error.gender ? (
                        <p className={classes.validation}>{error.gender[0]}</p>
                      ) : null}
                    </div>
                  </div>
                  {activateerror && (
                    <span className="text-danger mt-2 d-block">
                      {activateerror}
                    </span>
                  )}

                  {registeruser && registeruser?.is_exists && (
                    <div class="alert alert-primary mt-3" role="alert">
                      <p className="apple_info">
                        {`We see that you are already registered with ${registeruser?.exist_info?.emailid}. We
                      also realize you are now trying to login through Apple ID
                      using "hide my email" option. Since we do not have this
                      masked email ID in our database you are seeing this
                      screen. Please click on complete registration to proceed.
                      Please do not worry, we won't be asking you to re-enter
                      any pre filled information. Next time you login through
                      apple id, it will be a seamless process`}
                      </p>
                    </div>
                  )}
                  <button
                    className="mt-5 sportsbutton"
                    onClick={Completeprofile}
                  >
                    {loading ? (
                      <CircularProgress className={classes.progressbar_login} />
                    ) : (
                      " Complete Registration"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCimplete;
