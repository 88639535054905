import React from "react";
import ContentLoader from "react-content-loader";

const ParagraphLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={150}
      viewBox="0 0 400 150"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="102" rx="3" ry="3" width="220" height="6" />

      <rect x="0" y="56" rx="3" ry="3" width="500" height="40" />
    </ContentLoader>
  );
};

export default ParagraphLoader;
