import React from "react";
import { Banner } from "./Banner";

import { Navigation } from "./Navigation";

export const MainNavigation = () => {
  return (
    <div>
      <Navigation />
      <Banner />
    </div>
  );
};
