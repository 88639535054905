import React from "react";
import TopBanner from "../common/banner/TopBanner";
import useFetch from "../hooks/useFetch";
import { BASE_URL } from "../services/apiUrl";
import Skeleton from "@mui/material/Skeleton";
export const Termsandcondition = () => {
  const {
    data: { termspages },
    loading,
    error,
  } = useFetch(`${BASE_URL}termspage`);

  function createMarkup(text) {
    return { __html: text };
  }
  return (
    <>
      <TopBanner
        // title="Terms and Conditions"
        loading={loading}
        image={termspages?.termsbanner}
      />

      <div className="container">
        <div className="banner_spacer"></div>

        <div className="row row_display">
          <h3 className="font_call text-center">
            {termspages && termspages?.termssectionheading}
          </h3>
        </div>
        <div className="row">
          <div className="col-md-12">
            {/* <!-- contents here --> */}
            <p className="para_font">
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      termspages?.termssection1
                    )}
                  />
                </>
              )}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            {/* <!-- contents here --> */}
            <p className="para_font">
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      termspages?.termssection2
                    )}
                  />
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
