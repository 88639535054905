export const toIndianCurrency = (num) => {
  const curr = num.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
  return curr;
};

export const shortString = (name, count) => {
  var string = name;
  var count = count;
  var result = string.slice(0, count) + (string.length > count ? "..." : "");
  return result;
};

export const getTimefromTimestamp = (timestamp) => {
  const createdAt = timestamp;
  const dateObj = new Date(createdAt);
  const timeString = dateObj.toLocaleTimeString();

  return timeString;
};

export const slugify = (str) => {
  var slugstring = str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

  return slugstring;
};
export const DateFormat = (date) => {
  const today = date;
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return yyyy + "-" + mm + "-" + dd;
};

export const CurrentDateFormat = () => {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `${year}-${month}-${day}`;
  return currentDate;
};

export const Removspace = (string) => {
  if (string) {
    return string.toLowerCase().replace(/\s/g, "");
  }
  return null;
};

export function createMarkup(text) {
  return { __html: text };
}

export const CANCELORDER = {
  1: "Changed my mind",
  2: "Found a better deal somewhere else",
  3: "Bought this package by mistake",
  4: "Unsatisfactory product information on website",
  5: "Noticed too many negative reviews",
  6: "Don't want to specify any reason",
};
export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
