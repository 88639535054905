import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";

const MultiRangeSlider = ({
  min,
  max,
  onChange,
  selectedmin,
  selectedmax,
  setclearState,
}) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const [Selectedmin, setSelectedmin] = useState(selectedmin);
  const [Selectedmax, setSelectedmax] = useState(selectedmax);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    setSelectedmin(selectedmin);
    setSelectedmax(selectedmax);
  }, [selectedmin, selectedmax]);

  useEffect(() => {
    setMinVal(min);
    setMaxVal(max);
  }, [min, max]);

  useEffect(() => {
    setMinVal(min);
    setMaxVal(max);
  }, [setclearState]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    // if (range.current) {
    //   range.current.style.left = `${minPercent}%`;
    //   range.current.style.width = `${maxPercent - minPercent}%`;
    // }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    // if (range.current) {
    //   range.current.style.width = `${maxPercent - minPercent}%`;
    // }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal]);

  return (
    <div className="container" style={{ marginBottom: "23px" }}>
      <input
        type="range"
        min={min}
        max={max}
        value={Selectedmin || minVal}
        onChange={(event) => {
          setSelectedmax(null);
          setSelectedmin(null);
          const value = Math.min(Number(event.target.value), maxVal - 1);

          setMinVal(value);
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={Selectedmax || maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);

          setMaxVal(Number(event.target.value));
          maxValRef.current = Number(event.target.value);
          setSelectedmax(null);
          setSelectedmin(null);
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{Selectedmin || minVal}</div>
        <div className="slider__right-value">{Selectedmax || maxVal}</div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
