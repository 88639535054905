import React from "react";
import { useDispatch } from "react-redux";
import {
  setlightboximage,
  setLightboxview,
} from "../../features/sports/sportSlice";

import { IMAGE_URL } from "../../services/apiUrl";

const Card = ({ img, key, photoindex }) => {
  const dispatch = useDispatch();
  const OpenLightbox = (key) => {
    dispatch(setlightboximage(key));
    dispatch(setLightboxview(true));
  };
  return (
    <>
      <div
        className="banner_sec sportsbanner"
        key={photoindex}
        style={{ backgroundImage: `url("${img}")` }}
        onClick={() => OpenLightbox(photoindex)}
      >
        {" "}
        {/* <img src={img} alt="some" style={{ width: "100%", height: "100%" }} /> */}
      </div>
    </>
  );
};

export default Card;
