import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "./Login.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGetuser,
  setClearRegisterUser,
  setEmailPhoneActivate,
  setOtpget,
  setOtpVerified,
  setRegisterUser,
} from "../../features/user/userSlice";
import { useHistory, useLocation } from "react-router-dom";
import { setIsTermsModalVisible } from "../../features/modal/modalSlice";
const OtpForm = ({ type, setotpVerified, setmailerror, activate }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setotp] = useState(null);
  const [formValid, setformValid] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [error, seterror] = useState([]);
  const [resentotpLoading, setresentotpLoading] = useState(false);
  const [resentotpSend, setresentotpSend] = useState(false);
  const path = useLocation();
  const { registeruser } = useSelector((state) => state.user);

  const Handleinput = (e) => {
    e.preventDefault();

    e.target.value.length < 5 && setformValid(false);
    e.target.value.length >= 5 && setformValid(true);
    setotp(e.target.value);
  };

  const LoginorRegister = (e) => {
    let url = type === "login" ? "logincheck" : "registerconfirm";
    let data;
    if (type == "activate") {
      data = {
        email: activate,
        opt: registeruser.opt,
        otp: otp,
        uuid: registeruser.uuid,
      };
    } else {
      data = {
        email: registeruser.email || registeruser.phonenumber,
        opt: registeruser.opt,
        otp: otp,
        uuid: registeruser.uuid,
      };
    }

    setLoading(true);
    e.preventDefault();
    axios
      .post(url, data)
      .then((res) => {
        setLoading(false);
        if (type === "login") {
          localStorage.setItem("auth-token", res.data.token);
          dispatch(setClearRegisterUser());
          dispatch(fetchGetuser());
          if (path?.state?.type === "academy") {
            history.push(
              `/academy/${path?.state?.academy_id}/${path?.state?.slug}`
            );
          } else {
            history.push("/");
          }
        } else {
          if (type == "activate") {
            dispatch(setIsTermsModalVisible(false));
            dispatch(
              setEmailPhoneActivate({ type: registeruser.opt, value: true })
            );
            dispatch(setClearRegisterUser());
          } else {
            dispatch(setOtpVerified(true));

            dispatch(
              setEmailPhoneActivate({ type: registeruser.opt, value: true })
            );
          }

          // setotpVerified(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // Request made and server responded

          seterror(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
      });
    // setotpVerified(true);
    // type==="register"
  };

  const reSendotp = (e) => {
    console.log(type);
    e.preventDefault();
    setresentotpLoading(true);
    let data, url;
    if (type == "activate") {
      url = "resendotp/verify";
      data = {
        email: activate,
        opt: registeruser.opt,
      };
    } else {
      url = "resendotp/none";
      data = {
        email: registeruser.email || registeruser.phonenumber,
        opt: registeruser.opt,
      };
    }

    axios
      .post(url, data)
      .then((res) => {
        dispatch(setRegisterUser(res.data));
        setresentotpLoading(false);
        setresentotpSend(true);
        seterror([]);
      })
      .catch((error) => {
        if (error.response) {
          setresentotpLoading(false);
          setresentotpSend(false);

          // Request made and server responded
          console.log(error);
          seterror(error.response.data);
        }
      });
  };
  const doback = (e) => {
    e.preventDefault();
    dispatch(setOtpget(false));
    dispatch(setClearRegisterUser());
  };
  return (
    <>
      <form onSubmit={LoginorRegister}>
        <div className="row">
          <div className="col-lg-12 col-md-12 mt-4 form_input">
            <input
              type="number"
              name="logusr_tel"
              id="logusr_tel"
              className="form-control input1"
              placeholder="Enter OTP"
              onChange={Handleinput}
            />
            {error.length !== 0 && error.message ? (
              <p className={classes.validation}>{error.message}</p>
            ) : null}

            <p className="mt-3">
              OTP Sent to{" "}
              {activate != null
                ? activate
                : registeruser?.phonenumber || registeruser?.email}
            </p>

            <div className={classes.resendotp}>
              <small onClick={reSendotp} className={classes.otploading}>
                {resentotpLoading ? "Sending.." : "Resent OTP"}
              </small>
              <small className="text-success">
                {resentotpSend &&
                  `OTP Sent To ${
                    registeruser.email || registeruser.phonenumber
                  }`}
              </small>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 text-center mt-4 buttons-login">
            {type != "activate" && (
              <div className="back-btn" onClick={doback}>
                {" "}
                <i className="fa fa-arrow-left" aria-hidden="true"></i>Back
              </div>
            )}

            <button
              type="submit"
              name="otp"
              className=" sportsbutton"
              disabled={!formValid}
              style={{ backgroundColor: !formValid ? "grey" : "" }}
            >
              {!Loading ? (
                "Submit OTP"
              ) : (
                <CircularProgress className={classes.progressbar_login} />
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default OtpForm;
