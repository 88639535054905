import { MainNavigation } from "./components/navigation/MainNavigation";
import { Switch, Route, Redirect } from "react-router-dom";
import { Home } from "./pages/Home";
import { Termsandcondition } from "./pages/Termsandcondition";
import Footer from "./components/footer/Footer";
import Location from "../src/services/Location";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { useDispatch, useSelector } from "react-redux";
import {
  errorLocation,
  fetchTokenStringEnableloggedin,
  setChangeCity,
  updateLocation,
} from "./features/user/userSlice";
import { fetchGetuser } from "./features/user/userSlice";
import Navigation from "./components/navigation/subpage/Navigation";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Category from "./pages/Category";
import CategorySingle from "./pages/CategorySingle";
import Mobilefooter from "./components/footer/Mobilefooter";

import Blog from "./pages/Blog";
import SingleBlog from "./components/blogs/SingleBlog";
import useGeoLocation from "./hooks/useGeoLocation";
import {
  fetchLocations,
  fetchSiteconfig,
  policydata,
} from "./features/home/homeSlice";
import LoginPage from "./pages/LoginPage";
import Profile from "./pages/Profile";
import NotFound from "./pages/NotFound";
import FullPageLoader from "./common/loader/FullPageLoader";
import Checkout from "./pages/Checkout";
import Packages from "./pages/Packages";
import CookiePolicy from "./pages/CookiePolicy";
import RefundPolicy from "./pages/RefundPolicy";
import DisclaimerPolicy from "./pages/DisclaimerPolicy";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import AcdemyTerms from "./pages/AcdemyTerms";
import ProtectedRoute from "./services/ProtectedRoute";
import AttendanceCalender from "./pages/AttendanceCalender";

function App() {
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  const currentLocation = useLocation();

  const query = new URLSearchParams(useLocation().search);
  const location = query.get("location");
  const { detect, isLoggedin, TotalLoader, globalcity } = useSelector(
    (state) => state.user
  );
  const {
    loading,
    error,
    data: { latitude, longitude },
    city,
    setcity,
  } = useGeoLocation(detect);

  useEffect(() => {
    dispatch(fetchTokenStringEnableloggedin());
    dispatch(fetchLocations());
    dispatch(fetchGetuser());
    dispatch(fetchSiteconfig());
    dispatch(policydata());
  }, [dispatch]);

  useEffect(() => {
    if (location) dispatch(setChangeCity(location));
  });

  useEffect(() => {
    if (globalcity != null) {
      dispatch(
        updateLocation([latitude, longitude, location ? location : globalcity])
      );
      dispatch(setChangeCity(globalcity));
      //setcity(null);
      console.log("changes");
    }

    if (city) {
      dispatch(
        updateLocation([latitude, longitude, location ? location : city])
      );
    }
  }, [globalcity, city]);

  const Loadingprogress = (progress) => {
    setProgress(progress);
  };
  return (
    <>
      {currentLocation.pathname === "/" ? <MainNavigation /> : <Navigation />}
      {TotalLoader && <FullPageLoader />}

      <LoadingBar
        color="#df6d09"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {/* <Model /> */}
      <Switch>
        <Route path="/" exact>
          <Home Loadingprogress={Loadingprogress} />
        </Route>
        <Route path="/login" exact>
          {!isLoggedin ? (
            <LoginPage Loadingprogress={Loadingprogress} />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route
          path="/termsand-condition"
          exact
          Loadingprogress={Loadingprogress}
        >
          <Termsandcondition />
        </Route>

        <Route path="/contact-us" exact>
          <ContactUs Loadingprogress={Loadingprogress} />
        </Route>

        <Route path="/about-us" exact>
          <AboutUs Loadingprogress={Loadingprogress} />
        </Route>

        <Route path="/sports" exact>
          <Category Loadingprogress={Loadingprogress} type="sports" />
        </Route>

        <Route
          path="/administrator"
          component={() => {
            window.location.href = process.env.REACT_APP_ADMINISTRATOR_URL;
            return null;
          }}
        />
        <Route path="/coach" exact>
          <Category Loadingprogress={Loadingprogress} type="coach" />
        </Route>

        <Route path="/gym-fitness" exact>
          <Category Loadingprogress={Loadingprogress} type="Gym & Fitness" />
        </Route>
        <Route path="/gym-fitness/:id/:slug" exact>
          <CategorySingle
            Loadingprogress={Loadingprogress}
            name="Full Metal Alchemist Gym"
            type="Gym & Fitness"
          />
        </Route>

        <Route path="/academy/:id/:slug" exact>
          <CategorySingle
            Loadingprogress={Loadingprogress}
            name="Bangalore Chess Academy"
            type="sports"
          />
        </Route>

        <Route path="/blogs" exact>
          <Blog Loadingprogress={Loadingprogress} />
        </Route>
        <Route path="/checkout" exact>
          <Checkout Loadingprogress={Loadingprogress} />
        </Route>

        <Route path="/packages" exact>
          <Packages Loadingprogress={Loadingprogress} />
        </Route>
        <Route path="/cookiepolicy" exact>
          <CookiePolicy Loadingprogress={Loadingprogress} />
        </Route>
        <Route path="/refundpolicy" exact>
          <RefundPolicy Loadingprogress={Loadingprogress} />
        </Route>
        <Route path="/disclaimerpolicy" exact>
          <DisclaimerPolicy Loadingprogress={Loadingprogress} />
        </Route>
        <Route path="/privacypolicy" exact>
          <PrivacyPolicy Loadingprogress={Loadingprogress} />
        </Route>

        <Route path="/academyterms/:id" exact>
          <AcdemyTerms />
        </Route>

        <Route path="/blog/:id/:slug" exact>
          <ProtectedRoute>
            <SingleBlog Loadingprogress={Loadingprogress} />
          </ProtectedRoute>
        </Route>

        <Route path="/profile" exact>
          {isLoggedin ? (
            <Profile Loadingprogress={Loadingprogress} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route path="/attendance/:order_item" exact>
          {isLoggedin ? (
            <AttendanceCalender Loadingprogress={Loadingprogress} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>

      <Mobilefooter />

      <Footer />
    </>
  );
}

export default App;
