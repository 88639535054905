import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TopBanner from "../common/banner/TopBanner";
import FillterResults from "../components/academics/fillter/FillterResults";
import FillterModel from "../components/category/fillter/FillterModel";
import StoreSlider from "../components/store/StoreSlider";
import { Helmet } from "react-helmet";
import { SITE_NAME } from "../services/apiUrl";

import {
  fetchSports,
  fetchSportsCategory,
  resetSelectedurlage,
  resetSelectedurlprice,
  ResetState,
  updatePagination,
} from "../features/sports/sportSlice";
import { STATUS } from "../services/Status";
import Loader from "../common/loader/Loader";
import { useHistory, useLocation } from "react-router-dom";

const Category = (props) => {
  const Loadingprogress = props.Loadingprogress;
  const dispatch = useDispatch();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const sort = query.get("sort") || "relevance";
  const [clearfilter, setclearfilter] = useState(false);

  const location = query.get("location") || "Bangalore";
  const category = query.get("category")
    ? query.get("category").split(",")
    : [];
  const pricelist = query.get("price") ? query.get("price").split(",") : [];
  const agelist = query.get("age") ? query.get("age").split(",") : [];

  const [sortby, setsortby] = useState(sort);
  const [Category, setCategory] = useState(category);
  const [Pricelist, setPricelist] = useState(pricelist);
  const [Agelist, setAgelist] = useState(agelist);

  let url = `&category=${Category}&age=${Agelist}&price=${Pricelist}&sort=${sortby}`;

  const {
    sportList,
    status,
    page,
    hasMore,
    sportCategory,
    pagination: { currentPage, totalcount, perPage, paginateStatus },
  } = useSelector((state) => state.sport);

  const [pageNumber, setpageNumber] = useState(page);

  const { city, lat, lang } = useSelector((state) => state.user);
  useEffect(() => {
    Loadingprogress(100);
  }, []);

  const handlePageChange = (page) => {
    dispatch(updatePagination(page));

    props.type === "sports"
      ? dispatch(
          fetchSports(
            1,
            city,
            lat,
            lang,
            page,
            "infinite",
            url,
            "infinite",
            "sports"
          )
        )
      : props.type === "coach"
      ? dispatch(
          fetchSports(
            1,
            city,
            lat,
            lang,
            page,
            "infinite",
            url,
            "infinite",
            "coach"
          )
        )
      : dispatch(
          fetchSports(
            0,
            city,
            lat,
            lang,
            page,
            "infinite",
            url,
            "infinite",
            "gym"
          )
        );

    // props.type === "sports"
    //   ? dispatch(
    //       fetchSports(1, city, lat, lang, page, "infinite", url, "infinite")
    //     )
    //   : dispatch(
    //       fetchSports(0, city, lat, lang, page, "infinite".url, "infinite")
    //     );
  };

  const ApplyFilter = (data) => {
    setCategory(data.category);
    setAgelist(data.agelist);
    setPricelist(data.pricelist);

    let urldemo =
      props.type === "sports"
        ? `/sports?location=${city}&category=${data.category}&age=${data.agelist}&price=${data.pricelist}&sort=${sortby}`
        : props.type === "coach"
        ? `/coach?location=${city}&age=${data.agelist}&price=${data.pricelist}&sort=${sortby}`
        : `/gym-fitness?location=${city}&age=${data.agelist}&price=${data.pricelist}&sort=${sortby}`;

    history.push(urldemo);

    OpenOrClose();
  };

  useEffect(() => {
    if ((props.type === "sports" || props.type === "coach") && city != null) {
      dispatch(fetchSportsCategory(city, props.type));
    }
  }, [props.type, city]);

  useEffect(() => {
    dispatch(ResetState());

    if ((props.type === "sports" || props.type === "coach") && city != null) {
      dispatch(
        fetchSports(
          1,
          city,
          lat,
          lang,
          1,
          "pagination",
          url,
          "custom",
          props.type
        )
      );
    } else {
      dispatch(
        fetchSports(
          0,
          city,
          lat,
          lang,
          1,
          "pagination",
          url,
          "custom",
          props.type
        )
      );
    }
  }, [city, Category, Agelist, Pricelist, sortby, clearfilter, lat, lang]);

  const OpenOrClose = () => {
    document.getElementById("filterdiv").classList.toggle("open");
    document.getElementById("filterbtn").classList.toggle("open");
    document.body.classList.toggle("lock-scroll");
  };

  const ClearFilter = () => {
    console.log("clearbuton");
    let clearurl =
      props.type === "sports"
        ? `/sports?location=${city}`
        : props.type === "coach"
        ? `/coach?location=${city}`
        : `/gym-fitness?location=${city}`;

    history.push(clearurl);
    setclearfilter(!clearfilter);
    setCategory([]);
    setAgelist([]);
    setPricelist([]);
  };

  useEffect(() => {
    setclearfilter(!clearfilter);
    setCategory([]);
    setAgelist([]);
    setPricelist([]);

    setCategory(category);
    setAgelist(agelist);
    setPricelist(pricelist);
  }, [props.type]);

  const SliderCatChange = (title) => {
    setCategory(title);
  };

  const clearFilter = (e) => {
    e.preventDefault();

    const id = e.target.id;
    const name = e.target.getAttribute("name");
    let cat = Category;
    let age = Agelist;
    let price = Pricelist;

    if (name === "category") {
      cat = Category.filter((item) => item !== id);

      setCategory(cat);
    }
    if (name === "age") {
      age = Agelist.filter((item) => item !== id);

      setAgelist([]);
      age = "";
      dispatch(resetSelectedurlage());
    }
    if (name === "price") {
      price = Pricelist.filter((item) => item !== id);

      setPricelist([]);

      price = "";
      dispatch(resetSelectedurlprice());
    }

    changeUrl(cat, age, price);
  };

  const changeUrl = (cat, age, price) => {
    let newurl =
      props.type === "sports"
        ? `/sports?location=${city}&category=${cat}&age=${age}&price=${price}&sort=${sortby}`
        : props.type === "coach"
        ? `/coach?location=${city}&age=${age}&price=${price}&sort=${sortby}`
        : `/gym-fitness?location=${city}&age=${age}&price=${price}&sort=${sortby}`;

    history.push(newurl);
  };

  const setSortOption = (text) => {
    setsortby(text);
    let newurl =
      props.type === "sports"
        ? `/sports?location=${city}&category=&age=&price=&sort=${text}`
        : props.type === "coach"
        ? `/coach?location=${city}&age=&price=&sort=${text}`
        : `/gym-fitness?location=${city}&age=&price=&sort=${text}`;

    history.push(newurl);
  };
  return (
    <>
      {(props.type === "sports" || props.type === "coach") && (
        <>
          <Helmet>
            <title>{SITE_NAME} | Academy Listing</title>
          </Helmet>
          <section>
            <div className="container pt-5 mt-4">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                  {status === STATUS.LOADING ? (
                    <Loader />
                  ) : (
                    <StoreSlider
                      data={sportCategory}
                      setCategory={setCategory}
                      Category={Category}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <section className="py-5">
        <div className="container">
          <div className="row fil_row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <h3 className="main_heading">
                {props.type == "sports"
                  ? "Sports"
                  : props.type === "coach"
                  ? "Experts"
                  : "Gym-Fitness"}
              </h3>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 text-right mt-0 d-flex justify-content-end align-items-center">
              <div className="d-flex sort_div">
                <h6
                  className={`${sortby === "relevance" && "active"} mb-0`}
                  onClick={() => setSortOption("relevance")}
                >
                  Relevance
                </h6>
                <h6
                  className={`${sortby === "LtoH" && "active"} mb-0`}
                  onClick={() => setSortOption("LtoH")}
                >
                  Cost: Low to High
                </h6>
                <h6
                  className={`${sortby === "HtoL" && "active"} mb-0`}
                  onClick={() => setSortOption("HtoL")}
                >
                  Cost: High to Low
                </h6>
              </div>

              <div id="filterbtn" className="">
                <button className="sort_label" onClick={OpenOrClose}>
                  Filters&nbsp;&nbsp;<i className="fa fa-filter"></i>
                </button>
              </div>
              <FillterModel
                OpenOrClose={OpenOrClose}
                type={props.type}
                applyfilter={ApplyFilter}
                category={Category}
                setcategory={setCategory}
                agelist={Agelist}
                pricelist={Pricelist}
                clearfilter={ClearFilter}
              />
              {/* //fillter model */}
            </div>
          </div>

          {/* ///some fillter */}
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="pt-4">
                {Category &&
                  Category.length > 0 &&
                  Category.map((cat, i) => {
                    return (
                      <span
                        className="fil_val"
                        name="category"
                        id={cat}
                        onClick={clearFilter}
                        key={i}
                      >
                        {cat}
                      </span>
                    );
                  })}

                {/* <span className="fil_val">3 Star Rating</span> */}
                {Agelist && Agelist.length > 0 && (
                  <span
                    className="fil_val"
                    id={"filter"}
                    name="age"
                    onClick={clearFilter}
                  >
                    {Agelist.join("-")}
                  </span>
                )}

                {Pricelist && Pricelist.length > 0 && (
                  <span
                    className="fil_val"
                    id={"pricefilt"}
                    name="price"
                    onClick={clearFilter}
                  >
                    {Pricelist.join("-")}
                  </span>
                )}

                {Category.length > 0 ||
                Agelist.length > 0 ||
                Pricelist.length > 0 ? (
                  <button
                    type="reset"
                    className="reset_cls"
                    onClick={ClearFilter}
                  >
                    <i className="fa fa-undo"></i>&nbsp;&nbsp;Clear
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {/* ///some fillter */}
          <div className=" pt-3 pb-5">
            <>
              {props.type === "sports" ? (
                <FillterResults
                  type={props.type}
                  fillterData={sportList}
                  status={paginateStatus}
                  page={page}
                  hasMore={hasMore}
                  currentPage={currentPage}
                  totalcount={totalcount}
                  perPage={perPage}
                  handlePageChange={handlePageChange}
                  paginateStatus={paginateStatus}
                />
              ) : (
                <FillterResults
                  type={props.type}
                  fillterData={sportList}
                  status={paginateStatus}
                  page={page}
                  hasMore={hasMore}
                  currentPage={currentPage}
                  totalcount={totalcount}
                  perPage={perPage}
                  handlePageChange={handlePageChange}
                  paginateStatus={paginateStatus}
                />
              )}
            </>
          </div>
        </div>
      </section>
    </>
  );
};

export default Category;
