import React from "react";
import { IMAGE_URL } from "../../services/apiUrl";
import SingleFamilyMember from "./SingleFamilyMember";
import dimage from "../../assets/images/person.png";
import other from "../../assets/images/other.png";
import girl from "../../assets/images/girl.png";
import DeleteFamilyMember from "./DeleteFamilyMember";
import { useDispatch, useSelector } from "react-redux";
import { setIsModalVisible, setModalData } from "../../features/user/userSlice";

const FamilyMember = ({ member }) => {
  const { deletemodal, demo_user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const Findimage = (string) => {
    let image;
    if (string === null) {
      if (member?.fgender === "male") {
        image = dimage;
      } else if (member?.fgender === "female") {
        image = girl;
      } else {
        image = other;
      }
    } else if (string.includes("base64")) {
      image = string;
    } else if (string.includes("https")) {
      image = string;
    } else {
      image = `${IMAGE_URL}${string}`;
    }

    return image;
  };
  const DeleteFamilyMemberData = (data) => {
    dispatch(setIsModalVisible(true));
    dispatch(setModalData(data));
  };
  return (
    <>
      <div
        className="col-lg-3 col-md-6 col-sm-6 fmem_box mb-lg-0 mb-5"
        key={member.id}
      >
        <div className="text-center">
          <img
            src={Findimage(member?.fdisplayimg)}
            alt="Profile Image"
            className="img-fluid rounded-circle fmem_profile mb-3"
          />
          <h5 className="font_call">{member?.fmembername}</h5>
          <h6>{member?.femailid}</h6>
          <h6>{member?.fphoneno}</h6>
          <button
            type="button"
            className="btn btn-danger rounded-circle mem_del p-0 mx-1"
            onClick={() => DeleteFamilyMemberData(member)}
          >
            <i className="fa fa-trash"></i>
          </button>
          <button
            type="button"
            className="btn  rounded-circle mem_edit mx-1 p-0"
            data-bs-toggle="modal"
            data-bs-target={`#crudfamilymembers${member.id}`}
          >
            <i className="fa fa-pencil"></i>
          </button>
          {deletemodal && <DeleteFamilyMember />}
        </div>
      </div>
    </>
  );
};

export default FamilyMember;
