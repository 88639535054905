import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import img from "../../assets/images/bannerimg1.jpg";
import CartCard from "./CartCard";

const Cart = () => {
  const { cart } = useSelector((state) => state.cart);
  return (
    <>
      <div className="cart_div mt-5 mb-4">
        <h2 className="siderbar_head">Your Cart</h2>
        {cart && cart.length > 0
          ? cart.map((cartItem) => (
              <CartCard key={cartItem?.id} cart={cartItem} />
            ))
          : "Your cart is currently empty"}
      </div>

      {cart && cart.length > 0 && (
        <>
          <div className="text-center">
            <Link to="/checkout" className="btn checkout-button">
              My Cart
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default Cart;
