import React, { useState, useEffect } from "react";
import profileimage from "../../assets/images/person.png";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import classes from "../../components/login/Login.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFamilyMembers,
  setEditFamilyMembers,
  setFamilyMembers,
} from "../../features/user/userSlice";
import { IMAGE_URL } from "../../services/apiUrl";
const SingleFamilyMember = ({ id, member, member_id }) => {
  const [error, seterror] = useState([]);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [gender, setgender] = useState("male");
  const Findimage = (string) => {
    let image;
    if (string === null) {
      image = profileimage;
    } else if (string.includes("base64")) {
      image = string;
    } else if (string.includes("https")) {
      image = string;
    } else if (string.includes("static")) {
      image = string;
    } else {
      image = `${IMAGE_URL}${string}`;
    }

    return image;
  };
  const [profileImage, setprofileImage] = useState({
    image: member?.fdisplayimg || profileimage,
    imagefile: "",
  });
  const setMaxDateToday = () => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  const [memberinfo, setmemberinfo] = useState({
    id: member?.id,
    fmembername: member?.fmembername || "",
    member_id: member?.member_id || member_id,
    femailid: member?.femailid || "",
    fphoneno: member?.fphoneno || "",
    fdob: member?.fdob || "",
    faddress: member?.faddress || "",
    fcity: member?.fcity || "",
    fgender: member?.fgender || gender,
    fdistrict: member?.fdistrict || "",
    fpincode: member?.fpincode || "",
    fdisplayimg: member?.fdisplayimg || "",
    fcomments: member?.fcomments || "",
    frelation: member?.frelation || "",

    isnew: member?.isnew,
    status: member?.status,
  });

  const Handleinput = (e) => {
    e.preventDefault();
    setmemberinfo({ ...memberinfo, [e.target.name]: e.target.value });
  };

  const genderChange = (e) => {
    setgender(e.target.value);
    setmemberinfo({ ...memberinfo, fgender: e.target.value });
  };

  const onImageChange = (event) => {
    event.preventDefault();
    console.log(event.target.files);

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setprofileImage({
          image: e.target.result,
          imagefile: event.target.files[0],
        });

        setmemberinfo({ ...memberinfo, fdisplayimg: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const clickevent = (e) => {
    e.preventDefault();
    document.querySelector(`#profile${member?.id}`).click();
  };

  const closeModal = () => {
    document
      .querySelector(member?.id ? `#closemodal${member?.id}` : `#closemodal`)
      .click();

    const element = document.getElementById("addform");

    if (element) {
      element.reset();
    }

    if (id === "addfamilymembers") {
      setmemberinfo({
        fmembername: "",
        frelation: "",
        femailid: "",
        fphoneno: "",
        fdob: "",
        faddress: "",
        fcity: "",
        fgender: "",
        fdistrict: "",
        fpincode: "",
        fdisplayimg: "",
        fcomments: "",
      });

      setprofileImage({
        image: profileimage,
        imagefile: "",
      });
    }
  };

  const SubmitFamilyMember = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const data = { ...memberinfo };

    //console.log(data, "mprofile");
    let url =
      id === "addfamilymembers"
        ? `familymemberstore/${member_id}`
        : `familymemberupdate/${member?.id}`;
    // document
    //   .querySelector(member?.id ? `#closemodal${member?.id}` : `#closemodal`)
    //   .click();

    setloading(true);
    for (var key in memberinfo) {
      formData.append(key, memberinfo[key]);
    }

    formData.append("newimage", profileImage.imagefile);
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      closeModal();
      seterror([]);
      if (id === "addfamilymembers") {
        dispatch(fetchFamilyMembers());
      } else {
        dispatch(setEditFamilyMembers(data));
      }

      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
      if (error.response.data.errors) seterror(error.response.data.errors);
    }
  };
  return (
    <>
      <div
        className="modal fade member_modal"
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <form
              className="registration_form"
              onSubmit={SubmitFamilyMember}
              id={
                id === "addfamilymembers" ? "addform" : `addform${member?.id}`
              }
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {id === "addfamilymembers"
                    ? "Add Family Member"
                    : "View/Edit Family Member"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id={member?.id ? `closemodal${member?.id}` : `closemodal`}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="fmem_name" className="mt-0 log_label">
                          Member Name *
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="text"
                          name="fmembername"
                          id="fmem_name"
                          placeholder="Enter Name"
                          value={memberinfo?.fmembername}
                          onChange={Handleinput}
                          className="form-control profile_ip"
                        />
                        {error.length !== 0 && error.fmembername ? (
                          <p className={classes.validation}>
                            {error.fmembername[0]}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="fmem_dob" className="mt-0 log_label">
                          DOB *
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="date"
                          name="fdob"
                          id="fmem_dob"
                          value={memberinfo?.fdob}
                          placeholder="01-01-2000"
                          onChange={Handleinput}
                          max={setMaxDateToday()}
                          className="form-control profile_ip"
                        />
                        {error.length !== 0 && error.fdob ? (
                          <p className={classes.validation}>{error.fdob[0]}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="fmem_gender" className="mt-0 log_label">
                          Gender *
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <select
                          className="form-control"
                          value={memberinfo?.fgender || "male"}
                          onChange={genderChange}
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="fmem_email" className="mt-0 log_label">
                          Email *
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <>
                          <input
                            type="text"
                            name="femailid"
                            id="fmem_email"
                            onChange={Handleinput}
                            value={memberinfo?.femailid || ""}
                            placeholder="Enter Email"
                            className="form-control profile_ip"
                          />
                          {error.length !== 0 && error.femailid ? (
                            <p className={classes.validation}>
                              {error.femailid[0]}
                            </p>
                          ) : null}
                        </>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="fmem_phnum" className="mt-0 log_label">
                          Phone Number *
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <>
                          <input
                            type="text"
                            name="fphoneno"
                            id="fmem_phnum"
                            value={memberinfo?.fphoneno || ""}
                            placeholder="Enter Phone Number"
                            onChange={Handleinput}
                            className="form-control profile_ip"
                          />
                          {error.length !== 0 && error.fphoneno ? (
                            <p className={classes.validation}>
                              {error.fphoneno[0]}
                            </p>
                          ) : null}
                        </>
                        {/* {id == "addfamilymembers" ? (
                          <>
                            <input
                              type="text"
                              name="fphoneno"
                              id="fmem_phnum"
                              value={memberinfo?.fphoneno || ""}
                              placeholder="0123456789"
                              onChange={Handleinput}
                              className="form-control profile_ip"
                            />
                            {error.length !== 0 && error.fphoneno ? (
                              <p className={classes.validation}>
                                {error.fphoneno[0]}
                              </p>
                            ) : null}
                          </>
                        ) : memberinfo.fphoneno != user?.phoneno ? (
                          <>
                            <input
                              type="text"
                              name="fphoneno"
                              id="fmem_phnum"
                              value={memberinfo?.fphoneno || ""}
                              placeholder="0123456789"
                              onChange={Handleinput}
                              className="form-control profile_ip"
                            />
                            {error.length !== 0 && error.fphoneno ? (
                              <p className={classes.validation}>
                                {error.fphoneno[0]}
                              </p>
                            ) : null}
                          </>
                        ) : (
                          <p>{memberinfo?.fphoneno || ""}</p>
                        )} */}
                      </div>
                    </div>

                    <div className="row align-items-center mt-3 row_flex">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <p className="log_label mt-0">Display Image</p>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="file"
                          className="previewlabel input-img profileprevFamily"
                          name="fmem_img"
                          id={`profile${member?.id}`}
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={onImageChange}
                        />
                        <img
                          src={Findimage(
                            profileImage.image || member.fdisplayimg
                          )}
                          alt="Profile"
                          className="profilepreview img-fluid"
                        />
                        <label
                          onClick={clickevent}
                          className="mt-0 ml-4 btn rounded-0 previewlabel"
                        >
                          Change Image
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label
                          htmlFor="fmem_relation"
                          className="mt-0 log_label"
                        >
                          Relation
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="text"
                          name="frelation"
                          id="fmem_relation"
                          onChange={Handleinput}
                          value={memberinfo?.frelation}
                          className="form-control profile_ip"
                        />
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label
                          htmlFor="fmem_address"
                          className="mt-0 log_label"
                        >
                          Address
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="text"
                          name="faddress"
                          id="fmem_address"
                          onChange={Handleinput}
                          value={memberinfo?.faddress}
                          placeholder="Enter Address"
                          className="form-control profile_ip"
                        />
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label htmlFor="fmem_city" className="mt-0 log_label">
                          City
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="text"
                          name="fcity"
                          id="fmem_city"
                          onChange={Handleinput}
                          value={memberinfo?.fcity}
                          placeholder="Enter City"
                          className="form-control profile_ip"
                        />
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <label
                          htmlFor="fmem_pincode"
                          className="mt-0 log_label"
                        >
                          Pincode
                        </label>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8">
                        <input
                          type="text"
                          name="fpincode"
                          id="fmem_pincode"
                          onChange={Handleinput}
                          value={memberinfo?.fpincode}
                          placeholder="Enter Pincode"
                          className="form-control profile_ip"
                        />
                      </div>
                    </div>

                    {id !== "addfamilymembers" && (
                      <div className="row align-items-center mt-3">
                        <div className="col-lg-4 col-md-4 col-sm-4">
                          <label
                            htmlFor="fmem_status"
                            className="mt-0 log_label"
                          >
                            Status
                          </label>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8">
                          {memberinfo?.status === 1 ? (
                            <span className="text-success">Active</span>
                          ) : (
                            <span className="text-danger">In Active</span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {loading ? (
                  <CircularProgress className={classes.progressbar} />
                ) : (
                  <input
                    type="Submit"
                    className="btn pro_btn rounded-0"
                    placeholder="Save"
                  />
                )}

                {/* <!-- <button type="button" className="btn pro_btn rounded-0">Save changes</button> --> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleFamilyMember;
