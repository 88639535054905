import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Academics from "../components/academics/Academics";
import { fetchFeaturehome } from "../features/home/homeSlice";
import BootomFooter from "../components/academics/BootomFooter";

export const Home = (props) => {
  const dispatch = useDispatch();

  const { academies, academyCount, status, errormessage } = useSelector(
    (state) => state.home
  );

  const { city, lat, lang } = useSelector((state) => state.user);

  const Loadingprogress = props.Loadingprogress;
  useEffect(() => {
    Loadingprogress(100);
  }, []);

  useEffect(() => {
    city && dispatch(fetchFeaturehome(city, lat, lang));
  }, [dispatch, city]);

  return (
    <main>
      <Academics />
      {/* <BootomFooter /> */}
    </main>
  );
};
