import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../services/apiUrl";
import { STATUS } from "../../services/Status";

export const fetchGetuser = createAsyncThunk("user/fetchGetuser", async () => {
  const response = await axios.get("user");

  return response.data;
});

export const fetchLogout = createAsyncThunk("user/fetchLogout", async () => {
  const response = await axios.post("logout");

  return response.data;
});
const initialState = {
  token: "",
  detect: false,
  loading: false,
  demo_user: false,
  error: [],
  familymembers: [],
  locationerror: "",
  user: [],
  isLoggedin: false,
  loginModel: false,
  lat: "",
  lang: "",
  city: "",
  globalcity: null,
  locationfound: false,
  registeruser: [],
  phoneActivated: false,
  emailActivated: false,
  Otpget: false,
  Otpverified: false,
  SocialLoginprofile: false,
  TotalLoader: false,
  status: "",
  deletemodal: false,
  deletedata: [],
  buyedpackages: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginModel: (state, { payload }) => {
      state.loginModel = !state.loginModel;
    },
    logout: (state) => {
      state.isLoggedin = false;
      localStorage.removeItem("auth-token");
      state.user = [];
    },

    errorLocation: (state, { payload }) => {
      state.locationfound = false;
      state.locationerror = payload;
      state.lat = 12.9505303;
      state.lang = 77.4836497;
      state.city = "Bengaluru";
      localStorage.setItem("latitude", 12.9505303);
      localStorage.setItem("longitude", 77.4836497);
      localStorage.setItem("city", "Bengaluru");
    },

    updateLocation: (state, { payload }) => {
      localStorage.setItem("latitude", payload[0]);
      localStorage.setItem("longitude", payload[1]);
      localStorage.setItem("city", payload[2]);

      state.lat = payload[0];
      state.lang = payload[1];
      state.city = payload[2];
      state.locationfound = true;
      state.globalcity = null;
    },

    setglobalcity: (state, { payload }) => {
      state.globalcity = payload;
      state.detect = false;
    },

    setDemouser: (state, { payload }) => {
      state.demo_user = payload;
    },
    setChangeCity: (state, { payload }) => {
      state.city = payload;
    },
    setDetect(state, action) {
      state.detect = !state.detect;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },

    setRegisterUser(stae, action) {
      stae.registeruser = action.payload;
    },

    setClearRegisterUser(state) {
      state.registeruser = [];
    },
    setOtpget(state, action) {
      state.Otpget = action.payload;
    },
    setOtpVerified(state, action) {
      state.Otpverified = action.payload;
    },
    setSocialLoginprofile(state, action) {
      state.SocialLoginprofile = action.payload;
    },
    setTotalLoader(state, action) {
      state.TotalLoader = action.payload;
    },
    setLoggedin(state, action) {
      state.isLoggedin = action.payload;
    },
    setFamilyMembers(state, action) {
      // state.familymembers.push(action.payload);
      // state.status = STATUS.LOADING;
      // state.status = STATUS.IDLE;
      state.familymembers = action.payload;
    },

    setModalData(state, action) {
      state.deletedata = action.payload;
    },
    setIsModalVisible(state, action) {
      state.deletemodal = action.payload;
    },

    RemoveFamilymember(state, action) {
      state.familymembers = state.familymembers.filter(
        (fam) => fam.id != action.payload
      );
    },

    setEditFamilyMembers(state, action) {
      state.familymembers.forEach((element, index) => {
        if (element.id === action.payload.id) {
          state.familymembers[index] = action.payload;
        }
      });
    },

    setbuyedPackages(state, action) {
      state.buyedpackages = action.payload;
    },

    setEmailPhoneActivate(state, action) {
      if (action.payload?.type == "email") {
        state.emailActivated = action.payload.value;
      } else {
        state.phoneActivated = action.payload.value;
      }
    },

    setUserOffers(state, { payload }) {
      if (payload[1] === "promotional") {
        if (payload[0] === "email") {
          state.user["proemail"] = payload[2];
        } else {
          state.user["prowhatsapp"] = payload[2];
        }
      } else if (payload[1] === "offer") {
        if (payload[0] === "email") {
          state.user["offeremail"] = payload[2];
        } else {
          state.user["offerwhatsapp"] = payload[2];
        }
      } else if (payload[1] === "order") {
        if (payload[0] === "email") {
          state.user["orderemail"] = payload[2];
        } else {
          state.user["orderwhatsapp"] = payload[2];
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetuser.fulfilled, (state, { payload }) => {
      state.token = localStorage.getItem("auth-token");
      state.isLoggedin = true;
      state.user = payload;
      state.familymembers = payload.familymembers;
    });

    builder.addCase(fetchLogout.fulfilled, (state, { payload }) => {
      window.location.reload(false);
      state.token = localStorage.removeItem("auth-token");
      state.isLoggedin = false;
      state.user = [];
      state.familymembers = [];
      state.Otpget = false;
      state.Otpverified = false;
      state.demo_user = false;
      state.SocialLoginprofile = false;
      localStorage.removeItem("cart");
    });
  },
});

export default userSlice.reducer;
export const {
  loginModel,
  logout,
  updateLocation,
  errorLocation,
  setChangeCity,
  setDetect,
  setRegisterUser,
  setClearRegisterUser,
  setOtpget,
  setOtpVerified,
  setSocialLoginprofile,
  setTotalLoader,
  setLoggedin,
  setFamilyMembers,
  setModalData,
  setIsModalVisible,
  RemoveFamilymember,
  setEditFamilyMembers,
  setStatus,
  setUserOffers,
  setEmailPhoneActivate,
  setglobalcity,
  setDemouser,
} = userSlice.actions;

export const fetchTokenStringEnableloggedin = () => {
  return async function fetchTokenStringEnableloggedin(dispatch) {
    try {
      const token = localStorage.getItem("auth-token");

      if (token) {
        dispatch(setLoggedin(true));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchFamilyMembers = () => {
  //console.log(location, option);
  return async function fetchFamilyMembersThunk(dispatch) {
    dispatch(setStatus(STATUS.LOADING));

    axios
      .get("fetchfamilymembers")
      .then((res) => {
        dispatch(setFamilyMembers(res.data));
        dispatch(setStatus(STATUS.IDLE));
      })
      .catch((error) => {
        dispatch(setStatus(STATUS.ERROR));
      });
  };
};
