import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footerlogo from "../../assets/images/logo1.svg";
import playstroe from "../../assets/images/play_store.webp";
import appstroe from "../../assets/images/app_store.webp";
import twitter from "../../assets/images/twitterfooter.png";
import useFetch from "../../hooks/useFetch";
import { BASE_URL, IMAGE_URL } from "../../services/apiUrl";
const Footer = () => {
  const { config } = useSelector((state) => state.home);
  return (
    <>
      {/* <!-- FOOTER --> */}
      <footer className="mt-5">
        <div className="footer_widget">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 pb-5 pb-md-0">
                <div className="widget_1">
                  <Link to="/">
                    <img
                      src={Footerlogo}
                      width="160"
                      className="img-fluid nav-logo mb-2"
                      alt="Logo"
                    />
                  </Link>
                  <p>{config?.shortdesc}</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 pb-5 pb-md-0">
                <div className="widget_2">
                  <h5 className="widget_title">Contact</h5>
                  <h6>
                    <a href="#">
                      <i className="fa fa-map-marker"></i>
                      {config?.place} , {config?.city}
                    </a>
                  </h6>
                  {/* <h6>
                    <a href="tel:0123456789">
                      <i className="fa fa-phone"></i>
                      {config?.company_phone}
                    </a>
                  </h6> */}
                  <h6>
                    <a href={`mailto:${config?.company_email}`}>
                      <i className="fa fa-envelope"></i>
                      {config?.company_email}
                    </a>
                  </h6>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 pb-5 pb-md-0">
                <div className="widget_3">
                  <h5 className="widget_title">Links</h5>
                  <h6>
                    <Link to="/">Home</Link>
                  </h6>
                  <h6>
                    <Link to="/about-us">About Us</Link>
                  </h6>
                  <h6>
                    <Link to="/contact-us">Contact Us</Link>
                  </h6>
                  {/* <h6 className="hidden_class">
                    <Link to="/blogs">Analytics</Link>
                  </h6> */}
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div>
                  <h5 className="widget_title">Social Links</h5>
                  <a
                    href={config?.twitterurl}
                    className="social_icon"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitter} width={18} />
                  </a>
                  <a
                    href={config?.linkedinurl || "https://www.youtube.com/"}
                    className="social_icon"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                  <a
                    href={config?.fburl}
                    className="social_icon"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    href={config?.instagramurl}
                    className="social_icon"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="app_store_link text-center">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <div className="footer_link_text">
                  <h4>For better experience,</h4>
                  <h4>download the Sportzlete app now</h4>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6">
                <div className="app_link_icons">
                  <div className="playstore">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.wnapp.id1691580677794&pcampaignid=web_share"
                      target="_blank"
                    >
                      <img
                        src={playstroe}
                        width="160"
                        className="img-fluid nav-logo mb-2"
                        alt="Playstore"
                      />
                    </a>
                  </div>
                  <div className="appstore">
                    <a
                      href="https://apps.apple.com/in/app/sportzlete/id6464368094"
                      target="_blank"
                    >
                      <img
                        src={appstroe}
                        width="160"
                        className="img-fluid nav-logo mb-2"
                        alt="Appstore"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_copyright text-center">
          <div className="terms_links">
            <ul>
              <li>
                <Link to="/termsand-condition">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/cookiepolicy">Cookie Policy</Link>
              </li>
              <li>
                <Link to="/refundpolicy">Refund Policy</Link>
              </li>
              <li>
                <Link to="/disclaimerpolicy">Disclaimer Policy</Link>
              </li>
              <li>
                <Link to="/privacypolicy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <h6>
            Copy Rights{" "}
            <a href="/">
              &copy;{new Date().getFullYear()} - Infinite Change Info Systems
              Pvt. Ltd.
            </a>
          </h6>
        </div>
      </footer>
      {/* <!-- END FOOTER --> */}
    </>
  );
};

export default Footer;
