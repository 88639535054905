import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { isLoggedin } = useSelector((state) => state.user);
  let location = useLocation();

  if (!isLoggedin) {
    return <Redirect to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
