import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { IMAGE_URL } from "../../services/apiUrl";
import { shortString, slugify } from "../../services/Helper";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetsearchItems } from "../../features/home/homeSlice";

const SearchItem = ({ item, setsearch, search }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    AOS.init();
  }, []);

  const Gotolink = () => {
    setsearch(null);

    const element = document.getElementById("top-search");

    if (element) document.getElementById("top-search").value = "";

    //document.getElementById("sub-search").value = "";
    history.push(`/academy/${item.id}/${slugify(item.academyname)}`);
    search(false);
    dispatch(resetsearchItems());
  };
  return (
    <>
      <div
        className="search__item"
        data-aos="zoom-in"
        style={{ cursor: "pointer" }}
      >
        <img
          src={`${IMAGE_URL}${item.displayimg}`}
          alt="item-images"
          className="search-item__image"
        />

        <div onClick={Gotolink} data-bs-dismiss="modal">
          <div className="search__content">
            <p className="academy">{item.academyname}</p>
            <p>
              {item?.city}-{item?.sublocation}- {item.categoryname}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchItem;
