import React from "react";
import { useSelector } from "react-redux";
import ParagraphLoader from "../../common/loader/ParagraphLoader";
import twitter from "../../assets/images/twitterfooter_white.png";
const Text = ({ text1, text2, loading }) => {
  function createMarkup(text) {
    return { __html: text };
  }
  const { config } = useSelector((state) => state.home);
  return (
    <>
      <div className="col-lg-6 col-md-12">
        {/* <!-- contents here --> */}
        <h3 className="font_call">Call Us, Write Us</h3>
        <p className="font1">
          {loading ? (
            <>
              <ParagraphLoader />
            </>
          ) : (
            <div dangerouslySetInnerHTML={createMarkup(text1)} />
          )}
        </p>
        <p className="font2">
          {loading ? (
            <>
              <ParagraphLoader />
            </>
          ) : (
            <>
              <div dangerouslySetInnerHTML={createMarkup(text2)} />
              <br />
              {/* call:&nbsp;<a href="tel:9876543210">{config?.company_phone}</a> */}
            </>
          )}
        </p>
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <a
              className="text_dec"
              href={config?.fburl}
              target="_blank"
              rel="noreferrer"
            >
              <h6>
                <i className="fa fa-facebook contact_icon"></i> Connect Us On
                Facebook
              </h6>
            </a>
          </div>
          <div className="col-md-6 col-sm-6 mt-2 mt-sm-0">
            <a
              className="text_dec"
              href={config?.twitterurl}
              target="_blank"
              rel="noreferrer"
            >
              {/* <h6 className="contact_icon">
                <img src={twitter} width={5} />
                <p>Connect Us On Twitter</p>
              </h6> */}
              <div className="twitter_div">
                <div className="contact_icon">
                  <img src={twitter} width={10} />
                </div>

                <p>Connect Us On Twitter</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Text;
