import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../services/apiUrl";
import { shortString, slugify } from "../../services/Helper";

const BlogCard = ({ blog, openAuthorinfo }) => {
  const slug = slugify(blog?.blogtitle);

  return (
    <>
      <div className="col-md-4">
        <div className="blog_main">
          <Link
            to={`/blog/${blog.id}/${slug}`}
            className="text-decoration-none"
          >
            <div className="overflow-hidden mb-2">
              <div
                className="blog_col blog_col_image"
                style={{ backgroundImage: `url(${IMAGE_URL}${blog?.blogimg})` }}
              >
                {/* <span className="sport_label bg_green">
                <h6 className="m-0 text-white">{blog?.catnames}</h6>
              </span> */}
              </div>
            </div>
          </Link>

          <h6 className="blog_des mt-2">{shortString(blog?.blogtitle, 100)}</h6>
          <div className="blog_content d-flex gap-3">
            <div className="d-flex align-items-center ">
              <h6 className="blog_date mb-0">{blog?.blogdate}</h6>
              <span
                className="blog_by"
                onClick={() => openAuthorinfo(blog?.aboutblogauthor)}
              >
                {blog?.blogauthor} <i className="fa fa-info-circle"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
