import React from "react";

const FullPageLoader = () => {
  return (
    <>
      <div className="loading">
        <div className="loader"></div>
      </div>
    </>
  );
};

export default FullPageLoader;
