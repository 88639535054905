import React from "react";
import { useDispatch } from "react-redux";

import classes from "../../components/profile/Familymember.module.css";
import {
  setIsCartModalVisible,
  setIsModalVisible,
  setIsTermsModalVisible,
} from "../../features/modal/modalSlice";
import { resetCancelReason } from "../../features/sports/sportSlice";

const ModalBox = ({ children }) => {
  const dispatch = useDispatch();

  const modalOverlayHandler = (e) => {
    if (e.target.classList.contains("overlay_bg")) {
      dispatch(setIsModalVisible(false));
      dispatch(setIsTermsModalVisible(false));
      dispatch(setIsCartModalVisible(false));
      dispatch(resetCancelReason());
    }
  };

  const CLoseModal = () => {
    dispatch(setIsModalVisible(false));
    dispatch(setIsTermsModalVisible(false));
    dispatch(setIsCartModalVisible(false));
    dispatch(resetCancelReason());
  };
  return (
    <>
      <div className={classes.overlay_bg} onClick={modalOverlayHandler}>
        <div className={`${classes.details}  bg-white`}>
          <button
            type="button"
            className={`${classes.modal_close_btn} flex flex-center fs-14`}
            onClick={CLoseModal}
          >
            <i className="fa fa-times" onClick={CLoseModal}></i>
          </button>

          <div className="modal-body">{children}</div>

          {/* <div className={classes.deletebutton}>
            <button
              className="btn text-white bg-dark"
              onClick={() => dispatch(setIsModalVisible(false))}
            >
              Cancel
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ModalBox;
