import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../services/apiUrl";
import { STATUS } from "../../services/Status";

const initialState = {
  status: STATUS.LOADING,

  comments: [],
  unapproved: null,
  is_unapproved: false,
  editcomment: null,
};

const commentSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setStatus(state, action) {
      state.status = action.payload;
    },
    setComments(state, action) {
      state.comments = action.payload;
    },
    seteditComment(state, action) {
      state.editcomment = action.payload;
    },
    setCommentsUnapproved(state, action) {
      state.unapproved = action.payload;
      if (action.payload != null)
        state.comments = [...state.comments, action.payload];
    },

    setCommentSort(state, action) {
      state.comments = state.comments.slice().sort((productsA, productsB) => {
        if (action.payload === "1") {
          return productsA.id > productsB.id ? 1 : -1;
        } else {
          return productsA.rating < productsB.rating ? 1 : -1;
        }
      });
    },
  },
});

export default commentSlice.reducer;

export const {
  setStatus,
  setComments,
  setCommentSort,
  setCommentsUnapproved,
  seteditComment,
} = commentSlice.actions;

export const fetchComments = (id) => {
  //console.log(location, option);
  return async function fetchCommentsThunk(dispatch) {
    dispatch(setStatus(STATUS.LOADING));
    try {
      const response = await axios
        .get(`${BASE_URL}GetAllCommentRating/${id}`)
        .then((res) => {
          dispatch(setComments(res.data.data));
          dispatch(setCommentsUnapproved(res?.data?.unapproved));
          dispatch(setStatus(STATUS.IDLE));
        })
        .catch((err) => {
          dispatch(setStatus(STATUS.ERROR));
        });
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUS.ERROR));
    }
  };
};
