import React from "react";
import { useDispatch } from "react-redux";
import { removeCart } from "../../features/cart/cartSlice";
import { toIndianCurrency } from "../../services/Helper";

const CartCheckout = ({ cart, type }) => {
  const dispatch = useDispatch();
  return (
    <>
      <tr>
        <td>
          <h6 className="mb-0">{cart?.academy_name}</h6>
          <div className="pack__info">
            <small>
              Member Name :{" "}
              <span className="strong_cls">{cart?.selectedPersonName}</span>
            </small>

            <small>
              Package Name :{" "}
              <span className="strong_cls">{cart?.selectedPackageName}</span>
            </small>

            <small>
              Start Date :<span>{cart?.selecteddate}</span>
            </small>
            <small>Addons Selected : </small>

            {cart?.addoninfo.map((addon, i) => (
              <small key={i} className="strong_cls">
                {addon?.name}{" "}
              </small>
            ))}
          </div>
        </td>
        <td className="align_right">
          <h6 className="mb-0"> &nbsp;</h6>
          <div className="pack__info">
            <small>&nbsp;</small>

            <span className="primary_clr strong_cls">
              {toIndianCurrency(cart?.packageprice)}
            </span>

            <small>&nbsp;</small>

            {cart?.addoninfo.map((addon, i) => (
              <span className="primary_clr strong_cls">
                {toIndianCurrency(addon?.price)}
              </span>
            ))}
          </div>
        </td>
        <td className="align_right">
          {" "}
          <h6 className="mb-0"> &nbsp;</h6>
          <div className="pack__info">
            <small>&nbsp;</small>

            <span className="primary_clr strong_cls">
              {toIndianCurrency(cart?.packagepriceGst)} ({" "}
              {cart?.selectedPackageGst}%)
            </span>

            <small>&nbsp;</small>

            {cart?.addoninfo.map((addon, i) => (
              <span className="primary_clr strong_cls">
                {toIndianCurrency((addon?.price * Number(addon?.gst)) / 100)} (
                {addon?.gst}%)
              </span>
            ))}
          </div>
        </td>
        <td className="align_right">
          {" "}
          <h6 className="mb-0"> &nbsp;</h6>
          <div className="pack__info">
            <small>&nbsp;</small>

            <span className="primary_clr strong_cls">
              {toIndianCurrency(cart?.selectedPackageConveinentFee)}
            </span>

            <small>&nbsp;</small>
            {cart?.addoninfo.map((addon, i) => (
              <span className="primary_clr strong_cls">
                {toIndianCurrency(addon?.convfee)}
              </span>
            ))}
          </div>
        </td>
        <td className="align_right">
          <h6 className="mb-0"> &nbsp;</h6>
          <div className="pack__info">
            <small>&nbsp;</small>

            <span className="primary_clr strong_cls">
              {toIndianCurrency(
                cart?.packageprice +
                  cart?.packagepriceGst +
                  cart?.selectedPackageConveinentFee
              )}
              {/* {toIndianCurrency(
                Math.round(
                  cart?.packageprice +
                    (cart?.packageprice * Number(cart?.selectedPackageGst)) /
                      100
                )
              )}{" "} */}
            </span>

            <small>&nbsp;</small>

            {cart?.addoninfo.map((addon, i) => (
              <span className="text-danger strong_cls">
                {toIndianCurrency(
                  addon?.price +
                    (addon?.price * Number(addon?.gst)) / 100 +
                    addon?.convfee
                )}{" "}
              </span>
            ))}
          </div>
        </td>
        <td className="mt-5 align_center delete_icon">
          <p
            className="mb-0 text-dark mt-5"
            onClick={() => dispatch(removeCart(cart?.id))}
          >
            <i className="fa fa-trash"></i>
          </p>
        </td>
      </tr>
    </>
  );
};

export default CartCheckout;
