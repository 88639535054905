import React from "react";
import Logo from "../../assets/images/logosp.png";
import Sportsicon from "../../assets/images/sportsicon2.png";
import Gymicon from "../../assets/images/gymicon3.png";
import Storeicon from "../../assets/images/storeicon.png";
import Usericon from "../../assets/images/usericon.png";
import analytics from "../../assets/images/analytics.png";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import AfterLogin from "../navigation/AfterLogin";
import coach from "../../assets/images/coach.png";

const Mobilefooter = () => {
  const { isLoggedin } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  return (
    <>
      <section className="d-block d-lg-none mbl_bottom_sec">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-2 px-0">
              <div>
                <Link to="/">
                  <img src={Logo} className="img-fluid" />
                </Link>
              </div>
            </div>

            <div className="col-2 px-0">
              <div>
                <NavLink
                  to="/sports"
                  className="mbl_bottom text-center text-decoration-none"
                >
                  <div>
                    <img src={Sportsicon} className="img-fluid" />
                  </div>
                  <h6>Sports</h6>
                </NavLink>
              </div>
            </div>

            <div className="col-2 px-0">
              <div>
                <NavLink
                  to="/gym-fitness"
                  className="mbl_bottom text-center text-decoration-none"
                >
                  <div>
                    <img src={Gymicon} className="img-fluid" />
                  </div>
                  <h6>Gym&Fitness</h6>
                </NavLink>
              </div>
            </div>
            <div className="col-2 px-0">
              <div>
                <NavLink
                  to="/coach"
                  className="mbl_bottom text-center text-decoration-none"
                >
                  <div>
                    <img src={coach} className="img-fluid" />
                  </div>
                  <h6>Experts</h6>
                </NavLink>
              </div>
            </div>
            <div className="col-2 px-0">
              <div>
                <NavLink
                  to="/blogs"
                  className="mbl_bottom text-center text-decoration-none"
                >
                  <div>
                    <img src={analytics} className="img-fluid" />
                  </div>
                  <h6>Analytics</h6>
                </NavLink>
              </div>
            </div>

            {/* <div className="col-2 px-0">
              <div>
                <Link
                  to="/"
                  className="mbl_bottom text-center text-decoration-none"
                >
                  <div>
                    <img src={Storeicon} className="img-fluid" />
                  </div>
                  <h6>Store</h6>
                </Link>
              </div>
            </div> */}

            <div className="col-2 px-0">
              <div className="position-relative d-inline-block">
                {isLoggedin ? (
                  <>
                    <AfterLogin type="footer" />
                    <Link
                      to="/checkout"
                      className="mbl_bottom text-center text-decoration-none"
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "25px",
                        }}
                      >
                        {/* <i className="fa cart-icon-1">&#xf07a;</i> */}
                        <span
                          className="badge badge-warning"
                          id="lblCartCount-mobile"
                        >
                          {cart && cart.length}
                        </span>
                      </div>
                      {/* <h6 style={{ textAlign: "left" }}>Cart</h6> */}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="mbl_bottom text-center text-decoration-none"
                    >
                      <div>
                        <img
                          src={Usericon}
                          className="img-fluid"
                          alt="usericon"
                        />
                      </div>
                      <h6>Account</h6>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mobilefooter;
