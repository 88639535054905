import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../../services/apiUrl";
import { createMarkup, shortString, slugify } from "../../../services/Helper";

const FillterCard = (props) => {
  const {
    academyname,
    firstname,
    id,
    displayimg,
    city,
    sublocation,
    packages,
    price,
    offerprice,
    rating,
    sport,
  } = props.product;
  const url =
    props.type === "sports" || props.type === "coach"
      ? `/academy/${id}/${slugify(academyname)}`
      : `/gym-fitness/${id}/${slugify(academyname)}`;

  const CalculateRating = (rating) => {
    if (rating.length > 0) {
      var pointsScored = 0;
      var totalPoints = 0;
      rating.map((value) => {
        pointsScored = pointsScored + Number(value.pointscored);
        totalPoints = totalPoints + Number(value.totalpoints);
      });
      // const sumWithInitial = rating.reduce((accumulator, currentValue) => {
      //   const pointsScored = currentValue.pointscored;
      //   const totalPoints = currentValue.totalpoints;

      //   // Calculate the rating for the current object and accumulate it
      //   const currentRating = (pointsScored * 5) / totalPoints;
      //   return accumulator + currentRating;
      // }, 0);

      var total = (pointsScored * 5) / totalPoints;

      return total > 0 ? total.toFixed(2) : 0;
    } else {
      return 0;
    }
  };
  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <Link to={`${url}`} className="cate_link">
          <div className="cate_box">
            <div
              className="cate_img"
              style={{
                backgroundImage: `url(${IMAGE_URL}${displayimg})`,
              }}
            ></div>
            <div className=" pt-4">
              <div className="cate_left">
                <h5 className="mb-1">{academyname} </h5>
                <h6 className="stars">
                  <span style={{ display: "flex" }}>
                    <i className="fa fa-star "></i>
                    {CalculateRating(sport && sport?.rating)}
                    {/* {rating && rating != null
                      ? Math.round((rating + Number.EPSILON) * 100) / 100
                      : 0} */}
                  </span>
                </h6>
              </div>
              <div className="city_info">
                <p className="mb-1">
                  {city}
                  {sublocation && ` - ${sublocation}`}
                </p>

                <div className="start_rating">
                  {/* <h6 className="cate_price">
                    {packages.length > 0 && (
                      <>{`Rs.${price}` || `Rs.${offerprice}`}</>
                    )}
                  </h6> */}
                </div>
                {/* <p className="mb-1">20-30 Years</p> */}
              </div>
              <div className="city_info">
                {sport && sport?.shortdesc && (
                  <div
                    className="custom_style"
                    dangerouslySetInnerHTML={createMarkup(
                      shortString(sport?.shortdesc, 30)
                    )}
                  />
                )}
              </div>
              <div className="cate_right"></div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default FillterCard;
