import React, { useState, useCallback } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setlightboximage,
  setLightboxview,
} from "../../features/sports/sportSlice";
import { photos } from "./Photos";

const LightBox = () => {
  const { Lightboximages, Lightboxview, photoIndex } = useSelector(
    (state) => state.sport
  );

  const dispatch = useDispatch();

  return (
    <>
      {Lightboxview && (
        <Lightbox
          mainSrc={Lightboximages[photoIndex]}
          nextSrc={Lightboximages[(photoIndex + 1) % Lightboximages.length]}
          prevSrc={
            Lightboximages[
              (photoIndex + Lightboximages.length - 1) % Lightboximages.length
            ]
          }
          onCloseRequest={() => dispatch(setLightboxview(false))}
          onMovePrevRequest={() =>
            dispatch(
              setlightboximage(
                (photoIndex + Lightboximages.length - 1) % Lightboximages.length
              )
            )
          }
          onMoveNextRequest={() =>
            dispatch(
              setlightboximage(
                (photoIndex + Lightboximages.length + 1) % Lightboximages.length
              )
            )
          }
        />
      )}
    </>
  );
};

export default LightBox;
