import React, { useState, useCallback } from "react";
import Slider from "react-slick";
import { Banner } from "../../common/slider/Settings";

import Card from "./Card";

const BannerSlider = ({ images }) => {
  return (
    <>
      {images && images.length >= 3 ? (
        <Slider {...Banner} className="sportsbanner_slider">
          {images.map((data, index) => {
            return <Card img={data} key={index} photoindex={index} />;
          })}
        </Slider>
      ) : (
        <>
          <div className="sportsbanner_slider nonslick">
            {images.map((data, index) => {
              return <Card img={data} key={index} photoindex={index} />;
            })}
          </div>
        </>
      )}
    </>
  );
};

export default BannerSlider;
