import React from "react";
import ContentLoader from "react-content-loader";

const RestaurentLoader = (props) => {
  return (
    <>
      <ContentLoader
        viewBox="0 0 330 400"
        backgroundColor="#f0f0f0"
        foregroundColor="#dedede"
        {...props}
      >
        <rect x="26" y="320" rx="4" ry="4" width="271" height="9" />
        <rect x="27" y="340" rx="5" ry="5" width="220" height="40" />
        <rect x="24" y="7" rx="10" ry="10" width="388" height="300" />
      </ContentLoader>
    </>
  );
};

export default RestaurentLoader;
