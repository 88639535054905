import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const Accordian = ({ title, children, expandtype }) => {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    expandtype && setOpen("1");
  }, [expandtype]);

  return (
    <div className="accordian_style mt-4">
      <Accordion flush open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">{title}</AccordionHeader>
          <AccordionBody accordionId="1">{children}</AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Accordian;
