import React from "react";
import Skeleton from "@mui/material/Skeleton";

const FamilMemberLoader = () => {
  return (
    <>
      <div className="row">
        {[1, 2, 3, 4].map((numbers, i) => (
          <>
            <div
              className="col-lg-3 col-md-6 col-sm-6 fmem_box mb-lg-0 mb-5"
              key={i}
            >
              <div className="text-center">
                <Skeleton variant="circular" width={100} height={100} />
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default FamilMemberLoader;
