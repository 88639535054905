import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../services/apiUrl";
import noimage from "../../../src/assets/images/noimage.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { shortString, slugify, createMarkup } from "../../services/Helper";
const AcademyCard = ({
  link,
  img,
  desc,
  title,
  key,
  id,
  slug,
  city,
  sublocation,
  shortdesc,
}) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const srcfile = img ? `${IMAGE_URL}${img}` : noimage;

  return (
    <>
      <div className="slide_box" key={key} data-aos="zoom-in">
        <Link to={`/academy/${id}/${slugify(slug)}`}>
          <div
            className="cate_img"
            style={{ backgroundImage: `url(${srcfile})` }}
          >
            {/* <img src={srcfile} alt={title} /> */}
          </div>
          <div className="text-center p-4">
            <h4 className="font-weight-bold">{title}</h4>

            {sublocation ? (
              <span>
                {city} - {sublocation}
              </span>
            ) : (
              <span>{city}</span>
            )}

            {shortdesc && (
              <div
                className="custom_style"
                dangerouslySetInnerHTML={createMarkup(
                  shortString(shortdesc, 30)
                )}
              />
            )}
          </div>
        </Link>
      </div>
    </>
  );
};

export default AcademyCard;
