import React, { useState, useEffect, useCallback } from "react";
import BannerSlider from "../components/categorysingle/BannerSlider";
import Slot from "../components/categorysingle/Slot";
import VedioSlider from "../components/categorysingle/VedioSlider";

import CustomerRating from "../components/categorysingle/CustomerRating";
import AcademyMap from "../components/categorysingle/AcademyMap";
import Share from "../components/categorysingle/Share";
import Cart from "../components/categorysingle/Cart";
import { useParams, Route } from "react-router-dom";
import Comment from "../components/comments/Comment";
import { useDispatch, useSelector } from "react-redux";
import { fetchSportsSingle } from "../features/sports/sportSlice";
import HeadingLoader from "../common/loader/HeadingLoader";
import { STATUS } from "../services/Status";
import ParagraphLoader from "../common/loader/ParagraphLoader";
import { BannerLoader } from "../common/loader/BannerLoader";
import sideimg from "../assets/images/sideimg.png";
import Loader from "../common/loader/Loader";
import { fetchComments } from "../features/comments/commentSlice";
import RatingStar from "../components/categorysingle/RatingStar";
import {
  fetchAddons,
  fetchPackages,
  resetPackages,
  setselectedPackages,
} from "../features/cart/cartSlice";
import Step4 from "../components/categorysingle/steps/Step4";
import LoginError from "../common/commoncomponents/LoginError";
import AcademyGoogleMap from "../components/categorysingle/AcademyGoogleMap";
import LightBox from "../components/categorysingle/LightBox";
import { param } from "jquery";
import ModalBox from "../common/modal/ModalBox";

const CategorySingle = ({ name, type }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [open, setopen] = useState(false);

  const [commentState, setcommentState] = useState(false);
  const { modalvisible, modaldata } = useSelector((state) => state.modal);
  const {
    config: { ratingmsg },
  } = useSelector((state) => state.home);

  const { singleItem, status, Lightboximages, sportvedios } = useSelector(
    (state) => state.sport
  );

  const { isLoggedin } = useSelector((state) => state.user);

  const { packages } = useSelector((state) => state.cart);

  useEffect(() => {
    params.slug === "comments" ? setcommentState(true) : setcommentState(false);
  }, [params]);

  useEffect(() => {
    dispatch(fetchSportsSingle(params.id));
    dispatch(fetchComments(params.id));
  }, [params.id]);

  useEffect(() => {
    if (singleItem) {
      dispatch(fetchPackages(singleItem?.sport_id));
      dispatch(fetchAddons(singleItem?.sport_id));
    }

    dispatch(setselectedPackages(0));
    dispatch(resetPackages());
  }, [type, params.id, singleItem]);

  const openCloseSidebar = () => {
    document.getElementById("mySidebar").classList.toggle("open");
    document.getElementById("main").classList.toggle("open");
    document.getElementById("sidebaroverlay").classList.toggle("sidebar");

    document.body.classList.toggle("lock-scroll");
  };

  const removeSidebar = () => {
    document.getElementById("mySidebar").classList.remove("open");
    document.getElementById("main").classList.remove("open");
    document.getElementById("sidebaroverlay").classList.remove("sidebar");

    document.body.classList.remove("lock-scroll");
  };
  function createMarkup() {
    return { __html: singleItem?.longdesc };
  }

  function createMarkupRating(ratingmsg) {
    return { __html: ratingmsg };
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {status === STATUS.LOADING ? (
        <section>
          <div className="container">
            <Loader />
          </div>
        </section>
      ) : (
        <section>
          <LightBox />
          {Lightboximages && <BannerSlider images={Lightboximages} />}
        </section>
      )}

      <section>
        <div className="container py-5 single_txt">
          <div className="row">
            {/* //first div */}
            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
              <Route path={`/academy/${params.id}/comments`}>
                <Comment academy_id={params.id} />
              </Route>
              {!commentState && (
                <>
                  <div>
                    {status === STATUS.LOADING ? (
                      <Loader />
                    ) : (
                      <>
                        <h3 className="font_call">{singleItem?.academyname}</h3>
                        <p style={{ color: "grey" }}>{singleItem?.address}</p>

                        <div dangerouslySetInnerHTML={createMarkup()} />
                      </>
                    )}
                  </div>

                  {/* //vedio slider here */}
                  {status != STATUS.LOADING && (
                    <>
                      {sportvedios && sportvedios.length > 0 && (
                        <>
                          <div className="text-center py-5">
                            <VedioSlider sportvedios={sportvedios} />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {status != STATUS.LOADING && (
                    <>
                      {packages && packages.length > 0 && (
                        <Slot type={type} id={params.id} slug={params.slug} />
                      )}
                      {/* {isLoggedin ? (
                        <>
                          {packages && packages.length > 0 && (
                            <Slot type={type} id={params.id} />
                          )}
                        </>
                      ) : (
                        <LoginError academy_id={params.id} slug={params.slug} />
                      )} */}
                    </>
                  )}

                  {/* <div className="text-center clearfix pt-4">
                    <p>
                      Talk to us! Schedule Appointment <br /> Instant Video
                      conference with the vendor
                    </p>
                  </div> */}
                </>
              )}

              {/* //slott */}
            </div>

            <div className="col-lg-4 col-md-6 col-sm-8 col-xs-8 col-11 mx-auto mt-5 mt-lg-0 d-none d-lg-block">
              <div className="right_sidebar">
                <RatingStar id={singleItem?.sport_id} />
                <CustomerRating id={params.id} />

                {/* <AcademyMap latlng={singleItem?.lat_lang} /> */}
                <AcademyGoogleMap
                  lat={singleItem?.lat_lang}
                  lng={singleItem?.lang}
                />

                <Share
                  fb={singleItem?.fburl}
                  twiter={singleItem?.twitterurl}
                  linkin={singleItem?.linkedinurl}
                  web={singleItem?.weburl}
                />
                <Cart />

                {/* <div className="img_div">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <img
                        src={sideimg}
                        className="img-fluid w-100"
                        alt="image-dummy"
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            {modalvisible && (
              <ModalBox>
                <>
                  <div
                    className="custom_style"
                    dangerouslySetInnerHTML={createMarkupRating(ratingmsg)}
                  />
                </>
                {/* <RatingTable modalData={modaldata} /> */}
              </ModalBox>
            )}

            <div className="sidebar py-0 d-block d-lg-none" id="mySidebar">
              <div
                className="overlay-sidebar"
                id="sidebaroverlay"
                onClick={removeSidebar}
              ></div>
              <div className="right_sidebar" id="mySidebar">
                <h4 className="text-right">
                  <a
                    href="#"
                    className="closebtn text-dark"
                    onClick={removeSidebar}
                  >
                    ×
                  </a>
                </h4>
                <RatingStar id={singleItem?.sport_id} />
                <CustomerRating id={params.id} />

                {/* <AcademyMap latlng={singleItem?.lat_lang} /> */}
                <AcademyGoogleMap
                  lat={singleItem?.lat_lang}
                  lng={singleItem?.lang}
                />

                <Share
                  fb={singleItem?.fburl}
                  twiter={singleItem?.twitterurl}
                  linkin={singleItem?.linkedinurl}
                  web={singleItem?.weburl}
                />
                <Cart />
              </div>
            </div>

            {status != STATUS.LOADING && (
              <div id="main" className="d-block d-lg-none">
                <button className="openbtn" onClick={openCloseSidebar}>
                  &#171; <br />
                  {/* <i className="fa fa-shopping-cart"></i>&nbsp;<i>&#47;</i> */}
                  <i className="fa fa-smile-o"></i>
                </button>
              </div>
            )}

            {/* //nextdiv */}
          </div>
        </div>
      </section>
    </>
  );
};

export default CategorySingle;
