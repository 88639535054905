import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Link,
  NavLink,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import Logo from "../../../assets/images/logo1.svg";
import { useDispatch, useSelector } from "react-redux";
import { setChangeCity, setDetect } from "../../../features/user/userSlice";
import {
  setPage,
  setHasMore,
  emptySportList,
  ResetState,
} from "../../../features/sports/sportSlice";
import {
  fetchSearchitems,
  resetsearchItems,
} from "../../../features/home/homeSlice";
import SearchLoader from "../../../common/loader/SearchLoader";
import SearchItem from "../SearchItem";
import { STATUS } from "../../../services/Status";
import { IMAGE_URL } from "../../../services/apiUrl";
import AfterLogin from "../AfterLogin";
import { toast } from "react-toastify";
const Navigation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);

  const [search, setsearch] = useState(false);
  const [searchkey, setSearchkey] = useState(null);

  const { alllocations, searchItems, searchstatus, config } = useSelector(
    (state) => state.home
  );
  const [locatSearch, setlocatSearch] = useState(alllocations);

  useEffect(() => {
    setlocatSearch(alllocations);
  }, [alllocations]);

  const searchLocation = (e) => {
    if (e.target.value.length > 1) {
      const filterLocation =
        locatSearch &&
        locatSearch.filter((location) =>
          location.toLowerCase().includes(e.target.value.toLowerCase())
        );

      setlocatSearch(filterLocation);
    } else {
      setlocatSearch(alllocations);
    }
  };
  const { city, isLoggedin, locationerror } = useSelector(
    (state) => state.user
  );
  const { cart } = useSelector((state) => state.cart);

  const handleInputBlur = (event) => {
    dispatch(resetsearchItems());
  };

  const detectLocation = (e) => {
    e.preventDefault();
    let city = e.currentTarget.id;

    dispatch(setChangeCity(city));

    // query.set(`?location=${city}`);
    document.querySelector(".closemodal").click();
    history.push(`/`);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      // Trigger the onChange event here
      //console.log("Enter key pressed. Value:", event.target.value);
      dispatch(setChangeCity(event.target.value));
    }
  };
  const detectCurrentLocation = (e) => {
    console.log("detect");
    e.preventDefault();
    locationerror
      ? toast("User denied Geolocation | Kindly Enable to Access Location")
      : dispatch(setDetect());
  };

  let activeStyle = {
    textDecoration: "underline",
  };

  let activeClassName = "underline";
  const searchHandleChange = (e) => {
    if (e.target.value.length >= 3) {
      setsearch(true);
      setSearchkey(e.target.value);
      dispatch(fetchSearchitems(e.target.value, city));
      // console.log(searchkey, "from search");
    } else {
      setsearch(false);
      setSearchkey(null);
      dispatch(resetsearchItems());
    }
  };
  return (
    <>
      <header className="header_shad" id="stickyhead">
        <nav className="navbar navbar-expand-lg navbar-light main_nav hide_nav">
          <div className="container nav_rel">
            <button
              type="button"
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#nav-values"
            >
              <span
                className="navbar-toggler-icon navbar-hamburger"
                id="navbar-hamburger"
              ></span>
            </button>

            <div>
              <Link to="/" className="nav-brand">
                {/* src={config?.imagec ? `${IMAGE_URL}${config?.imagec}` : Logo} */}
                <img
                  src={Logo}
                  width="160"
                  className="img-fluid nav-logo"
                  alt="Logo"
                />
              </Link>
            </div>

            <div
              className="collapse navbar-collapse navbar-collapse-lg justify-content-between nav_bg"
              id="nav-values"
            >
              <div className="header_search header_search_mob wi_300">
                <form
                  action="#"
                  method="post"
                  className="d-flex align-items-center"
                >
                  <div>
                    <div
                      id="header_location_type"
                      className="btn dropdown-toggle px-1"
                      data-bs-toggle="dropdown"
                    >
                      <i className="fa fa-map-marker"></i>
                      <input
                        type="text"
                        placeholder={city}
                        className="input-mini"
                        onChange={searchLocation}
                        onKeyDown={handleInputKeyPress}
                      />
                      <div
                        id="header_location_type_options"
                        className="dropdown-menu p-3 my-2"
                      >
                        <div className="d-flex drop_content">
                          <i className="fa fa-location-arrow pe-3"></i>
                          <p className="mb-0" onClick={detectCurrentLocation}>
                            Detect current location
                          </p>
                        </div>
                        <p className="drop_txt mb-0">Using GPS</p>
                        <Scrollbars autoHide autoHeight>
                          {locatSearch &&
                            locatSearch.length > 0 &&
                            locatSearch.map((location, i) => (
                              <>
                                <div
                                  className="d-flex drop_content location-drop"
                                  key={location}
                                  style={{ marginTop: "10px" }}
                                >
                                  <i className="fa fa-location-arrow pe-3"></i>
                                  <p
                                    className="drop_txt"
                                    style={{ marginLeft: "0px" }}
                                    id={location}
                                    onClick={detectLocation}
                                  >
                                    {location}
                                  </p>
                                </div>
                              </>
                            ))}
                        </Scrollbars>
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <div
                      className="btn dropdown-toggle sticky_search_dropdown d-flex py-0 w-100"
                      data-bs-toggle="dropdown"
                    >
                      <i className="fa fa-search"></i>
                      <input
                        type="text"
                        placeholder="Search here...."
                        name="search"
                        className="search1 w-100"
                        onChange={searchHandleChange}
                      />
                      <div className="dropdown-menu p-3">
                        <div className="">
                          <div className=" searchitems">
                            {searchstatus === STATUS.LOADING ? (
                              <>
                                <SearchLoader />
                                <SearchLoader />
                                <SearchLoader />
                                <SearchLoader />
                              </>
                            ) : (
                              <>
                                {searchItems && searchItems.length > 0 ? (
                                  <>
                                    {searchItems.map((item, i) => (
                                      <SearchItem
                                        item={item}
                                        key={i}
                                        setsearch={setSearchkey}
                                        search={setsearch}
                                      />
                                    ))}
                                  </>
                                ) : (
                                  <p className="no-content">No Content Found</p>
                                )}
                              </>
                            )}

                            {/* <SearchItem /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {/* {search && (
                  <div className="subpage__search">
                    <div className=" searchitems">
                      {searchstatus === STATUS.LOADING ? (
                        <>
                          <SearchLoader />
                          <SearchLoader />
                          <SearchLoader />
                          <SearchLoader />
                        </>
                      ) : (
                        <>
                          {searchItems && searchItems.length > 0 ? (
                            <>
                              {searchItems.map((item, i) => (
                                <SearchItem
                                  item={item}
                                  key={i}
                                  setsearch={setSearchkey}
                                  search={setsearch}
                                />
                              ))}
                            </>
                          ) : (
                            <p className="no-content">No Content Found</p>
                          )}
                        </>
                      )}

                      
                    </div>
                  </div>
                )} */}
              </div>

              <div className="d-block d-lg-none mb-3 mb-wi">
                <Link to="/login" className="login_btn login-btn1 mbl_login">
                  Login
                  <i className="fa fa-user"></i>
                </Link>
              </div>

              <ul
                className="navbar-nav contact_link mb-wi px-2"
                style={{ gap: "10px" }}
              >
                <li className="nav-item ">
                  <NavLink
                    to="/sports"
                    className="nav-link "
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    Sports
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/gym-fitness"
                    className="nav-link "
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    Gym & Fitness
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/coach"
                    className="nav-link "
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    Experts
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    to="/blogs"
                    className="nav-link "
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    Analytics
                  </NavLink>
                </li>
              </ul>
              <div className="d-flex align-items-center mb-wi position-relative">
                {isLoggedin ? (
                  <AfterLogin type="header" />
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="login_btn login-btn1 d-none d-lg-block"
                    >
                      Login
                      <i className="fa fa-user"></i>
                    </Link>
                  </>
                )}

                {/* <a
                  href={config?.twitterurl}
                  className="social_icon social_icon1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-twitter"></i>
                </a> */}
                {/* <a
                  href={config?.youtubeurl || "https://www.youtube.com/"}
                  className="social_icon social_icon1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-youtube"></i>
                </a> */}
                {/* <a
                  href={config?.fburl}
                  className="social_icon social_icon1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-facebook"></i>
                </a> */}
                {/* <a
                  href={config?.instagramurl}
                  className="social_icon social_icon1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a> */}
                {isLoggedin && (
                  <Link to="/checkout" className="">
                    {/* <i className="fa cart-icon-sub">&#xf07a;</i> */}
                    <span className="badge badge-warning" id="lblCartCount">
                      {cart && cart.length}
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>

        <div className="header_search mob_header_search mob_search container">
          <div className="mobi_location">
            <button
              type="button"
              className="btn"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
            >
              <i className="fa fa-map-marker"></i>&nbsp;&nbsp;{city}
            </button>
            <div className="modal fade" id="myModal" role="dialog">
              <div className="modal-dialog">
                {/* <!-- Modal content--> */}
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Select Location</h4>
                    <button
                      type="button"
                      className="closemodal"
                      data-bs-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <form action="#">
                      <i className="fa fa-search"></i>
                      <input
                        type="text"
                        placeholder="Search here...."
                        name="search"
                        className="mobi_icon"
                        onChange={searchLocation}
                      />
                      <Scrollbars autoHide autoHeight>
                        {locatSearch &&
                          locatSearch.length > 0 &&
                          locatSearch.map((location, i) => (
                            <>
                              <div
                                className="d-flex drop_content location-drop"
                                key={location}
                                style={{ marginTop: "10px" }}
                              >
                                <i className="fa fa-location-arrow pe-3"></i>
                                <p
                                  className="drop_txt"
                                  style={{ marginLeft: "0px" }}
                                  id={location}
                                  onClick={detectLocation}
                                >
                                  {location}
                                </p>
                              </div>
                            </>
                          ))}
                      </Scrollbars>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobi_search">
            {/* {isLoggedin && (
              <Link
                to="/checkout"
                className="btn sear_btn"
                style={{ marginRight: "5px" }}
              >
                <i className="fa cart-icon-1">&#xf07a;</i>
                <span className="badge badge-warning" id="lblCartCount-mobile">
                  {cart && cart.length}
                </span>
              </Link>
            )} */}
            <button
              type="button"
              className="btn sear_btn"
              data-bs-toggle="modal"
              data-bs-target="#myModal2"
            >
              <i className="fa fa-search"></i>
            </button>
            <div className="modal fade" id="myModal2" role="dialog">
              <div className="modal-dialog">
                {/* <!-- Modal content--> */}
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Search Academies</h4>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <form action="#" method="post">
                      <i className="fa fa-search"></i>
                      <input
                        type="text"
                        name="search2"
                        id="sub_search"
                        placeholder="Search here...."
                        className="mobi_icon"
                        onChange={searchHandleChange}
                      />
                      {/* <!-- </fieldset> --> */}
                      {search && (
                        <div className=" searchitems-mobile">
                          {searchstatus === STATUS.LOADING ? (
                            <>
                              <SearchLoader />
                              <SearchLoader />
                            </>
                          ) : (
                            <>
                              {searchItems && searchItems.length > 0 ? (
                                <>
                                  {searchItems.map((item, i) => (
                                    <SearchItem
                                      item={item}
                                      key={i}
                                      setsearch={setSearchkey}
                                      search={setsearch}
                                    />
                                  ))}
                                </>
                              ) : (
                                <p className="no-content">No Content Found</p>
                              )}
                            </>
                          )}

                          {/* <SearchItem /> */}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navigation;
