import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import classload from "../../components/login/Login.module.css";
import {
  RemoveFamilymember,
  setIsModalVisible,
} from "../../features/user/userSlice";
import classes from "./Familymember.module.css";

const DeleteFamilyMember = () => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState([]);
  const { deletedata } = useSelector((state) => state.user);
  const modalOverlayHandler = (e) => {
    if (e.target.classList.contains("overlay_bg")) {
      dispatch(setIsModalVisible(false));
    }
  };

  const deleteMember = (e) => {
    e.preventDefault();

    setloading(true);
    axios
      .post(`deletefamilymember/${deletedata?.id}`)
      .then((res) => {
        setloading(false);
        dispatch(RemoveFamilymember(deletedata?.id));
        dispatch(setIsModalVisible(false));
      })
      .catch((errors) => {
        setloading(false);
        errors.response.data.errors && seterror(errors.response.data.errors);
      });
  };
  return (
    <>
      <div className={classes.overlay_bg} onClick={modalOverlayHandler}>
        <div className={`${classes.details}  bg-white`}>
          <button
            type="button"
            className={`${classes.modal_close_btn} flex flex-center fs-14`}
            onClick={() => dispatch(setIsModalVisible(false))}
          >
            <i className="fa fa-times"></i>
          </button>
          <h5>
            Are You Sure Delete This Family Member {deletedata?.fmembername}
          </h5>

          {error.length !== 0 && error?.buyed ? (
            <>
              <p className="text-danger">{error.buyed[0]}</p>
              <ul style={{ listStyleType: "none" }}>
                {error?.academy.map((ac, i) => (
                  <li key={i}>
                    {i + 1}.{ac}
                  </li>
                ))}
              </ul>
            </>
          ) : null}
          <div className={classes.deletebutton}>
            <button
              className="btn text-white bg-dark"
              onClick={() => dispatch(setIsModalVisible(false))}
            >
              Cancel
            </button>
            {loading ? (
              <CircularProgress className={classload.progressbar} />
            ) : (
              <button
                className="btn text-white bg-danger"
                onClick={deleteMember}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteFamilyMember;
