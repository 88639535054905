import React, { useEffect, useState } from "react";
import TopBanner from "../../common/banner/TopBanner";
import BlogList from "./BlogList";
import useFetch from "../../hooks/useFetch";
import Loader from "../../common/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogs } from "../../features/home/homeSlice";
import { useHistory, useLocation } from "react-router-dom";
const BlogContent = () => {
  const { isLoggedin } = useSelector((state) => state.user);
  const { blogs, status } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const category = query.get("category")
    ? query.get("category").split(",")
    : [];
  const [Category, setCategory] = useState(category);
  let url;

  url = `blogs?category=${Category}`;

  useEffect(() => {
    dispatch(fetchBlogs(url));
  }, [Category]);

  const OpenOrClose = () => {
    document.getElementById("filterdiv").classList.toggle("open");
    document.getElementById("filterbtn").classList.toggle("open");
    document.body.classList.toggle("lock-scroll");
  };

  const ApplyFilter = (data) => {
    console.log(data);
    setCategory(data.category);

    let urldemo = `blogs?category=${data.category}`;

    history.push(urldemo);
    OpenOrClose();
  };

  const ClearFilter = (isclear = true) => {
    let clearurl = `blogs`;

    history.push(clearurl);

    setCategory([]);
    isclear && OpenOrClose();
  };

  const ClearSmFilter = (id) => {
    var cat = Category.filter((item) => item !== id);
    let clearurl = `blogs?${cat}`;
    setCategory(cat);
    history.push(clearurl);
  };

  const {
    data: { aboutuspages },
    loading,
  } = useFetch(`aboutuspage`);

  let step = 1;

  const blogData = () => {
    return loading ? (
      <Loader />
    ) : blogs && blogs.length > 0 ? (
      <BlogList
        blog={blogs}
        Category={Category}
        setCategory={setCategory}
        applyfilter={ApplyFilter}
        clearfilter={ClearFilter}
        ClearSmFilter={ClearSmFilter}
      />
    ) : (
      <p className="text-center">No Blogs Available</p>
    );
  };
  return (
    <>
      <TopBanner
        // title="Blog"
        loading={loading}
        image={aboutuspages?.blogbanner}
      />

      <section>
        <div className="container">
          <div className="banner_spacer"></div>

          {isLoggedin ? (
            blogData()
          ) : (
            <>
              {" "}
              <div className="blog__content" style={{ textAlign: "center" }}>
                <button
                  style={{ fontSize: "16px" }}
                  className="common-btn"
                  onClick={() => history.push("/login")}
                >
                  Login to View Analytics
                </button>
              </div>
              <div className="banner_spacer"> </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default BlogContent;
