import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import SnackbarNotification from "../../../common/loader/SnackbarNotification";
import { useDispatch } from "react-redux";
import { setSnackabar } from "../../../features/home/homeSlice";
import axios from "axios";
import { setUserOffers } from "../../../features/user/userSlice";

const SwitchTab = ({ checkedinfoEmail, checkedinfoWhatsapp, type }) => {
  const dispatch = useDispatch();

  const SwitchChange = (event) => {
    let offer = event.target.name;

    let type = event.target.id;
    let action = event.target.checked ? 1 : 0;

    axios
      .post(`offernotification/${type}/${offer}/${action}`)
      .then((res) => {
        dispatch(setSnackabar(true));
        dispatch(setUserOffers([offer, type, action]));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setSnackabar(false));
      });
  };
  return (
    <>
      <SnackbarNotification />
      <div className="switch-items">
        <div className="switch-item">
          <label>Email</label>

          <Switch
            defaultChecked={checkedinfoEmail}
            name="email"
            id={type}
            onChange={SwitchChange}
            disabled={type === "order"}
          />
        </div>
        <div className="switch-item">
          <label>Whatsapp</label>
          <Switch
            defaultChecked={checkedinfoWhatsapp}
            name="whatsapp"
            id={type}
            onChange={SwitchChange}
          />
        </div>
      </div>
    </>
  );
};

export default SwitchTab;
