import React from "react";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedDate,
  setTermchecked,
} from "../../../features/cart/cartSlice";
import moment from "moment";
import ModalBox from "../../../common/modal/ModalBox";
import { setIsTermsModalVisible } from "../../../features/modal/modalSlice";
import { CurrentDateFormat } from "../../../services/Helper";
export const Step4 = () => {
  const [startDate, setStartDate] = useState(moment(new Date()).toDate());

  const today = new Date();

  const {
    selecteddate,
    selectedPackage,
    packages,
    selectedslot,
    selectedDuration,
  } = useSelector((state) => state.cart);

  const { singleItem, termschecked } = useSelector((state) => state.sport);

  const { termsmodal } = useSelector((state) => state.modal);

  const [dateSeparate, setdateSeparate] = useState({
    month: moment(selecteddate).format("MMM"),
    date: moment(selecteddate).format("D"),
    day: moment(selecteddate).format("dddd"),
  });

  useEffect(() => {
    setdateSeparate({
      month: moment(selecteddate).format("MMM"),
      date: moment(selecteddate).format("D"),
      day: moment(selecteddate).format("dddd"),
    });
  }, [selecteddate]);

  const dispatch = useDispatch();

  const dateChanged = (date) => {
    dispatch(setSelectedDate(date.toISOString()));
    setStartDate(moment(new Date()).toDate());
    setSelectedDate(date.toISOString());
  };

  const handlechange = (e) => {
    const checked = e.target.checked;
    checked ? dispatch(setTermchecked(true)) : dispatch(setTermchecked(false));
  };

  const modalVisible = (e) => {
    e.preventDefault();
    dispatch(setIsTermsModalVisible(true));
  };

  function createMarkup(text) {
    return { __html: text };
  }

  const CheckisFixedPackage = () => {
    let getpackage = packages.filter((check) => check.id === selectedPackage);

    if (getpackage[0]?.isfixed === 1) {
      dispatch(setSelectedDate(getpackage[0]?.packagestdate));
      return true;
    } else {
      return false;
    }
  };

  const getPackageFullInformation = () => {
    let packageInfo = packages.find((check) => check.id === selectedPackage);

    let dateFormat = `${moment(packageInfo?.packagestdate).format(
      "MMM-D-Y"
    )} (${moment(packageInfo?.packagestdate).format("dddd")})`;

    return dateFormat;
    console.log(packageInfo, "info");
  };

  const calculateMaxDate = () => {
    const maxDate =
      selectedDuration == "Day"
        ? moment().add(2, "weeks").toDate()
        : moment().add(2, "months").toDate();
    return maxDate;
  };

  useEffect(() => {
    CheckisFixedPackage();
    getPackageFullInformation();
  }, [selectedPackage]);
  if (selectedslot === "")
    return <p className="text-danger">Please Select Any Slot</p>;
  return (
    <>
      {termsmodal && (
        <ModalBox>
          <div className="terms-sections">
            <p>{singleItem?.termssectionheading}</p>

            <p>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  singleItem?.termssection1
                )}
              />
            </p>
            <p>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  singleItem?.termssection2
                )}
              />
            </p>
          </div>
        </ModalBox>
      )}
      {CheckisFixedPackage() ? (
        <>
          <div className="text-center">
            <p>
              You have selected a Fixed Duration Package. Package Start Date
            </p>
            {getPackageFullInformation()}
          </div>
          <div className="terms-check mt-5">
            <input
              type="checkbox"
              onChange={handlechange}
              style={{ position: "unset", opacity: 1 }}
            />
            <small className="terms-head" onClick={modalVisible}>
              Accept Our <span className="blue-line">Terms and Conditions</span>
            </small>
          </div>
          <p className="term-success remove text-center text-danger">
            Please Accept Our Terms and Conditions
          </p>
        </>
      ) : (
        <>
          <fieldset>
            <div className="form-card step4">
              <div className="row  px-sm-0 px-md-5 alt_font align-items-center">
                <div className="col-sm-6 mb-4 mb-sm-0">
                  <div id="Datepicker1" className="font-weight-bold">
                    <DatePicker
                      inline
                      selected={moment(selecteddate).toDate()}
                      onChange={(date) => dateChanged(date)}
                      minDate={new Date()}
                      maxDate={calculateMaxDate()}
                    />
                  </div>
                  {/* <button type="button" className="btn float-right nxt_btn">
                    &#171;
                  </button> */}
                </div>
                <div className="col-sm-6">
                  <div className="dis_date text-center text-white py-4">
                    <h6>{dateSeparate?.day}</h6>
                    <h1>{dateSeparate?.date}</h1>
                    <h4>{dateSeparate?.month}</h4>
                  </div>
                </div>
              </div>

              <div className="terms-check mt-5">
                <input
                  type="checkbox"
                  onChange={handlechange}
                  style={{ position: "unset", opacity: 1 }}
                />
                <small className="terms-head" onClick={modalVisible}>
                  Accept Our{" "}
                  <span className="blue-line">Terms and Conditions</span>
                </small>
              </div>
              <p className="term-success remove text-center text-danger">
                Please Accept Our Terms and Conditions
              </p>
            </div>
          </fieldset>
        </>
      )}
    </>
  );
};
export default Step4;
