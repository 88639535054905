import React, { useState, useEffect } from "react";
import TopBanner from "../common/banner/TopBanner";
import img from "../assets/images/Captureform.PNG";
import Signin from "../components/login/Signin";
import Signup from "../components/login/Signup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGetuser,
  setClearRegisterUser,
  setDemouser,
  setOtpget,
  setRegisterUser,
} from "../features/user/userSlice";
import SocialMedialogin from "../components/login/SocialMedialogin";
import useFetch from "../hooks/useFetch";
import { BASE_URL, IMAGE_URL } from "../services/apiUrl";
import Loader from "../common/loader/Loader";
import { useLocation, useHistory } from "react-router-dom";
import Mainlogo from "../assets/images/logosp.png";

const LoginPage = () => {
  const [Loginpage, setLoginpage] = useState(true);
  const [Loading, setLoading] = useState(false);
  const [mailerror, setmailerror] = useState("");
  const [getOtp, setgetOtp] = useState(false);
  const [otpVerified, setotpVerified] = useState(false);
  const [error, seterror] = useState([]);
  const dispatch = useDispatch();
  const path = useLocation();
  const history = useHistory();
  const { Otpget, SocialLoginprofile, Otpverified } = useSelector(
    (state) => state.user
  );
  const { config } = useSelector((state) => state.home);

  useEffect(() => {
    seterror([]);
    setLoading(false);
  }, [Loginpage]);

  const {
    data: { aboutuspages },
    loading: abload,
    error: aberror,
  } = useFetch(`${BASE_URL}aboutuspage`);

  const LoginRegisterHandler = (data) => {
    setLoading(true);
    console.log(data);
    // setLoading(true);
    // setgetOtp(true);
    // console.log(data, "from login");
    // setLoading(false);

    let url = Loginpage ? "login" : "register";
    axios
      .get("sanctum/csrf-cookie")
      .then(() => {
        axios
          .post(url, data)
          .then((res) => {
            if (res?.data?.demo_user) {
              localStorage.setItem("auth-token", res.data.token);
              dispatch(setClearRegisterUser());
              dispatch(setDemouser(true));
              dispatch(fetchGetuser());
              history.push("/");
            } else {
              dispatch(setRegisterUser(res.data));
              setLoading(false);
              dispatch(setOtpget(true));
            }

            // setgetOtp(true);
          })
          .catch((errors) => {
            if (errors.response.data.errors) {
              seterror(errors.response.data.errors);
            } else {
              setmailerror(
                "Whoops !! Something Went Wrong , May be You are Entered Invalid Email"
              );
            }

            setLoading(false);
            dispatch(setOtpget(false));
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      {/* {SocialLoginprofile ? (
        <TopBanner title="Registration" />
      ) : (
        <>
          {abload ? (
            <Loader />
          ) : (
            <TopBanner
              title={Loginpage ? "login" : "Register"}
              image={
                Loginpage ? aboutuspages?.loginbanner : aboutuspages?.regbanner
              }
            />
          )}
        </>
      )} */}
      <section className="totalpage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 loginadjust mt-5 mb-5">
              <div className="row">
                {!Otpget && (
                  <div className="col-md-6 sidepage">
                    <h2>{Loginpage ? "Login" : "Create Account"}</h2>
                    <p className="lettersize">
                      <span>
                        or{" "}
                        <span
                          className="createclr"
                          onClick={() => setLoginpage(!Loginpage)}
                        >
                          {Loginpage ? "Create Account" : "Login"}
                        </span>
                      </span>
                    </p>

                    <hr />
                  </div>
                )}
                {Otpget && !Otpverified && (
                  <div className="col-md-6 sidepage">
                    <h2>OTP Verification</h2>

                    <hr />
                  </div>
                )}

                {/* <div
                  className="col-md-6 logoimg"
                  style={{ textAlign: "right" }}
                >
                  <img
                    className="sideimg"
                    src={
                      config?.imagec
                        ? `${IMAGE_URL}${config?.imagec}`
                        : Mainlogo
                    }
                  />
                </div> */}

                {/* <hr /> */}

                {/* signin form */}
                {Loginpage ? (
                  <Signin
                    setLoginpage={setLoginpage}
                    Loginpage={Loginpage}
                    onSubmitHandler={LoginRegisterHandler}
                    errors={error}
                    loading={Loading}
                    SocialLoginprofile={SocialLoginprofile}
                    getOtp={Otpget}
                    type="login"
                    path={path}
                  />
                ) : (
                  <Signup
                    setLoginpage={setLoginpage}
                    Loginpage={Loginpage}
                    onSubmitHandler={LoginRegisterHandler}
                    errors={error}
                    loading={Loading}
                    getOtp={Otpget}
                    SocialLoginprofile={SocialLoginprofile}
                    type="register"
                    path={path}
                    mailerror={mailerror}
                    setmailerror={setmailerror}
                  />
                )}

                {!Otpget && (
                  <p className="signtext">
                    <span>By Signing in, </span>
                    <span className="policycenter">
                      <span>I accept the </span>

                      <a href="/termsand-condition" target="_blank">
                        Terms & Conditions
                      </a>
                      <span> & </span>
                      <a href="/privacypolicy" target="_blank">
                        Privacy Policy
                      </a>
                    </span>
                  </p>
                )}
              </div>
              {!Otpget && <SocialMedialogin path={path} />}
              {/* social media login */}
            </div>
            <div className="col-md-6 mb-5">
              {abload ? (
                <Loader />
              ) : (
                <img
                  className="loginimg"
                  src={`${IMAGE_URL}${
                    Loginpage
                      ? aboutuspages?.loginbanner
                      : aboutuspages?.regbanner
                  }`}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
