import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import classes from "../../components/login/Login.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import Rating from "@mui/material/Rating";
import { setIsTermsModalVisible } from "../../features/modal/modalSlice";
import { fetchComments } from "../../features/comments/commentSlice";

const CommentForm = ({
  academy_id,
  setshowCommentform,
  commentdata = null,
  type = "create",
}) => {
  const { editcomment } = useSelector((state) => state.comment);
  const [rating, setrating] = useState(2);
  const [comment, setcomment] = useState("");
  const [error, seterror] = useState([]);
  const [showSuccess, setshowSuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const dispatch = useDispatch();
  const ratingChanged = (newRating) => {
    setrating(newRating);
  };

  useEffect(() => {
    if (type == "edit") {
      setrating(editcomment?.rating ? editcomment?.rating : 0);
      setcomment(editcomment?.comments);
    }
  }, [type, editcomment]);

  const addRemove = () => {
    // console.log("yes running");
    setshowSuccess(true);
    //document.querySelector(".comment-success").classList.remove("remove");

    setTimeout(() => {
      setshowSuccess(false);
    }, 3000);
  };
  const SubmitComment = (e) => {
    e.preventDefault();

    setloading(true);

    let url =
      type == "edit"
        ? `editcomment/${editcomment?.id}`
        : `addcomment/${academy_id}`;
    axios
      .post(`${url}`, {
        comments: comment,
        rating: rating,
        member_id: user.id,
      })
      .then((res) => {
        setrating(0);
        setloading(false);
        setcomment("");

        seterror([]);
        addRemove();
        if (type == "edit") {
          setTimeout(() => {
            dispatch(setIsTermsModalVisible(false));
            dispatch(fetchComments(editcomment?.academy_id));
          }, 2000);
        } else {
          setTimeout(() => {
            setshowCommentform(false);
            dispatch(fetchComments(academy_id));
          }, 2000);
        }
      })
      .catch((error) => {
        setloading(false);
        if (error.response.data.errors) seterror(error.response.data.errors);
      });
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h3 className="font_call">
            {type == "edit" ? "Edit" : "Write"} your Comment
          </h3>

          <form className="cmt_form">
            <div className="comment-area py-4">
              {" "}
              <textarea
                className="form-control"
                rows="8"
                placeholder="Sample content goes here"
                value={comment}
                onChange={(e) => setcomment(e.target.value)}
              ></textarea>{" "}
              {error.length !== 0 && error.comments ? (
                <p className={classes.validation}>{error.comments[0]}</p>
              ) : null}
            </div>
            {showSuccess && (
              <p className="text-success  comment-success">
                Successfully Submitted Your Comment{" "}
              </p>
            )}

            <div className="d-md-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center">
                <h4 className="font_call rating_heading">Submit Your Rating</h4>
                <div className="rating">
                  {/* <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={24}
                    value={rating}
                    edit={true}
                    activeColor="#ffd700"
                  /> */}
                  <Rating
                    name="simple-controlled"
                    value={rating}
                    onChange={(event, newValue) => {
                      setrating(newValue);
                    }}
                  />
                </div>
              </div>

              <div className="text-center text-md-right pt-4 pt-md-0">
                {loading ? (
                  <>
                    <CircularProgress className={classes.progressbar} />
                  </>
                ) : (
                  <button className="btn send" onClick={SubmitComment}>
                    Submit <i className="fa fa-long-arrow-right ml-1"></i>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CommentForm;
