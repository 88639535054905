import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import homeReducer from "../features/home/homeSlice";
import sportReducer from "../features/sports/sportSlice";
import cartReducer from "../features/cart/cartSlice";
import commentReducer from "../features/comments/commentSlice";
import modalReducer from "../features/modal/modalSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    home: homeReducer,
    sport: sportReducer,
    cart: cartReducer,
    comment: commentReducer,
    modal: modalReducer,
  },
});
export default store;
