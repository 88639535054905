import React, { useEffect, useState } from "react";
import info from "../assets/images/info.png";
import info_active from "../assets/images/info_active.png";
import head_info from "../assets/images/head_info.png";
import Calendar from "react-calendar";
import Popup from "../components/calender/Popup";
import { useHistory, useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { CurrentDateFormat } from "../services/Helper";
import { useSelector } from "react-redux";
const AttendanceCalender = (props) => {
  const Loadingprogress = props.Loadingprogress;
  const { user, familymembers, status, demo_user } = useSelector(
    (state) => state.user
  );
  const params = useParams();
  const { data, loading, error } = useFetch(
    `getAttendanceSheet/${params.order_item}/2024`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    Loadingprogress(100);
  }, []);
  const [dateState, setDateState] = useState(new Date());
  const [modalShow, setModalShow] = React.useState(false);
  const [userinfo, Setunserinfo] = useState({});

  useEffect(() => {
    Setunserinfo({
      user_id: user?.id,
      order_item_id: params.order_item,
      date: CurrentDateFormat(),
      status: null,
    });
  }, [user, params?.order_item]);

  const changeDate = (e) => {
    setDateState(e);
  };
  const toggleModel = () => {
    setModalShow(!modalShow);
  };
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  const onClickDay = (value, event) => {
    if (isToday(value)) {
      if (data && data?.data?.length > 0) {
        const localDate = new Date(value);

        const utcDate = new Date(
          localDate.getTime() - localDate.getTimezoneOffset() * 60 * 1000
        );

        const isoDateString = utcDate.toISOString().split("T")[0];
        const leave = data.data?.find((leave) => leave.date === isoDateString);

        Setunserinfo((prevUserInfo) => ({
          ...prevUserInfo, // Copy the previous state
          status: leave ? leave.status : null, // Add or update the 'status' property
        }));

        toggleModel();
      } else {
        toggleModel();
      }
    }
  };
  if (modalShow) {
    document.body.classList.add("active-open");
  } else {
    document.body.classList.remove("active-open");
  }
  // const data = [
  //   { date: "2023-12-01", status: "absent" },
  //   { date: "2023-12-03", status: "leave" },
  //   { date: "2023-12-05", status: "present" },
  //   { date: "2023-12-12", status: "leave" },
  //   { date: "2023-12-06", status: "leave" },
  //   { date: "2023-12-10", status: "present" },
  //   { date: "2023-12-12", status: "absent" },
  //   { date: "2023-12-13", status: "present" },
  //   { date: "2023-12-15", status: "present" },
  //   { date: "2023-12-16", status: "leave" },
  //   { date: "2023-12-19", status: "leave" },
  //   { date: "2023-12-29", status: "leave" },

  // ];
  const LeaveContent = ({ type, isCurrentDate, children, holidaytext }) => {
    return (
      <>
        <div>{isCurrentDate && <p className="today_text">Today</p>}</div>
        {children}
        <div
          className={`schedule_container ${type
            .toLowerCase()
            .replace(/\s/g, "")}`}
        >
          <div className="track_schedule">
            <p className="track_text">{holidaytext ? holidaytext : type}</p>
          </div>
          <div>
            {type === "Leave" && (
              <img src={info} alt="info" width="20px" height="20px" />
            )}
            {isCurrentDate && (
              <img src={info_active} alt="info" width="20px" height="20px" />
            )}
          </div>
        </div>
      </>
    );
  };

  const tileContent = ({ date }) => {
    const { status, holiday } = getTileClassName({ date });

    // const isCurrentDate = moment(date).isSame(moment(dateState), "day");
    const isToday = (date) => {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    };

    if (status) {
      let type;
      let holidaytext = null;

      switch (status) {
        case "absent":
          type = "Absent";
          break;
        case "present":
          type = "Present";
          break;
        case "leave":
          type = "Leave";
        case "No Class":
          type = "No Class";
          break;
        case "Not Taken":
          type = "Not Taken";
          break;
        case "Holiday":
          type = "Holiday";
          holidaytext = holiday;
          break;
        default:
          type = "";
      }

      return (
        <LeaveContent
          type={type}
          holidaytext={holidaytext}
          isCurrentDate={isToday(date)}
        >
          {/* {isCurrentDate && <p className="today_text">Today</p>} */}
        </LeaveContent>
      );
    }
  };
  const getTileClassName = ({ date }) => {
    const localDate = new Date(date);

    const utcDate = new Date(
      localDate.getTime() - localDate.getTimezoneOffset() * 60 * 1000
    );

    const isoDateString = utcDate.toISOString().split("T")[0];
    if (data) {
      const leave = data.data?.find((leave) => leave.date === isoDateString);
      if (leave) {
        // console.log(date, "dates");
        return leave.status === "Absent"
          ? { status: "absent", holiday: leave.holiday }
          : leave.status === "Present"
          ? { status: "present", holiday: leave.holiday }
          : leave.status === "No Class"
          ? { status: "No Class", holiday: leave.holiday }
          : leave.status === "Not Taken"
          ? { status: "Not Taken", holiday: leave.holiday }
          : leave.status === "Holiday"
          ? { status: "Holiday", holiday: leave.holiday }
          : { status: "N/A", holiday: false };
      }
    }

    return { status: "working", holiday: false };
  };
  return (
    <>
      <div>
        <Popup
          show={modalShow}
          userinfo={userinfo}
          onHide={() => setModalShow(false)}
        />
      </div>
      <div className="container">
        <div className="row">
          <ul className="marked_label">
            <li style={{ color: "#00ba34", fontWeight: 500, fontSize: "15px" }}>
              Marked Present
            </li>
            <li style={{ color: "#0085ff", fontWeight: 500, fontSize: "15px" }}>
              Working
            </li>
            <li style={{ color: "#e92c2c", fontWeight: 500, fontSize: "15px" }}>
              Absent
            </li>
            <li style={{ color: "#ff9f2d", fontWeight: 500, fontSize: "15px" }}>
              No Class
            </li>
          </ul>
        </div>

        <div
          className="member_information"
          style={{
            padding: "20px",
          }}
        >
          <div className="row">
            <div className="col-md-3">
              Family Member Name :{" "}
              {data && data?.result ? data?.result?.fmembername : "N/A"}{" "}
            </div>
            <div className="col-md-3">
              Member Name :{" "}
              {data && data?.result ? data?.result?.membername : "N/A"}{" "}
            </div>
            <div className="col-md-3">
              Academy Name :{" "}
              {data && data?.result ? data?.result?.academyname : "N/A"}{" "}
            </div>
            <div className="col-md-3">
              Package Name :{" "}
              {data && data?.result ? data?.result?.packagename : "N/A"}{" "}
            </div>
            <br />
            <div className="col-md-3 mt-3">
              Slot Name :{" "}
              {data && data?.result ? data?.result?.slotname : "N/A"}{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="calendar_head container">
        <div className="row">
          <Calendar
            value={dateState}
            onChange={changeDate}
            onClickDay={onClickDay}
            tileClassName={(date) => getTileClassName(date)}
            tileContent={(date) => tileContent(date)}
            maxDate={new Date()}
            // minDate={new Date()}
          />
        </div>
      </div>
    </>
  );
};

export default AttendanceCalender;
