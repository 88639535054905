import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";

const firebaseConfiglocal = {
  apiKey: "AIzaSyAJlxPPoEi2GLlqfYjpb8r_25tYMlSrsvY",
  authDomain: "fir-c85ac.firebaseapp.com",
  projectId: "fir-c85ac",
  storageBucket: "fir-c85ac.appspot.com",
  messagingSenderId: "352867867834",
  appId: "1:352867867834:web:5cb9c3b7b92c94c5b27683",
};

const firebaseConfig = {
  apiKey: "AIzaSyDGflkDLw4juWNqpQslAffUA2MrwvqlFLM",

  authDomain: "sportzlete.firebaseapp.com",

  projectId: "sportzlete",

  storageBucket: "sportzlete.appspot.com",

  messagingSenderId: "503960772805",

  appId: "1:503960772805:web:a3581eef9d057f79b050ac",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");
export const google = new GoogleAuthProvider();
export const facebook = new FacebookAuthProvider();
export default app;
