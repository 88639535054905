import React, { useState } from "react";
import { IMAGE_URL } from "../../services/apiUrl";
import profileimage from "../../assets/images/person.png";
import Rating from "@mui/material/Rating";
import { useDispatch, useSelector } from "react-redux";
import { setIsTermsModalVisible } from "../../features/modal/modalSlice";
import ModalBox from "../../common/modal/ModalBox";
import CommentForm from "./CommentForm";
import { seteditComment } from "../../features/comments/commentSlice";

const CommentCard = ({ comment }) => {
  const { user } = useSelector((state) => state.user);
  const { termsmodal } = useSelector((state) => state.modal);
  const [editcomment, seteditcomment] = useState({});
  const dispatch = useDispatch();
  const Findimage = (string) => {
    let image;
    if (string === null) {
      image = profileimage;
    } else if (string.includes("base64")) {
      image = string;
    } else if (string.includes("https")) {
      image = string;
    } else {
      image = `${IMAGE_URL}${string}`;
    }

    return image;
  };

  //console.log(comment);
  const openCommenedit = (commentedit) => {
    console.log(commentedit);
    seteditcomment(commentedit);
    dispatch(seteditComment(commentedit));
    dispatch(setIsTermsModalVisible(true));
  };
  return (
    <>
      {termsmodal && (
        <ModalBox>
          <CommentForm type="edit" />
        </ModalBox>
      )}

      <div className="row py-1" key={comment?.id}>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="cmt_box">
            <div className="d-md-flex">
              <div className="col-md-2 col-12 text-center mx-auto">
                <img
                  src={Findimage(comment?.displayimg)}
                  className="img-fluid cmt_profile"
                />
              </div>
              <div className="col-md-10 mx-auto">
                <div className="d-flex justify-content-between py-3 py-md-0">
                  <h6 className="cmt_usr_name">
                    {comment?.membername}{" "}
                    <span className="text-warning" style={{ fontSize: "11px" }}>
                      {user?.id === comment?.member_id && "You"}
                      {user?.id === comment?.member_id && (
                        <>
                          {comment?.status != 0 ? (
                            <i
                              className="fa fa-edit ms-2 edit_comment"
                              onClick={() => openCommenedit(comment)}
                            ></i>
                          ) : (
                            <span className="ms-2">
                              Your Coment Not Yet Approved
                            </span>
                          )}
                        </>
                      )}
                    </span>
                  </h6>
                  <h6 className="cmt_time">
                    {new Date(comment?.created_at).toDateString()}
                  </h6>
                </div>
                <Rating
                  name="simple-controlled"
                  value={comment?.rating}
                  disabled
                />
                <p>{comment?.comments}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentCard;
