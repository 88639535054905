import React, { useState } from "react";
import TopBanner from "../../common/banner/TopBanner";
import img from "../../assets/images/bann1.png";
import { useHistory, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { IMAGE_URL } from "../../services/apiUrl";
import Loader from "../../common/loader/Loader";
import Model from "../categorysingle/Model";
import ModalBox from "../../common/modal/ModalBox";
import { useDispatch, useSelector } from "react-redux";
import { setIsTermsModalVisible } from "../../features/modal/modalSlice";

const SingleBlog = () => {
  const params = useParams();
  const [isOpen, setOpen] = useState(false);
  const [CurrentVedio, setVedio] = useState("");
  const dispatch = useDispatch();
  const { termsmodal } = useSelector((state) => state.modal);

  const {
    data: { blog },
    loading,
    error,
  } = useFetch(`singleblog/${params.id}`);

  function createMarkup(data) {
    return { __html: data };
  }
  const history = useHistory();
  const OpenModel = (vedio) => {
    setOpen(true);
  };

  const openAuthorinfo = () => {
    dispatch(setIsTermsModalVisible(true));
  };

  return (
    <>
      {termsmodal && (
        <ModalBox>
          {blog && (
            <>
              <div
                dangerouslySetInnerHTML={createMarkup(blog?.aboutblogauthor)}
              ></div>
            </>
          )}
        </ModalBox>
      )}
      {/* <TopBanner title="Single Blog" type="Blog" blogtitle={blog?.blogtitle} /> */}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="banner_spacer"></div>
          <div className="blog_top_banner container">
            <div className="blog__content">
              <button
                style={{ fontSize: "16px" }}
                className="common-btn"
                onClick={() => history.goBack()}
              >
                <i
                  className="fa fa-arrow-left  backIcon"
                  aria-hidden="true"
                ></i>{" "}
                Back to Analytics
              </button>
            </div>
          </div>
          <section>
            <div className="container">
              {blog?.biurl == null && <div className="banner_spacer"></div>}

              <div className="row">
                <div className="col-md-12">
                  <div className="blog_single">
                    {blog?.biurl != null && (
                      <div className="right_label row">
                        <div className="col-md-12 col-lg-9 col-sm-12 col-xs-12">
                          <h1 className="font-weight-bold blog_heading mt-3 mb-4">
                            {blog?.blogtitle}
                          </h1>
                        </div>
                        <div className="col-md-12 col-lg-3 col-sm-12 col-xs-12">
                          <div className="d-flex justify-content-end align-items-center single_blog_info">
                            <h6 className="blog_date mb-0">{blog?.blogdate}</h6>
                            <span
                              className="blog_by"
                              onClick={() => openAuthorinfo()}
                            >
                              {blog?.blogauthor}
                              <i className="fa fa-info-circle ms-2"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {blog?.biurl == null ? (
                      <div className="singlediv">
                        <img
                          src={`${IMAGE_URL}${blog?.blogimg}`}
                          className="img-fluid w-100"
                        />
                        {/* <span className="sport_label bg_pink">
                        <h6 className="m-0 text-white">{blog?.catnames}</h6>
                      </span> */}
                      </div>
                    ) : (
                      <>
                        <div
                          className="i_frame mt-5"
                          dangerouslySetInnerHTML={createMarkup(blog?.biurl)}
                        />
                      </>
                    )}

                    <div className="single_content mt-5  px-3 px-md-0">
                      {blog?.biurl == null && (
                        <div className="right_label row">
                          <div className="col-md-12 col-lg-9 col-sm-12 col-xs-12">
                            <h1 className="font-weight-bold blog_heading mt-5 mb-4">
                              {blog?.blogtitle}
                            </h1>
                          </div>
                          <div className="col-md-12 col-lg-3 col-sm-12 col-xs-12">
                            <div className="d-flex justify-content-end align-items-center single_blog_info">
                              <h6 className="blog_date mb-0">
                                {blog?.blogdate}
                              </h6>
                              <span
                                className="blog_by"
                                onClick={() => openAuthorinfo()}
                              >
                                {blog?.blogauthor}
                                <i className="fa fa-info-circle ms-2"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          blog?.blogcontent
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="banner_spacer"></div>
              {blog?.blogyoutubeurl && (
                <>
                  <Model
                    isOpen={isOpen}
                    setOpen={setOpen}
                    vedio={blog?.blogyoutubeurl}
                  />
                  <div className="text-center py-5 blog_slider">
                    <div className="singlevideo_slider">
                      <div className="blog_slide">
                        <div>
                          <img
                            src={`https://img.youtube.com/vi/${blog?.blogyoutubeurl}/hqdefault.jpg`}
                            className="vid__image"
                          />
                        </div>

                        <button
                          className="js-video-button-blog js-video-button1"
                          data-channel="custom"
                          onClick={() => {
                            OpenModel(blog?.blogyoutubeurl);
                          }}
                        >
                          <div className="btn1">
                            <div className="play"></div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default SingleBlog;
