import React, { useState } from "react";
import Slider from "react-slick";

import { Academybanner, Coachbanner } from "../../common/slider/Settings";
import RestaurentLoader from "../../common/loader/RestaurentLoader";
import AcademyCard from "./AcademyCard";
import { useSelector } from "react-redux";
import { STATUS } from "../../services/Status";
import EmptyComponent from "../../common/commoncomponents/EmptyComponent";
import CoachCard from "./CoachCard";

const CoachSlider = () => {
  const [loading, setloading] = useState(true);
  const { featuresports, status, featurecoach } = useSelector(
    (state) => state.home
  );
  return (
    <>
      {status === STATUS.LOADING ? (
        <>
          {" "}
          <div className="row">
            <div className="col-sm-12 col-md-4 ">
              <RestaurentLoader />
            </div>
            <div className="col-sm-12 col-md-4 ">
              <RestaurentLoader />
            </div>
            <div className="col-sm-12 col-md-4 ">
              <RestaurentLoader />
            </div>
          </div>
        </>
      ) : (
        <>
          {featurecoach && featurecoach.length > 3 ? (
            <Slider {...Coachbanner} className="academies_slider">
              {featurecoach &&
                featurecoach.map((data, i) => {
                  return (
                    <CoachCard
                      key={i}
                      id={data.id}
                      slug={data?.academyname}
                      img={data?.displayimg}
                      link={data?.categoryname}
                      desc={data?.address}
                      title={data?.academyname}
                      cat={data?.categoryname}
                      city={data?.city}
                      sublocation={data?.sublocation}
                      shortdesc={data?.shortdesc}
                    />
                  );
                })}
            </Slider>
          ) : (
            <>
              <div className="row">
                {featurecoach.map((data, i) => {
                  return (
                    <div className="col-sm-12 col-md-6 col-lg-4 " key={i}>
                      <CoachCard
                        id={data.id}
                        slug={data?.academyname}
                        img={data?.displayimg}
                        link={data?.categoryname}
                        desc={data?.shortdesc}
                        title={data?.academyname}
                        cat={data?.categoryname}
                        city={data?.city}
                        sublocation={data?.sublocation}
                        shortdesc={data?.shortdesc}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {featurecoach && featurecoach.length == 0 && <EmptyComponent />}
        </>
      )}
    </>
  );
};

export default CoachSlider;
