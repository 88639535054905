import React from "react";
import Slider from "react-slick";
import { Gym } from "../../../common/slider/Settings";

import GymCard from "./GymCard";
import { useSelector } from "react-redux";
import { STATUS } from "../../../services/Status";
import GymLoader from "../../../common/loader/GymLoader";
import EmptyComponent from "../../../common/commoncomponents/EmptyComponent";

const GymSlider = () => {
  const { featuregym, status } = useSelector((state) => state.home);

  if (status === STATUS.LOADING)
    return (
      <div className="gym_slider ">
        <GymLoader />
      </div>
    );

  return (
    <>
      {featuregym && featuregym.length == 0 && <EmptyComponent />}
      {featuregym && (
        <Slider {...Gym} className="gym_slider">
          {featuregym.map((data, i) => {
            return (
              <GymCard
                key={i}
                id={data.id}
                img={data?.displayimg}
                text={data?.shortdesc}
                title={data?.academyname}
                city={data?.city}
                sublocation={data?.sublocation}
              />
            );
          })}
        </Slider>
      )}
    </>
  );
};

export default GymSlider;
