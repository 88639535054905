import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import img1 from "../../../assets/images/person.png";
import { PackSlider_, Person } from "../../../common/slider/Settings";
import {
  setselectedPerson,
  setSelectedPersonname,
} from "../../../features/cart/cartSlice";
import { IMAGE_URL } from "../../../services/apiUrl";

const Step2 = () => {
  const dispatch = useDispatch();
  const Findimage = (string) => {
    let image;
    if (string === null) {
      image = img1;
    } else if (string.includes("base64")) {
      image = string;
    } else if (string.includes("https")) {
      image = string;
    } else {
      image = `${IMAGE_URL}${string}`;
    }

    return image;
  };
  const { familymembers } = useSelector((state) => state.user);
  const { selectedPerson } = useSelector((state) => state.cart);

  const HandleChangeFamilyMember = (id, name) => {
    dispatch(setselectedPerson(id));
    dispatch(setSelectedPersonname(name));
  };
  return (
    <>
      <fieldset>
        <div className="step1">
          {familymembers && familymembers.length > 0 ? (
            <>
              <Slider {...PackSlider_} className="row person_slider">
                {familymembers.map((family, i) => (
                  <div key={family.id}>
                    <label htmlFor={`person${family.id}`}>
                      <input
                        type="radio"
                        name="person_select"
                        id={`person${family.id}`}
                        value={family?.fmembername}
                        defaultChecked={selectedPerson === family.id}
                        onChange={() =>
                          HandleChangeFamilyMember(
                            family.id,
                            family?.fmembername
                          )
                        }
                      />
                      <div className="person_box text-center mb-2">
                        <img
                          src={Findimage(family.fdisplayimg)}
                          className="img-fluid"
                        />
                        <h6 className="py-3">{family.fmembername}</h6>
                        <div className="select_span text-center">
                          <i className="fa fa-check"></i>
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </Slider>
            </>
          ) : (
            "No Family Members"
          )}
        </div>
      </fieldset>
    </>
  );
};

export default Step2;
