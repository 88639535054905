import React, { useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  useLoadScript,
  MarkerF,
  InfoWindow,
} from "@react-google-maps/api";
import { useEffect } from "react";

const libraries = ["places"];
const AcademyGoogleMap = ({ lat, lng }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAHN0azNejNITABzDdUco0MpcyZFgaEL-A",
    libraries,
  });

  const [center, setcenter] = useState({
    lat: Number(12.9095132),
    lng: Number(77.6871614),
  });

  const containerStyle = {
    height: "200px",
  };

  useEffect(() => {
    if (lat) {
      setcenter({
        lat: Number(lat),
        lng: Number(lng),
      });
    }
  }, [lat]);

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={11}
        onClick={(e) =>
          window
            .open(
              `https://maps.google.com/?q=${center?.lat},${center?.lng}`,
              "_blank"
            )
            .focus()
        }
      >
        <MarkerF
          position={center}
          clickable
          onClick={(e) =>
            window
              .open(
                `https://maps.google.com/?q=${center?.lat},${center?.lng}`,
                "_blank"
              )
              .focus()
          }
        />
        {/* Child components, such as markers, info windows, etc. */}
        <></>
      </GoogleMap>
    </>
  ) : (
    <>Loading</>
  );
};

export default AcademyGoogleMap;
