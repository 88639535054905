import React from "react";
import { auth, google, facebook, appleProvider } from "../../services/Firebase";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGetuser,
  setClearRegisterUser,
  setEmailPhoneActivate,
  setLoggedin,
  setOtpget,
  setOtpVerified,
  setRegisterUser,
  setSocialLoginprofile,
  setTotalLoader,
} from "../../features/user/userSlice";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

const SocialMedialogin = ({ type, setotpVerified, setgetOtp }) => {
  const { isLoggedin } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const path = useLocation();
  const SocialLogin = (provider) => {
    const authProvider =
      provider == "apple"
        ? appleProvider
        : provider == "google"
        ? google
        : facebook;

    signInWithPopup(auth, authProvider)
      .then((res) => {
        axios
          .post("sociallogin", {
            email: res.user.email,
            phoneno: res.user?.phoneNumber,
            apple_uid: res.user?.uid,
            auth_type: provider,
          })
          .then((response) => {
            if (response.data.is_user) {
              localStorage.setItem("auth-token", response.data.token);
              dispatch(setClearRegisterUser());
              dispatch(setLoggedin(true));
              dispatch(fetchGetuser());
              dispatch(setTotalLoader(true));
              if (path?.state?.type === "academy") {
                history.push(
                  `/academy/${path?.state?.academy_id}/${path?.state?.slug}`
                );
                dispatch(setTotalLoader(false));
              } else {
                history.push("/");
                dispatch(setTotalLoader(false));
              }
            } else {
              const data = {
                message: "Otp Send User Mobile Number",
                email: res.user.email,
                phonenumber: res.user?.phoneNumber,
                opt: "social",
                membername: res.user.displayName,
                displayimg: res.user.photoURL,
                apple_uid: res.user?.uid,
                authtype: provider,
              };
              dispatch(setRegisterUser(data));
              dispatch(setSocialLoginprofile(true));
              dispatch(setOtpget(true));
              dispatch(setOtpVerified(true));
              if (res.user.email) {
                dispatch(setEmailPhoneActivate({ type: "email", value: true }));
              }
              if (res.user.phoneNumber) {
                dispatch(setEmailPhoneActivate({ type: "phone", value: true }));
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });

        // const data = {
        //   message: "Otp Send User Mobile Number",
        //   email: res.user.email,
        //   opt: "email",
        //   membername: res.user.displayName,
        // };
        // dispatch(setRegisterUser(data));
        // dispatch(setOtpget(true));
        // dispatch(setOtpVerified(true));
        // setgetOtp(true);
        // setotpVerified(true);
      })
      .catch((err) => console.log(err, "error"));
  };
  return (
    <>
      <form className="google_form">
        <div className="row">
          <div className="col-lg-12 col-md-12 text-center mt-3">
            <p className="mt-2">--OR--</p>
          </div>
        </div>
        <div className="buttons">
          <button
            className="btn fb_btn my-2"
            type="button"
            onClick={() => SocialLogin("facebook")}
          >
            <div className="text-center justify-content-center">
              <i className="fa fa-facebook" aria-hidden="true"></i>
              <span className="p-2 loginfacebook">Login with Facebook</span>
            </div>
          </button>

          <button
            className="btn google_btn my-2"
            type="button"
            onClick={() => SocialLogin("google")}
          >
            <div className="text-center justify-content-center">
              <i className="fa fa-google" aria-hidden="true"></i>
              <span className="p-3">Login with Google</span>
            </div>
          </button>
        </div>
        <div className="buttons">
          <button
            className="btn apple_btn my-2"
            type="button"
            onClick={() => SocialLogin("apple")}
          >
            <div className="text-center justify-content-center mx-2">
              <i className="fa fa-apple" aria-hidden="true"></i>
              <span className="p-3">Login with Apple</span>
            </div>
          </button>
        </div>
      </form>
    </>
  );
};

export default SocialMedialogin;
