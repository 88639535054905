import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { setChangeCity, setDetect } from "../../features/user/userSlice";
import {
  setPage,
  setHasMore,
  emptySportList,
} from "../../features/sports/sportSlice";

import classes from "./Banner.module.css";
import SearchLoader from "../../common/loader/SearchLoader";
import SearchItem from "./SearchItem";
import { STATUS } from "../../services/Status";
import {
  fetchSearchitems,
  resetsearchItems,
  resetsearchItemsbanner,
  setLocations,
} from "../../features/home/homeSlice";
import { toast } from "react-toastify";

const BannerContent = ({ heading, subheading }) => {
  const dispatch = useDispatch();
  const [search, setsearch] = useState(false);
  const [searchkey, setSearchkey] = useState(null);

  const { alllocations, searchItemsbanner, searchstatus } = useSelector(
    (state) => state.home
  );
  const { city, locationerror } = useSelector((state) => state.user);
  const [locatSearch, setlocatSearch] = useState(alllocations);

  const handleInputBlur = (event) => {
    dispatch(resetsearchItems());
  };

  useEffect(() => {
    setlocatSearch(alllocations);
  }, [alllocations]);

  const searchLocation = (e) => {
    if (e.target.value.length > 1) {
      const filterLocation =
        locatSearch &&
        locatSearch.filter((location) =>
          location.toLowerCase().includes(e.target.value.toLowerCase())
        );

      setlocatSearch(filterLocation);
    } else {
      setlocatSearch(alllocations);
    }
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      // Trigger the onChange event here
      //console.log("Enter key pressed. Value:", event.target.value);
      dispatch(setChangeCity(event.target.value));
    }
  };

  const detectLocation = (e) => {
    e.preventDefault();
    let city = e.currentTarget.id;

    dispatch(setChangeCity(city));
  };
  const searchHandleChange = (e) => {
    if (e.target.value.length >= 3) {
      setsearch(true);
      setSearchkey(e.target.value);
      dispatch(fetchSearchitems(e.target.value, city, "banner"));
      // console.log(searchkey, "from search");
    } else {
      setsearch(false);
      dispatch(resetsearchItemsbanner());
    }
  };

  const detectCurrentLocation = (e) => {
    console.log("detect");
    e.preventDefault();
    locationerror
      ? toast("User denied Geolocation | Kindly Enable to Access Location")
      : dispatch(setDetect());
  };
  //console.log(searchkey, "from search");
  return (
    <>
      <div className="row banner_content">
        <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10">
          <div className="text-white text-center">
            <h1 className="banner_head text-uppercase">
              {heading ? heading : "SportzLete"}
            </h1>
            <h5 className="text-uppercase banner_subhead">
              {subheading ? subheading : "Reinvent Our Lives Demo"}
            </h5>
          </div>

          <div className="home_search header_search mt-5">
            <div className="row">
              <div className="col-xl-5 col-lg-5  d-flex mbl_searchbar">
                <i className="fa fa-map-marker"></i>

                <div
                  id="location_type"
                  className="btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <input
                    type="text"
                    placeholder={city}
                    className="input-mini w-100"
                    onChange={searchLocation}
                    onKeyDown={handleInputKeyPress}
                  />

                  <div
                    id="location_type_options"
                    className="dropdown-menu p-3 my-2"
                  >
                    <div className="d-flex drop_content">
                      <i className="fa fa-location-arrow pe-3"></i>
                      <p className="mb-0" onClick={detectCurrentLocation}>
                        Detect current location
                      </p>
                    </div>
                    <p className="drop_txt mb-0">Using GPS</p>
                    <Scrollbars autoHide autoHeight>
                      {locatSearch &&
                        locatSearch.length > 0 &&
                        locatSearch.map((location, i) => (
                          <>
                            <div
                              className="d-flex drop_content location-drop"
                              key={location}
                              style={{ marginTop: "10px" }}
                              id={location}
                              onClick={detectLocation}
                            >
                              <i className="fa fa-location-arrow pe-3"></i>
                              <p
                                className="drop_txt"
                                style={{ marginLeft: "0px" }}
                              >
                                {location}
                              </p>
                            </div>
                          </>
                        ))}
                    </Scrollbars>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7  d-flex  mbl_searchbar position-relative">
                <div
                  className="btn dropdown-toggle search_dropdown d-flex py-0 w-100"
                  data-bs-toggle="dropdown"
                >
                  <i className="fa fa-search"></i>
                  <input
                    type="text"
                    placeholder="Search here...."
                    name="search"
                    className="search1 form-control"
                    onChange={searchHandleChange}
                    onBlur={handleInputBlur}
                  />
                  <div id="" className="dropdown-menu p-3 my-2">
                    <div className=" searchitems">
                      {searchstatus === STATUS.LOADING ? (
                        <>
                          <SearchLoader />
                          <SearchLoader />
                          <SearchLoader />
                        </>
                      ) : (
                        <>
                          {searchItemsbanner && searchItemsbanner.length > 0 ? (
                            <>
                              {searchItemsbanner.map((item, i) => (
                                <SearchItem
                                  item={item}
                                  key={item}
                                  setsearch={setSearchkey}
                                  search={setsearch}
                                />
                              ))}
                            </>
                          ) : (
                            <p className="no-content">No Content Found</p>
                          )}
                        </>
                      )}

                      {/* <SearchItem /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {search && (
            <div className=" searchitems">
              {searchstatus === STATUS.LOADING ? (
                <>
                  <SearchLoader />
                  <SearchLoader />
                  <SearchLoader />
                  <SearchLoader />
                  <SearchLoader />
                  <SearchLoader />
                  <SearchLoader />
                  <SearchLoader />
                </>
              ) : (
                <>
                  {searchItems && searchItems.length > 0 ? (
                    <>
                      {searchItems.map((item, i) => (
                        <SearchItem
                          item={item}
                          key={i}
                          setsearch={setSearchkey}
                          search={setsearch}
                        />
                      ))}
                    </>
                  ) : (
                    <p className="no-content">No Content Found</p>
                  )}
                </>
              )}

             
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default BannerContent;
