import React from "react";
import { useSelector } from "react-redux";
import TopBanner from "../common/banner/TopBanner";

const RefundPolicy = () => {
  const { policies } = useSelector((state) => state.home);
  function createMarkup(text) {
    return { __html: text };
  }
  return (
    <>
      <TopBanner
        // title="Terms and Conditions"
        loading={false}
        image={policies?.rrimg}
      />
      <div className="container">
        <div className="banner_spacer"></div>

        <div className="row">
          <div className="col-md-12">
            <p className="para_font">
              <>
                <div
                  dangerouslySetInnerHTML={createMarkup(policies?.section3)}
                />
              </>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
