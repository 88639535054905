import axios from "axios";
import React, { useState, useEffect } from "react";

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const FetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(url);

      setData(response.data);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchData();
  }, [url]);

  const Refetch = () => {
    setLoading(true);
    FetchData();
  };

  return { data, loading, error, Refetch };
};

export default useFetch;
