import React from "react";
import { useEffect } from "react";
import TopBanner from "../common/banner/TopBanner";
import Content from "../components/aboutus/Content";
import Testimonial from "../components/aboutus/Testimonial";
import useFetch from "../hooks/useFetch";
import { BASE_URL } from "../services/apiUrl";
import { Skeleton } from "@mui/material";

const AboutUs = (props) => {
  const {
    data: { aboutuspages },
    loading,
    error,
  } = useFetch(`${BASE_URL}aboutuspage`);
  const Loadingprogress = props.Loadingprogress;
  useEffect(() => {
    Loadingprogress(100);
  }, []);

  function createMarkup(text) {
    return { __html: text };
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For other browsers
    }, 0);
  }, []);

  return (
    <>
      <TopBanner image={aboutuspages?.aboutusbanner} loading={loading} />

      <section>
        <div className="container">
          <div className="banner_spacer"></div>
          <div className="row">
            {/* <!-- something begins--> */}
            <div className="col-md-12">
              {/* <!-- contents here --> */}
              <p className="para_font">
                <>
                  {aboutuspages && (
                    <div
                      className="custom_style"
                      dangerouslySetInnerHTML={createMarkup(
                        aboutuspages?.section1
                      )}
                    />
                  )}
                </>
              </p>
            </div>
            {/* <!-- something ends --> */}
          </div>
          <div className="banner_spacer"></div>

          <div className="banner_spacer"></div>

          <div className="banner_spacer"></div>
        </div>
      </section>

      {/* <Testimonial /> */}
    </>
  );
};

export default AboutUs;
