import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { PackSlider_ } from "../../common/slider/Settings";
import {
  setExpirePackagesState,
  setSelectedDuration,
  setselectedPackages,
} from "../../features/cart/cartSlice";
import FixedPackage from "./FixedPackage";
import { CurrentDateFormat, toIndianCurrency } from "../../services/Helper";

const PackSlider = () => {
  const dispatch = useDispatch();
  const { selectedPackage, packages, selectedDuration } = useSelector(
    (state) => state.cart
  );
  const [durationtype, setdurationType] = useState("Day");

  const Durations = ["Day", "Week", "Month", "year"];

  const CheckExpire = (startdate) => {
    if (startdate === null) return true;
    var currentday = new Date();

    var day = new Date(startdate);
    var enddate = new Date(day);
    enddate.setDate(day.getDate() + 3); //2023-02-01

    // 2023-01-30 2023-02-01
    if (enddate.getTime() < currentday.setHours(0, 0, 0, 0)) {
      return false;
    } else {
      return true;
    }

    // if (currentday.toDateString() > enddate.toDateString()) {
    //   console.log("1st segment");
    //   return false;
    // } else if (currentday.toDateString() < enddate.toDateString()) {
    //   console.log("2nd");
    //   return true;
    // } else {
    //   console.log("3rd");
    //   return true;
    // }
  };

  const CheckEnrollLastdate = (packagelastdate) => {
    if (packagelastdate === null) return true;
    var currentDate = new Date();
    const givenDate = new Date(packagelastdate);
    if (givenDate < currentDate) {
      return false;
    } else {
      return true;
    }
  };

  const date = "2023-01-29";

  const HandleselectedPackaes = (
    id,
    name,
    gst = 0,
    razorpay_per = 0,
    convfee = 0
  ) => {
    dispatch(setselectedPackages([id, name, gst, razorpay_per, convfee]));
  };

  const ChangePackages = (type) => {
    console.log(type);
    dispatch(setSelectedDuration(type));
  };

  const FilterExpirePackages = (type) => {
    const pack = packages.filter((packs) => CheckExpire(packs?.packagestdate));
    const Fillterpackages = pack.filter((fpacks) =>
      CheckEnrollLastdate(fpacks?.packagelastdate)
    );

    // after filter chekc day package first

    const PackagesFinal = Fillterpackages.filter(
      (finalpackages) => finalpackages.durationmonths == type
    );
    // if (PackagesFinal.length === 0) {
    //   dispatch(setExpirePackagesState());
    // }
    return (
      <>
        <div className="row pack_slider ">
          <div className="tab_duration">
            <span
              onClick={() => ChangePackages("Day")}
              className={selectedDuration == "Day" ? "tab_duration_active" : ""}
            >
              Daily
            </span>
            <span
              onClick={() => ChangePackages("Week")}
              className={
                selectedDuration == "Week" ? "tab_duration_active" : ""
              }
            >
              Weekly
            </span>
            <span
              onClick={() => ChangePackages("Month")}
              className={
                selectedDuration == "Month" ? "tab_duration_active" : ""
              }
            >
              Monthly
            </span>
            <span
              onClick={() => ChangePackages("Year")}
              className={
                selectedDuration == "Year" ? "tab_duration_active" : ""
              }
            >
              Yearly
            </span>
          </div>

          {PackagesFinal.length == 0 && <p>No Packages Available</p>}
          {PackagesFinal.map((data, i) => (
            <>
              {CheckExpire(data?.packagestdate) && (
                <>
                  <div
                    className="pack_content col-md-4 col-sm-12"
                    key={data?.id}
                  >
                    <label htmlFor={`pack${i}`}>
                      <input
                        type="radio"
                        name="pack_select"
                        id={`pack${i}`}
                        defaultChecked={selectedPackage === data.id}
                        onChange={() =>
                          HandleselectedPackaes(
                            data?.id,
                            data?.packagename,
                            data?.gst,
                            data?.razorpay_per,
                            data?.convfee
                          )
                        }
                      />
                      <div className="pack_box">
                        <div className="pack_box_top text-white text-center">
                          <h5>{data.packagename}</h5>

                          {data?.offerperprice ? (
                            <>
                              {data?.offertype === "1" ? (
                                <>
                                  <h4>
                                    {toIndianCurrency(data?.offerpricenet)}
                                  </h4>
                                  <h6>
                                    {toIndianCurrency(data?.offernettotal)}
                                  </h6>
                                </>
                              ) : (
                                <>
                                  {/* <h4>
                                    {toIndianCurrency(
                                      Math.round(
                                        (data?.price * data?.offerperprice) /
                                          100
                                      )
                                    )}
                                  </h4>
                                  <h6>{toIndianCurrency(+data?.price)}</h6> */}

                                  <h4>
                                    {toIndianCurrency(data?.offerpricenet)}
                                  </h4>
                                  <h6>
                                    {toIndianCurrency(data?.offernettotal)}
                                  </h6>
                                </>
                              )}
                            </>
                          ) : (
                            <h4>{toIndianCurrency(+data?.nettotal)}</h4>
                          )}
                          <h5>
                            {data?.duration} {data?.durationmonths}
                          </h5>
                        </div>
                        <div className="pack_box_bottom p-3 text-left">
                          <div className="pack_des">
                            <h5>
                              {data.comment1 && (
                                <>
                                  <i className="fa fa-check"></i>
                                  {data?.comment1}
                                </>
                              )}
                            </h5>
                            <h5>
                              {data.comment2 && (
                                <>
                                  <i className="fa fa-check"></i>
                                  {data?.comment2}
                                </>
                              )}
                            </h5>
                            <h5>
                              {data.comment3 && (
                                <>
                                  <i className="fa fa-check"></i>
                                  {data?.comment3}
                                </>
                              )}
                            </h5>
                          </div>

                          <br />
                          <div className="select_span text-center">
                            <i className="fa fa-check"></i>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </>
              )}
            </>
          ))}
        </div>
      </>
    );
  };

  return <>{FilterExpirePackages(selectedDuration)}</>;
};

export default PackSlider;
