import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedslot,
  setSlotavailable,
} from "../../../features/cart/cartSlice";
import useFetch from "../../../hooks/useFetch";
import Loader from "../../../common/loader/Loader";
import { Scrollbars } from "react-custom-scrollbars";

const Step3 = () => {
  const dispatch = useDispatch();
  const { selectedPackage, selectedAddons, selectedPerson, selectedslot } =
    useSelector((state) => state.cart);
  const { data, loading, error } = useFetch(
    `getallslots?fmember_id=${selectedPerson}&package_id=${selectedPackage}`
  );
  //&addon_id=${selectedAddons}

  useEffect(() => {
    data && data.length > 0
      ? dispatch(setSlotavailable(true))
      : dispatch(setSlotavailable(false));
  }, [data]);

  if (error)
    return (
      <p className="text-danger text-center">
        You Missed out Select Package/Family Member Please Select
      </p>
    );
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {data && data.length > 0 ? (
            <>
              <fieldset>
                <div className="step3">
                  <div className="row">
                    <Scrollbars autoHide style={{ height: 350 }}>
                      {data.map((slot, i) => (
                        <>
                          <div className="slot-items" key={slot?.id}>
                            <div
                              className="col-lg-12 col-md-12 col-sm-12"
                              key={slot?.id}
                            >
                              <label htmlFor={`slottime${slot?.id}`}>
                                <input
                                  type="radio"
                                  name="slot_select"
                                  id={`slottime${slot?.id}`}
                                  defaultChecked={selectedslot === slot?.id}
                                  onChange={() =>
                                    dispatch(setSelectedslot(slot?.id))
                                  }
                                />
                                <div className="row slottime_box align-items-center mb-3 px-2">
                                  <div className="col-sm-10 col-9 slottime_inner">
                                    <h6 className="">
                                      {slot?.slotname} -{" "}
                                      <small>
                                        (
                                        {`${slot?.fromtime} to ${slot?.totime}`}
                                        )
                                      </small>
                                    </h6>
                                    {slot?.addons.length > 0 ? (
                                      <>
                                        <div>
                                          Addons:{" "}
                                          {slot?.addons.map((add, i) => (
                                            <small key={i}>
                                              {add?.add?.addonname},
                                            </small>
                                          ))}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="col-1">
                                    <div className="select_span text-center mr-0">
                                      <i className="fa fa-check"></i>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        </>
                      ))}
                    </Scrollbars>
                  </div>
                </div>
              </fieldset>
            </>
          ) : (
            <>
              <p>No Slots Available</p>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Step3;
