import React from "react";
import { Link } from "react-router-dom";
import noimage from "../../../../src/assets/images/noimage.jpg";
import { IMAGE_URL } from "../../../services/apiUrl";
import { shortString, slugify } from "../../../services/Helper";

const GymCard = ({ img, title, text, id, city, sublocation }) => {
  const srcfile = img ? `${IMAGE_URL}${img}` : noimage;
  function createMarkup(text) {
    return { __html: text };
  }
  return (
    <>
      <div className="slide_box">
        <div className="gym_box">
          <Link to={`/academy/${id}/${slugify(title)}`}>
            <div
              className="gym_img"
              style={{ backgroundImage: `url(${srcfile})` }}
            ></div>

            <div className="text-center p-4">
              <h4 className="font-weight-bold">{shortString(title)}</h4>
              {sublocation != null ? (
                <span>
                  {city} - {sublocation}
                </span>
              ) : (
                <span>{city}</span>
              )}
              <div
                className="custom_style"
                dangerouslySetInnerHTML={createMarkup(text)}
              />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default GymCard;
