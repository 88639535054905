import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommentCard from "./CommentCard";
import CommentForm from "./CommentForm";
import { Scrollbars } from "react-custom-scrollbars";
import { setCommentSort } from "../../features/comments/commentSlice";
import { Link, useHistory } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { setIsTermsModalVisible } from "../../features/modal/modalSlice";
import ModalBox from "../../common/modal/ModalBox";

const Comment = ({ academy_id }) => {
  const { comments, unapproved } = useSelector((state) => state.comment);
  const { config } = useSelector((state) => state.home);

  const { termsmodal } = useSelector((state) => state.modal);
  const { user, isLoggedin } = useSelector((state) => state.user);
  const [showCommentform, setshowCommentform] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, loading, error } = useFetch(`ifuserbuyed/${academy_id}`);

  const checkUserComment = (id) => {
    const found =
      comments && comments.map((comment) => comment.member_id).includes(id);

    return found;
  };

  const openCommentinfo = () => {
    dispatch(setIsTermsModalVisible(true));
  };

  const openform = (e) => {
    e.preventDefault();
    setshowCommentform(!showCommentform);
  };

  return (
    <>
      {termsmodal && (
        <ModalBox>
          <p>{config?.cmtusrmsg}</p>
        </ModalBox>
      )}
      <div className="row">
        <div className="col-xl-6 col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <h3 className="font_call">Comments & Reviews</h3>
        </div>

        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-12 col-xs-12 text-right pt-4 pt-md-0 d-flex justify-content-between">
          <form className="cmts_form">
            <label htmlFor="cmts_sorting">Sort by:</label>
            <span className="sel_arrow">
              <select
                name="cmts_sorting"
                id="cmts_sorting"
                onChange={(e) => dispatch(setCommentSort(e.target.value))}
              >
                <option value="0">Most Popular</option>
                <option value="1">Latest</option>
              </select>
              <i className="fa fa-chevron-down"></i>
            </span>
          </form>
          <button className="btn text-danger" onClick={() => history.goBack()}>
            <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
          </button>
        </div>
      </div>
      {user.length === 0 && (
        <div className="mt-3 mb-3  ">
          <Link to="/login" className="text-danger font_call">
            Login to add New Comment
          </Link>
        </div>
      )}
      {isLoggedin && !checkUserComment(user?.id) && unapproved == null && (
        <div className="comment_btn">
          <button
            className={`${data && !data?.userbuyed ? "not_buyed" : "buyed"}`}
            onClick={openform}
          >
            Comment
          </button>
          {data && !data?.userbuyed && (
            <i
              className="fa fa-info-circle user_not_buyed"
              aria-hidden="true"
              onClick={openCommentinfo}
            ></i>
          )}
        </div>
      )}

      {isLoggedin && showCommentform && (
        <>
          <>
            {!checkUserComment(user?.id) && data?.userbuyed && (
              <CommentForm
                academy_id={academy_id}
                setshowCommentform={setshowCommentform}
              />
            )}
          </>
        </>
      )}
      {comments && comments.length > 0 ? (
        <>
          <Scrollbars autoHide style={{ height: 650 }}>
            <div style={{ marginTop: "10px" }} className="mb-3">
              {comments && comments.length > 0 ? (
                comments.map((comment, i) => (
                  <CommentCard comment={comment} key={i} />
                ))
              ) : (
                <>
                  <div className="no-comments">
                    <p>{config?.cmtmsg}</p>
                  </div>
                </>
              )}
            </div>
          </Scrollbars>
        </>
      ) : (
        <div className="no-comments mt-3 mb-3 text-center">
          <p>{config?.cmtmsg}</p>
        </div>
      )}
    </>
  );
};

export default Comment;
